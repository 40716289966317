import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  darken,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/store";
import { MobileMenuDrawer } from "../mobile-menu/MobileMenuDrawer";
import NavMenuButton from "./NavMenuButton";
import CoderButton from "./CoderButton";
import SignupButton from "./SignupButton";
import logo_white_long from "../../../images/logo-white.png";
import { MenuOpen } from "@mui/icons-material";
import { ProfileButton } from "./ProfileButton";

export const Navbar = ({}) => {
  const location = useLocation();
  const isAuthPage = location.pathname === "/auth";

  const navigate = useNavigate();
  const theme = useTheme();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userCredentials = useAppSelector((state) => state.userCredentials);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect drawerOpen
  useEffect(() => {
    //console.log("drawerOpen", drawerOpen);
  }, [drawerOpen]);

  const showAccount = () => {
    if (!userAuthSlice.userAuth?.token) {
      return false;
    }

    if (!userDetailsSlice.userDetails?.onboarded) {
      return false;
    }

    return true;
  };

  const handleMenuClick = (viewName, forceNavigate = false) => {
    if (forceNavigate) {
      return navigate(viewName);
    }

    if (viewName === "signup" || viewName === "/signup") {
      const navRoute = "/auth?signup=true";
      return navigate(navRoute);
    }

    if (viewName === "login" || viewName === "/login") {
      const navRoute = "/auth";
      return navigate(navRoute);
    }

    if (viewName === "synqer" || viewName === "/synqer") {
      return navigate("/synqer");
    }

    if (viewName === "coder" || viewName === "/coder") {
      return navigate("/coder");
    }

    if (viewName === "examples") {
      return navigate("/examples");
    }

    if (viewName === "team") {
      return navigate("/team");
    }

    if (viewName === "account" || viewName === "/account") {
      const token = userAuthSlice.userAuth?.token;
      const isLoggedIn = token !== null && token !== undefined && token !== "";
      const isVerfied = userCredentials.userCredentials?.verified ?? false;
      const isOnboarded = userDetailsSlice.userDetails?.onboarded ?? false;
      const showAuth = !isLoggedIn || !isVerfied || !isOnboarded;
      if (showAuth) {
        return navigate("/auth");
      } else {
        return navigate("/account");
      }
    } else {
      if (window.location.pathname !== "/") {
        return navigate("/");
      }
    }
  };

  const menuItems = (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        paddingTop: "10px",
        paddingLeft: "10px",
        alignItems: "flex-start",
        minWidth: "200px",
      }}
    >
      <CoderButton viewName={"coder"} onClick={handleMenuClick} />
      <NavMenuButton viewName={"synqer"} onClick={handleMenuClick} />
      <NavMenuButton viewName={"examples"} onClick={handleMenuClick} />
      {!showAccount() && (
        <NavMenuButton
          viewName="login"
          onClick={handleMenuClick}
          closeDrawer={() => setDrawerOpen(false)}
        />
      )}
      {!showAccount() && (
        <SignupButton
          viewName={"signup"}
          handleClick={() => handleMenuClick("signup")}
          sx={{ marginLeft: "10px" }}
        />
      )}
      {showAccount() && <ProfileButton />}
    </Box>
  );

  if (isAuthPage) {
    return null;
  }

  const backgroundThemeColor = theme.palette.colors.teal[500];
  const rB = parseInt(backgroundThemeColor.slice(1, 3), 16);
  const gB = parseInt(backgroundThemeColor.slice(3, 5), 16);
  const bB = parseInt(backgroundThemeColor.slice(5, 7), 16);

  // Get the RGB values for the theme color
  const iconThemeColor = theme.palette.colors.grayScale.white;
  const r = parseInt(iconThemeColor.slice(1, 3), 16);
  const g = parseInt(iconThemeColor.slice(3, 5), 16);
  const b = parseInt(iconThemeColor.slice(5, 7), 16);

  // Calculate color transition from white to the theme color based on scroll position
  const interpolateColor = (start, end, factor) =>
    start + (end - start) * factor;
  const scrollFactor = Math.min(scrollPosition / 200, 1);
  const interpolatedR = Math.round(interpolateColor(255, r, scrollFactor));
  const interpolatedG = Math.round(interpolateColor(255, g, scrollFactor));
  const interpolatedB = Math.round(interpolateColor(255, b, scrollFactor));
  const iconColor = `rgb(${interpolatedR}, ${interpolatedG}, ${interpolatedB})`;

  return (
    <AppBar
      position="relative"
      sx={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <Box
          display={{ xs: "block", md: "none" }}
          onClick={() => {
            navigate("/");
          }}
          sx={{
            paddingTop: { xs: "20px", md: "10px" },
            marginTop: "-5px",
            marginRight: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img src={logo_white_long} alt="Logo" style={{ height: "25px" }} />
        </Box>
        <Box
          display={{ xs: "none", md: "block" }}
          onClick={() => {
            navigate("/");
          }}
          sx={{
            marginRight: "auto",
            marginTop: "12px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img src={logo_white_long} alt="Logo" style={{ height: "50px" }} />
        </Box>
        {!drawerOpen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              // blur the background when the menu is open
              backdropFilter: "blur(20px)",
              backgroundColor: `rgba(${rB}, ${gB}, ${bB}, ${Math.min(
                scrollPosition / 200,
                0.8
              )})`,
              position: "fixed",
              top: "12px",
              right: "12px",
              height: "40px",
              width: "40px",
              zIndex: 1300,
              display: { xs: "block", md: "none" },
              opacity: drawerOpen ? 0 : 1,
              transition: "opacity 0.5s",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: `rgba(${rB}, ${gB}, ${bB}, ${Math.min(
                  scrollPosition / 200,
                  0.9
                )})`,
                color: darken(iconColor, 0.05),
              },
            }}
          >
            <MenuOpen />
          </IconButton>
        )}
        {isSmallScreen ? (
          <MobileMenuDrawer
            handleMenuClick={handleMenuClick}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />
        ) : (
          <Box sx={{ marginLeft: "auto" }}>{menuItems}</Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

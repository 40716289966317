import { Box } from "@mui/material";
import { PhaseCard } from "./PhaseCard";

export const LaunchPhase = () => {
  return (
    <Box
      flexDirection={{ xs: "column", md: "row" }}
      width={"100%"}
      display={"flex"}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ zIndex: 2 }}
      gap={"16px"}
    >
      <PhaseCard
        title={"Announcement Phase"}
        subtitle={
          "Multipliers are at their highest and they last forever... so get in early."
        }
        signupBonus={"50,000"}
        pointMultiplier={"10x"}
        referralMuliplier={"5,000"}
      />
      <PhaseCard
        title={"Pilot Phase"}
        subtitle={"Get points when you start your synchronizer."}
        signupBonus={"10,000"}
        pointMultiplier={"2x"}
        referralMuliplier={"1,000"}
        isActive={true}
      />
      <PhaseCard
        title={"Launch Phase"}
        subtitle={"Get a sign up bonus for being an early noder."}
        signupBonus={"5,000"}
        pointMultiplier={"1x"}
        referralMuliplier={"500"}
      />
    </Box>
  );
};

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import AuthPage from "../pages/auth/AuthPage";
import AccountPage from "../pages/account/AccountPage";
import TermsPage from "../pages/terms/TermsPage";
import PrivacyPage from "../pages/privacy/PrivacyPage";
import UnsubscribePage from "../pages/UnsubscribePage";
import CoderPage from "../pages/coder-pages/CoderPage";
import DocsPage from "../pages/docs/DocsPage";
import IntroductionPage from "../pages/coder-pages/introduction-page/IntroductionPage";
import FurtherReadingPage from "../pages/coder-pages/further-reading-page/FurtherReadingPage";
import SynchronizerLoginPage from "../pages/SynchronizerLoginPage";
import ExamplesPage from "../pages/examples/ExamplesPage";
import TeamPage from "../pages/team/TeamPage";
import SynqerPage from "../pages/synchronizer/SynqerPage";
import SocialsPage from "../pages/socials/SocialsPage";

export interface PageProps {
  showPageWrapper: boolean;
}

const PageManager: React.FC = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<CoderPage showPageWrapper={true} />} /> */}
      <Route path="/coder" element={<CoderPage showPageWrapper={true} />} />
      <Route path="/auth" element={<AuthPage showPageWrapper={false} />} />
      <Route path="*" element={<NotFoundPage showPageWrapper={false} />} />
      <Route path="/account" element={<AccountPage showPageWrapper={true} />} />
      <Route path="/terms" element={<TermsPage showPageWrapper={true} />} />
      <Route path="/socials" element={<SocialsPage showPageWrapper={true} />} />
      <Route path="/privacy" element={<PrivacyPage showPageWrapper={true} />} />
      <Route path="/synqer" element={<SynqerPage showPageWrapper={true} />} />
      <Route
        path="/examples"
        element={<ExamplesPage showPageWrapper={true} />}
      />
      <Route path="/team" element={<TeamPage showPageWrapper={true} />} />

      <Route
        path="/coder/introduction-to-multisynq"
        element={<IntroductionPage showPageWrapper={true} />}
      />
      <Route
        path="/coder/further-reading"
        element={<FurtherReadingPage showPageWrapper={true} />}
      />
      <Route path="/docs" element={<DocsPage showPageWrapper={true} />} />
      <Route
        path="/unsubscribe"
        element={<UnsubscribePage showPageWrapper={false} />}
      />
      {/* Redirect /coder to / */}
      <Route path="/" element={<Navigate to="/coder" />} />

      <Route
        path="/synchronizer/login"
        element={<SynchronizerLoginPage showPageWrapper={false} />}
      />

      {/* Redirect /login and /signup to /auth */}
      {/* <Route path="/login" element={<Navigate to="/auth" />} />
      <Route path="/signup" element={<Navigate to="/auth?signup=true" />} /> */}
    </Routes>
  );
};

export default PageManager;

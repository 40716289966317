import { useEffect, useRef, useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  setCurrentPage,
  setScrollPosition,
  setTotalPages,
} from "../../../../features/ui/pageManagerStateSlice";

export const usePageManager = (listRef, itemRefs, filteredExamples) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();

  const pageManager = useAppSelector((state) => state.pageManager);
  const { currentPage, scrollPosition, totalPages } = pageManager;

  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    listRef.current.scrollLeft = scrollPosition;
    const itemWidth = 190 + 20; // width + margin
    const itemsPerPage = Math.floor(
      (listRef.current.clientWidth || 0) / itemWidth
    );
    const newTotalPages = Math.ceil(filteredExamples.length / itemsPerPage)
    dispatch(setTotalPages(newTotalPages));

    const targetIndex = (currentPage - 1) * itemsPerPage;

    if (itemRefs.current[targetIndex]) {
      const targetItem = itemRefs.current[targetIndex];
      const listRect = listRef.current.getBoundingClientRect();
      const targetRect = targetItem.getBoundingClientRect();

      const scrollFrom = listRef.current.scrollLeft;
      const scrollTo = targetRect.left - listRect.left + scrollFrom;

      if (isXs) {
        listRef.current.scrollLeft = scrollTo;
        dispatch(setScrollPosition(listRef.current.scrollLeft));
      } else {
        const startTime = performance.now();
        const duration = 300;

        const animateScroll = (currentTime) => {
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);

          try {
            listRef.current.scrollLeft =
              scrollFrom + (scrollTo - scrollFrom) * progress;
          } catch (e) {
            //console.error(e);
          }

          if (timeElapsed < duration) {
            window.requestAnimationFrame(animateScroll);
          } else {
            try {
              dispatch(setScrollPosition(listRef.current.scrollLeft));
            } catch (e) {
              //console.error(e);
            }
          }
        };

        window.requestAnimationFrame(animateScroll);
      }
    }

    const hasOverflow =
      listRef.current.scrollWidth > listRef.current.clientWidth;
    setShowShadow(hasOverflow);
  }, [currentPage, isXs, filteredExamples.length, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (listRef.current) {
        const itemWidth = 190 + 20;
        const itemsPerPage = Math.floor(
          (listRef.current.clientWidth || 0) / itemWidth
        );
        const newTotalPages = Math.ceil(filteredExamples.length / itemsPerPage)
        dispatch(setTotalPages(newTotalPages));
        dispatch(setCurrentPage(1));
        const hasOverflow =
          listRef.current.scrollWidth > listRef.current.clientWidth;
        setShowShadow(hasOverflow);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const callSetCurrentPage = (page) => {
    if (filteredExamples.length > 0) {
      const pagesCount = Math.ceil(
        filteredExamples.length / (listRef.current.clientWidth / (190 + 20))
      );
      const newPage = ((page - 1 + pagesCount) % pagesCount) + 1;
      dispatch(setCurrentPage(newPage));
    }
  };

  return {
    showShadow,
    currentPage,
    totalPages,
    callSetCurrentPage,
  };
};

import React from "react";
import { alpha, Box, TextField, useTheme } from "@mui/material";
import { Filter } from "../../../../../../../icons";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { setFilterString } from "../../../../../../../features/api-key/apiKeySlice";
import { CreateApiKeyButton } from "./CreateApiKeyButton";
import { ApiDocsButton } from "./ApiDocsButton";
import ActionButton from "../../../../../examples/blocks/action-bar/ActionButton";

const ActionBlock: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { apiKeys, filterString } = useAppSelector((state) => state.apiKey);

  const hasKeys = apiKeys.length > 0;
  const primaryColor = theme.palette.colors.blue[500];
  const grayColor = theme.palette.colors.grayScale[500];
  const borderRadius = "6px";
  const borderThickness = "1.5px";
  const height = "40px";

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterString(event.target.value));
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      width={"100%"}
      marginTop="8px"
    >
      <Box
        display={{ xs: "none", md: "flex" }}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"40px"}
        height={"40px"}
        marginRight={"8px"}
        sx={{
          borderRadius: "8px",
        }}
      >
        <Filter
          sx={{
            width: "32px",
            height: "32px",
            fill: hasKeys ? primaryColor : alpha(grayColor, 0.35),
          }}
        />
      </Box>
      <TextField
        placeholder="Filter by Name, Region, Url, etc..."
        value={filterString}
        onChange={handleFilterChange}
        variant="outlined"
        disabled={!hasKeys}
        sx={{
          marginRight: "8px",
          width: { xs: "100%", md: "600px" },
          "& .MuiOutlinedInput-root": {
            height: height,
            borderRadius: borderRadius,
            "& fieldset": {
              borderWidth: borderThickness,
              borderRadius: borderRadius,
              borderColor: hasKeys ? primaryColor : grayColor,
            },
            "&:hover fieldset": {
              borderColor: hasKeys ? primaryColor : undefined,
              borderWidth: borderThickness,
            },
            "&.Mui-focused fieldset": {
              borderColor: hasKeys ? primaryColor : grayColor,
              borderWidth: borderThickness,
            },
            backgroundColor: alpha(theme.palette.colors.grayScale.white, 1.0),
          },
          "& .MuiOutlinedInput-input": {
            color: hasKeys ? primaryColor : grayColor,
            height: "calc(100% - 32px)",
            boxSizing: "border-box",
          },
          "& .MuiInputLabel-root": {
            color: hasKeys ? primaryColor : grayColor,
          },
          "& .MuiFormLabel-root": {
            color: hasKeys ? primaryColor : grayColor,
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: grayColor, // Ensures no color change on hover
                borderWidth: borderThickness, // Maintain the border width
              },
              "& fieldset": {
                borderColor: grayColor,
                opacity: 0.5,
              },
            },
            "& .MuiOutlinedInput-input": {
              color: grayColor,
              opacity: 0.5,
            },
          },
        }}
      />
      <Box
        display="flex"
        justifyContent={{ xs: "flex-start", md: "space-between" }}
        width={{ xs: "auto", md: "100%" }}
        gap={{ xs: "8px", md: "0px" }}
      >
        <CreateApiKeyButton />
        <ApiDocsButton />
      </Box>
    </Box>
  );
};

export default ActionBlock;

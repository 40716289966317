import axios from "axios";
import { LocalStorageService } from "../services/LocalStorageService";
import { IUserAuth } from "../types/user/userTypes";

// Because the frontend and backend should be hosted from the same
// domain, we can use absolute URLs to default to the current page's host
const API = axios.create({ baseURL: '/' });

API.interceptors.request.use((req) => {
  const userAuth = LocalStorageService.get("userAuth") as IUserAuth | null;
  if (userAuth) {
    const token = userAuth?.token;

    req.headers.Authorization = `Bearer ` + token;
  }

  if (req.data instanceof FormData) {
    req.headers["Content-Type"] = "multipart/form-data";
  }
  return req;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 498) {
      window.location.href = "/auth";
      error.message = "Invalid Token";
    }
    return Promise.reject(error);
  }
);

export default API;

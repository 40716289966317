import React, { useState, useEffect } from "react";
import { Button, darken, lighten, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

interface NavMenuButtonProps {
  viewName: string;
  onClick?: (view: string) => void;
  closeDrawer?: () => void | null;
  sx?: any;
}

const NavMenuButton: React.FC<NavMenuButtonProps> = ({
  viewName,
  onClick,
  closeDrawer = null,
  sx = null,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [isActiveView, setIsActiveView] = useState(determineActiveView());

  useEffect(() => {
    setIsActiveView(determineActiveView());
  }, [location, viewName]);

  function determineActiveView() {
    const view = viewName.toLowerCase();
    if (view === "/" || view === "coder") return location.pathname === "/";
    if (view === "synqer") return location.pathname === "/synqer";
    if (view === "login") return location.pathname === "/auth";

    const viewPattern = new RegExp(`^/${view}(/|$)`);
    return viewPattern.test(location.pathname);
  }

  const handleClick = () => {
    if (closeDrawer) closeDrawer();
    if (onClick) onClick(viewName);
  };

  return (
    <Button
      onClick={handleClick}
      disableRipple
      sx={{
        marginRight: theme.spacing(1),
        color: isActiveView ? theme.palette.colors.blue[300] : "inherit",
        boxShadow: "none",
        transition: "color 0.0s",
        "&:hover": {
          backgroundColor: isActiveView
            ? theme.palette.colors.blue[300]
            : "inherit",
          color: isActiveView
            ? lighten(theme.palette.colors.blue[300], 0.2)
            : darken(theme.palette.colors.grayScale.white, 0.2),
        },
        "&.MuiButtonBase-root": {
          "&:hover": {
            backgroundColor: "transparent", // Ensure no grey background on hover
          },
        },
        ...sx,
      }}
    >
      {viewName}
    </Button>
  );
};

export default NavMenuButton;

import API from "../api";
import { IApiKey } from "../../types/api-key/apiKeyTypes";

// Create a new API key (using full IApiKey object)
export const createApiKey = (keyData: IApiKey) => {
  return API.post(`/apiKeys/create-key`, keyData);
};

// Fetch metadata for available regions (locations)
export const fetchRegions = () => {
  return API.post(`/apiKeys/meta`);
};

// List all API keys for a user
export const listApiKeys = (details = false) => {
  return API.post(`/apiKeys/read`, {
    details,
  });
};

// Read a specific API key (using apiKey as identifier)
export const readApiKey = (keyData: IApiKey) => {
  return API.post(`/apiKeys/key/${keyData.apiKey}/read`);
};

// Update an existing API key (using apiKey as identifier)
export const updateApiKey = (keyData: IApiKey) => {
  return API.put(`/apiKeys/key/${keyData.apiKey}/update`, keyData);
};

// Delete an API key (using apiKey as identifier)
export const deleteApiKey = (keyData: IApiKey) => {
  return API.post(`/apiKeys/key/${keyData.apiKey}/delete`);
};

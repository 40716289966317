import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { TeamMenuTab } from "../TeamPage";
import { keyframes } from "@mui/system";

// Define the keyframes for the underline animation with a bounce effect
const bounceAnimationRight = keyframes`
  0% {
    transform: translateX(0) scaleX(1);
  }
  40% {
    transform: translateX(15px) scaleX(0.9);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
`;

const bounceAnimationLeft = keyframes`
  0% {
    transform: translateX(0) scaleX(1);
  }
  40% {
    transform: translateX(-15px) scaleX(0.9);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
`;

export const TeamMenuHeader = ({ activeTab, setActiveTab }) => {
  const theme = useTheme();
  const teamRef = useRef(null);
  const advisorsRef = useRef(null);
  const [underlineStyle, setUnderlineStyle] = useState({
    width: 0,
    left: 0,
    animation: "",
  });

  const updateUnderlineStyle = () => {
    const teamWidth = teamRef.current ? teamRef.current.offsetWidth : 0;
    const advisorsWidth = advisorsRef.current
      ? advisorsRef.current.offsetWidth
      : 0;

    const direction = activeTab === TeamMenuTab.TEAM ? "left" : "right";
    const animation =
      direction === "right" ? bounceAnimationRight : bounceAnimationLeft;

    if (activeTab === TeamMenuTab.TEAM) {
      setUnderlineStyle({
        width: teamWidth,
        left: 0,
        animation: `${animation} 0.2s ease-in-out`,
      });
    } else {
      setUnderlineStyle({
        width: advisorsWidth,
        left: teamWidth + 44,
        animation: `${animation} 0.2s ease-in-out`,
      });
    }
  };

  useEffect(() => {
    updateUnderlineStyle();
    window.addEventListener("resize", updateUnderlineStyle);
    return () => {
      window.removeEventListener("resize", updateUnderlineStyle);
    };
  }, [activeTab]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="16px"
      marginBottom="16px"
    >
      <Typography
        fontSize="14px"
        fontWeight={500}
        textAlign="center"
        width="100%"
        color={theme.palette.colors.blue[200]}
        marginTop="20px"
        marginBottom="-12px"
      >
        meet the...
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        marginTop="20px"
        position="relative"
      >
        <Typography
          ref={teamRef}
          variant="h3"
          fontSize={{ xs: "24px", sm: "32px" }}
          textAlign="center"
          fontWeight={700}
          color={
            activeTab === TeamMenuTab.TEAM
              ? theme.palette.colors.blue[200]
              : theme.palette.colors.grayScale.white
          }
          onClick={() => setActiveTab(TeamMenuTab.TEAM)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          Team
        </Typography>
        <Box
          sx={{
            height: "24px",
            width: "3px",
            backgroundColor: theme.palette.colors.grayScale.white,
            margin: "0px 20px",
            opacity: 0.2,
            borderRadius: "2px",
          }}
        />
        <Typography
          ref={advisorsRef}
          variant="h3"
          textAlign="center"
          fontSize={{ xs: "24px", sm: "32px" }}
          fontWeight={700}
          color={
            activeTab === TeamMenuTab.ADVISORS
              ? theme.palette.colors.blue[200]
              : theme.palette.colors.grayScale.white
          }
          onClick={() => setActiveTab(TeamMenuTab.ADVISORS)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          Advisors
        </Typography>
        <Box
          sx={{
            position: "absolute",
            bottom: "-4px",
            height: "3px",
            backgroundColor: theme.palette.colors.blue[200],
            transition: "left 0.15s ease-out, width 0.15s ease-out",
            width: underlineStyle.width,
            left: underlineStyle.left,
            animation: underlineStyle.animation,
            borderRadius: "2px",
          }}
        />
      </Box>
    </Box>
  );
};

import React from "react";
import { Select, MenuItem, alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OpenDrawer } from "../../../../../../icons";

const HEIGHT = "32px";
const ICON_SIZE = "20px";

interface Framework {
  name: string;
  color: string;
  image?: React.ElementType;
}

interface LargeFilterProps {
  frameworks: Framework[];
  activeFilter: string;
  onFilterChange: (value: string) => void;
}

const LargeFilter: React.FC<LargeFilterProps> = ({
  frameworks,
  activeFilter,
  onFilterChange,
}) => {
  const theme = useTheme();

  const dropdownBgColor =
    activeFilter === "Show All"
      ? theme.palette.colors.royalBlue[500]
      : frameworks.find((framework) => framework.name === activeFilter)
          ?.color || theme.palette.colors.royalBlue[500];

  const getMenuItemStyles = (isSelected: boolean) => ({
    backgroundColor: isSelected
      ? `${dropdownBgColor} !important`
      : "white !important",
    color: isSelected
      ? "white !important"
      : `${theme.palette.text.primary} !important`,
    height: "40px !important",
    "&:hover": {
      backgroundColor: isSelected
        ? `${alpha(dropdownBgColor, 0.8)} !important`
        : `${alpha(theme.palette.colors.blue[900], 0.05)} !important`,
    },
  });

  const uniqueFrameworks = [
    "Show All",
    ...Array.from(
      new Set(frameworks.map((framework) => framework.name))
    ).sort(),
  ];

  return (
    <Select
      value={activeFilter}
      onChange={(event) => onFilterChange(event.target.value as string)}
      displayEmpty
      inputProps={{ "aria-label": "Filter options" }}
      IconComponent={() => (
        <OpenDrawer
          sx={{
            marginLeft: "-20px !important",
            fill: "white !important",
            height: ICON_SIZE,
            width: ICON_SIZE,
            marginRight: "8px !important",
          }}
        />
      )}
      sx={{
        height: HEIGHT,
        backgroundColor: `${dropdownBgColor} !important`,
        color: "white !important",
        fontWeight: "700 !important",
        borderRadius: "8px !important",
        "& .MuiSelect-select": {
          paddingLeft: "12px !important",
          display: "flex !important",
          alignItems: "center !important",
        },
        boxShadow: "none !important",
        "&:hover": {
          boxShadow: "none !important",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: "16px !important",
            "& .MuiMenuItem-root": {
              backgroundColor: "white !important",
              "&.Mui-selected": {
                backgroundColor: `${dropdownBgColor} !important`,
                color: "white !important",
                "&:hover": {
                  backgroundColor: `${alpha(dropdownBgColor, 0.8)} !important`,
                },
              },
            },
          },
        },
      }}
    >
      {uniqueFrameworks.map((name, index) => {
        if (name === "Show All" && index > 0) return null;

        const FrameworkImage = frameworks.find(
          (framework) => framework.name === name
        )?.image;
        return (
          <MenuItem
            key={name}
            value={name}
            sx={{
              ...getMenuItemStyles(activeFilter === name),
              background: `${
                activeFilter === name ? dropdownBgColor : "white"
              } !important`,
              "&.Mui-selected": {
                backgroundColor: `${dropdownBgColor} !important`,
                color: "white !important",
                "&:hover": {
                  backgroundColor: `${alpha(dropdownBgColor, 0.8)} !important`,
                },
              },
            }}
          >
            {FrameworkImage && (
              <FrameworkImage
                sx={{
                  fill:
                    activeFilter === name
                      ? "white !important"
                      : `${theme.palette.text.primary} !important`,
                  height: ICON_SIZE,
                  width: ICON_SIZE,
                  marginRight: "8px !important",
                }}
              />
            )}
            {name}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default LargeFilter;

import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  setShowPageWrapper,
  setSignInMessage,
} from "../../../features/ui/publicUiStateSlice";
import { loadUserEngagement } from "../../../features/users/userEngagement";
import ConfirmationPopup from "../../popups/ConfirmationPopup";
import { PageProps } from "../../page-manager/PageManager";
import {
  logout,
  setEmailValidatedFlow,
  setManualLogout,
} from "../../../features/users/userAuthSlice";
import LoadingBlock from "./blocks/LoadingBlock";
import AccountAPISection from "./sections/api-section/AccountAPISection";
import AccountHeaderSection from "./sections/AccountHeaderSection";
import AccountSynqerSection from "./sections/AccountSynqerSection";
import AccountApiKeySection from "./sections/api-key-section/AccountAPIKeySection";
import { EditApiKeyPopup } from "./blocks/edit-api-key-popup/EditApiKeyPopup";
import { closeApiKeyPopup } from "../../../features/ui/apiKeyPopupSlice";
import { IApiKey } from "../../../types/api-key/apiKeyTypes";
import {
  listApiKeys,
  updateApiKey,
  createApiKey,
} from "../../../features/api-key/apiKeySlice";

const AccountPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userEngagementSlice = useAppSelector((state) => state.userEngagement);
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const apiKeyPopupSlice = useAppSelector((state) => state.apiKeyPopup);
  const apiKeySlice = useAppSelector((state) => state.apiKey);
  const hasAtLeastOneApiKey = apiKeySlice.apiKeys?.length > 0;
  const userAuthLoading = userAuthSlice.loading;
  const [showLoading, setShowLoading] = useState(userAuthLoading);
  const { isOpen, selectedApiKey } = apiKeyPopupSlice;

  const handleApiKeySave = async (formData: IApiKey, isUpdate: boolean) => {
    try {
      if (isUpdate && selectedApiKey) {
        await dispatch(
          updateApiKey({ ...selectedApiKey, ...formData })
        ).unwrap();
      } else {
        await dispatch(createApiKey(formData)).unwrap();
      }
      await dispatch(listApiKeys());
    } catch (error) {
      console.error("Error saving API key:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (
      !userAuthSlice.userAuth?.token ||
      userAuthSlice.userAuth?.token === undefined ||
      userAuthSlice.userAuth?.token === ""
    ) {
      dispatch(setSignInMessage("Please log in to view your account."));
      navigate("/auth");
      return;
    }

    dispatch(setShowPageWrapper(showPageWrapper));
    loadPageData();
  }, []);

  useEffect(() => {
    if (!userDetailsSlice.userDetails && !userAuthLoading) {
      navigate("/auth");
    }
  }, [userAuthLoading]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const userDetails = localStorage.getItem("userDetails");

      if (
        userDetails === null ||
        userDetails === undefined ||
        userDetailsSlice.userDetails === null ||
        userDetailsSlice.userDetails === undefined ||
        userDetailsSlice.userDetails?.onboarded === false
      ) {
        dispatch(setManualLogout(true));
        dispatch(logout());
        dispatch(setEmailValidatedFlow(true));
        navigate("/auth");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userDetailsSlice]);

  async function loadPageData() {
    const userEngagementExists = userEngagementSlice.userEngagement !== null;
    if (userEngagementExists) {
      setShowLoading(true);
      await Promise.all([
        dispatch(loadUserEngagement()),
        dispatch(listApiKeys()),
      ]);

      setShowLoading(false);
      return;
    } else {
      setShowLoading(true);
      await Promise.all([
        dispatch(loadUserEngagement()),
        dispatch(listApiKeys()),
      ]);
      setShowLoading(false);
    }
  }

  return (
    <Box
      flexDirection={"column"}
      display="flex"
      right={0}
      left={0}
      top={0}
      bottom={0}
      marginTop={"-70px"}
      paddingTop={"120px"}
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
      }}
    >
      <ConfirmationPopup />
      <AccountHeaderSection />
      {showLoading ? (
        <LoadingBlock />
      ) : (
        <>
          {!hasAtLeastOneApiKey ? (
            <AccountAPISection />
          ) : (
            <AccountApiKeySection />
          )}
          <AccountSynqerSection />
          <EditApiKeyPopup
            open={isOpen}
            onClose={() => dispatch(closeApiKeyPopup())}
            apiKey={selectedApiKey}
            onSave={handleApiKeySave}
          />
        </>
      )}
    </Box>
  );
};

export default AccountPage;

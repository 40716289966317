import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import CleanSVG from "../../../../services/CleanSVG";
import { SvgName } from "../../../../images/SvgAssets";
import { useEffect, useRef, useState } from "react";

export enum ContentOrientation {
  leftToRight = "left-to-right",
  rightToLeft = "right-to-left",
  vertical = "vertical",
}

export enum DotsLocation {
  left = "left",
  right = "right",
}

interface LandingSectionBaseProps {
  title: string;
  description: string;
  image: SvgName;
  color: string;
  orientationPreference: ContentOrientation;
  dotsLocation?: DotsLocation;
}

export const SectionBase: React.FC<LandingSectionBaseProps> = ({
  title,
  description,
  image,
  color,
  orientationPreference,
  dotsLocation = null,
}) => {
  const theme = useTheme();
  const [animate, setAnimate] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const ref = useRef(null);

  // isXs is a boolean that is true if the screen width is less than 500px
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const orientation = isXs
    ? ContentOrientation.vertical
    : orientationPreference;
  const flexDirection = () =>
    orientation === ContentOrientation.vertical
      ? "column-reverse"
      : orientation === ContentOrientation.leftToRight
      ? "row"
      : "row-reverse";

  useEffect(() => {
    // console.log("orientationPreference", orientationPreference);
    // console.log("orientation", orientation);
    // console.log("flexDirection", flexDirection);
  }, [orientationPreference, orientation, isXs]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!hasAnimated && entry.isIntersecting) {
          setAnimate(true);
          setHasAnimated(true); // Mark that animation has occurred
        }
      },
      {
        threshold: 0.2,
        rootMargin: "0px",
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasAnimated]);

  theme.components = {
    MuiCssBaseline: {
      styleOverrides: `
        @keyframes slideFadeIn {
          from {
            transform: translateY(40px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .animate-on-scroll {
          opacity: 0;
          animation-fill-mode: forwards;
          animation-duration: 0.4s;
          animation-name: slideFadeIn;
        }
      `,
    },
  };

  return (
    <Box
      display={"flex"}
      position={"relative"}
      flexDirection={flexDirection()}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      sx={{
        background: color,
        color: "white",
        p: "32px",
        minHeight: "500px",
      }}
    >
      <Box
        ref={ref}
        display={"flex"}
        flexDirection={flexDirection()}
        justifyContent={"center"}
        alignItems={"center"}
        paddingLeft={"32px"}
        paddingRight={"32px"}
        paddingBottom={"24px"}
        sx={{
          animation: animate ? "slideFadeIn 1.0s forwards" : "none",
          width: "100%",
          maxWidth: "900px",
          opacity: 0,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{ width: "100%", maxWidth: "500px" }}
        >
          <Typography
            variant="h3"
            paddingTop={
              orientation === ContentOrientation.vertical ? "32px" : 0
            }
            textAlign={
              orientation === ContentOrientation.vertical ? "center" : "left"
            }
            sx={{ color: theme.palette.colors.grayScale.white }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            lineHeight={"1.4"}
            textAlign={
              orientation === ContentOrientation.vertical ? "center" : "left"
            }
            sx={{ color: theme.palette.colors.grayScale.white, opacity: 0.8 }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          display={
            orientation === ContentOrientation.vertical ? "none" : "flex"
          }
          sx={{
            marginLeft: "32px",
            marginRight: "32px",
            backgroundColor: "white",
            width: "4px",
            borderRadius: "5px",
            height: "200px",
          }}
        />
        <CleanSVG
          name={image}
          style={{
            height: "150px",
            width: "150px",
          }}
        />
      </Box>
      {dotsLocation && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",

            opacity: 0.1,
            zIndex: 0,
          }}
        >
          <CleanSVG
            name={SvgName.Dots}
            style={{
              width: "auto",
              height: "900px",
              position: "absolute",
              left:
                dotsLocation && dotsLocation === DotsLocation.left
                  ? "-40%"
                  : "50%",
              top: "-85%",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

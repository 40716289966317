import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  useTheme,
  Dialog,
  DialogContent,
  Typography,
  Divider,
} from "@mui/material";
import ProfileImage from "./ProfileImage";
import { Close, LinkedIn, X } from "../../../../../icons";

const ProfileBlock = ({ member, bioHeight }) => {
  const [open, setOpen] = useState(false);

  const buildName = (firstName, middleInitial, lastName) => {
    let finalName = firstName + " ";
    if (middleInitial) {
      finalName += middleInitial + " ";
    }
    finalName += lastName;
    return finalName;
  };

  const theme = useTheme();
  const scale = 150 / 150;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      position={`relative`}
      maxWidth={`700px`}
      marginBottom={`20px`}
      marginTop={`20px`}
    >
      <ProfileImage
        src={member?.image}
        size={150}
        theme={theme}
        showSelected={true}
        isFeatured={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={"16px"}
        marginLeft={`${20 * scale}px`}
        marginRight={`${20 * scale}px`}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          fontSize={`${20 * scale}px`}
          fontWeight={800}
          color={theme.palette.colors.grayScale.white}
          textAlign={"center"}
        >
          {buildName(
            member?.firstName,
            member?.middleInitial,
            member?.lastName
          )}
        </Box>
        <Box
          display={"flex"}
          fontSize={`${14 * scale}px`}
          fontWeight={700}
          color={theme.palette.colors.grayScale.white}
          textAlign={"center"}
          sx={{
            opacity: 0.6,
          }}
        >
          {member?.title}
        </Box>
        <Box
          display={"flex"}
          fontSize={`${14 * scale}px`}
          fontWeight={400}
          color={theme.palette.colors.grayScale.white}
          textAlign={"center"}
          marginTop={`16px`}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
          }}
        >
          {member?.shortBio}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginTop="20px"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: "14px",
              marginRight: "10px",
              backgroundColor: theme.palette.colors.blue[300],
              paddingLeft: "20px",
              paddingRight: "20px",
              boxShadow: "none",
              height: "40px",
              borderRadius: "8px",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: theme.palette.colors.blue[400],
              },
            }}
            onClick={handleOpen}
          >
            Full Bio
          </Button>
          {member?.xUrl && (
            <IconButton
              href={member?.xUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                marginRight: "10px",
                backgroundColor: theme.palette.colors.blue[500],
                fill: theme.palette.colors.grayScale.white,
                borderRadius: "8px",
                height: "32px",
                width: "32px",
                "&:hover": {
                  backgroundColor: theme.palette.colors.blue[600],
                },
              }}
            >
              <X />
            </IconButton>
          )}
          {member?.linkedInUrl && (
            <IconButton
              href={member?.linkedInUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: theme.palette.colors.blue[500],
                fill: theme.palette.colors.grayScale.white,
                borderRadius: "8px",
                padding: "7px",
                height: "32px",
                width: "32px",
                "&:hover": {
                  backgroundColor: theme.palette.colors.blue[600],
                },
              }}
            >
              <LinkedIn />
            </IconButton>
          )}
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            padding: "8px",
          },
        }}
      >
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box width="100%" />
              <ProfileImage
                src={member?.image}
                size={150}
                theme={theme}
                showSelected={true}
                isFeatured={true}
              />
              <Box
                display="flex"
                width="100%"
                height="100%"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <IconButton
                  onClick={handleClose}
                  sx={{
                    marginRight: "-16px",
                    marginTop: "-16px",
                    height: "32px",
                    width: "32px",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    padding: "5px",
                    "&:hover": {
                      backgroundColor: theme.palette.colors.grayScale[100],
                      opacity: 0.8,
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>

            <Typography
              variant="h6"
              color="textPrimary"
              align="center"
              sx={{ marginTop: "16px" }}
            >
              More about {member?.firstName}...
            </Typography>
            <Divider sx={{ width: "100%", margin: "16px 0" }} />
            <Typography variant="body1" color="textPrimary" align="center">
              {member?.fullBio}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ProfileBlock;

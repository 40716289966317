import { Box, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import ReactMarkdown from "react-markdown";
import content from "!!raw-loader!./Multisynq Frameworks.md";
import { getCustomMarkdownComponents } from "../../../customTheme/CustomMarkdownComponents";

const DocsPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customComponents = getCustomMarkdownComponents(theme);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      marginTop={"-70px"}
      paddingTop={"80px"}
      sx={{ background: theme.palette.background.headerBackgroundGradient }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"20px"}
        marginBottom={{ xs: "20px", md: "40px" }}
      >
        <Typography
          fontWeight={900}
          fontSize={"56px"}
          paddingRight={"4px"}
          sx={{
            color: theme.palette.colors.grayScale.white,
          }}
        >
          {`<`}
        </Typography>
        <Typography
          fontWeight={900}
          fontSize={"48px"}
          fontFamily={`Poppins`}
          sx={{
            color: theme.palette.colors.grayScale.white,
          }}
        >
          {`Docs`}
        </Typography>
        <Typography
          fontWeight={900}
          fontSize={"56px"}
          paddingLeft={"4px"}
          sx={{
            color: theme.palette.colors.grayScale.white,
          }}
        >
          {`/>`}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexGrow={2}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{
          background: "rgba(255, 255, 255, 1.0)",
          paddingBottom: "80px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "40px",
        }}
      >
        <Box width={{ xs: "90%", sm: "80%", md: "70%", lg: "60%" }}>
          <ReactMarkdown components={customComponents}>{content}</ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
};

export default DocsPage;

import {
  Microverse,
  Unity,
  WorldCore,
  Croquet,
  Logo,
  ShowAll,
  Partners,
} from "../../../icons";

export enum Framework {
  SHOW_ALL = "Show All",
  MICROVERSE = "Microverse",
  UNITY = "Unity",
  WORLDCORE = "Worldcore",
  CROQUET_JS_WEB_API = "Croquet JS Web API",
  VIRTUAL_DOM = "Virtual DOM",
  ROGUE_ENGINE = "Rogue Engine",
}

export interface IFramework {
  name: string;
  color: string;
  image: React.ReactNode;
}

export const generateFrameworks = ({ theme }) => {
  return [
    {
      name: Framework.SHOW_ALL,
      color: theme.palette.colors.royalBlue[500],
      image: ShowAll,
    },
    {
      name: Framework.MICROVERSE,
      color: theme.palette.colors.purple[500],
      image: Microverse,
    },
    {
      name: Framework.UNITY,
      color: theme.palette.colors.lightPurple[500],
      image: Unity,
    },
    {
      name: Framework.WORLDCORE,
      color: theme.palette.colors.darkPurple[500],
      image: WorldCore,
    },
    {
      name: Framework.CROQUET_JS_WEB_API,
      color: theme.palette.colors.magenta[500],
      image: Croquet,
    },
    {
      name: Framework.VIRTUAL_DOM,
      color: theme.palette.colors.purple[500],
      image: Logo,
    },
    {
      name: Framework.ROGUE_ENGINE,
      color: theme.palette.colors.blue[500],
      image: Partners,
    },
  ];
};

import { Box, Typography, useTheme } from "@mui/material";

export const InstructionCell = ({ icon, title, subtitle }) => {
  const theme = useTheme();
  const iconSize = 50;

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={iconSize}
        minWidth={iconSize}
        height={iconSize}
        minHeight={iconSize}
        borderRadius="50%"
        bgcolor={theme.palette.colors.blue[100]}
        mr={2}
      >
        <Typography
          variant="body1"
          fontSize={iconSize * 0.5}
          fontWeight={900}
          sx={{
            color: theme.palette.colors.royalBlue[500],
            width: "100%",
            textAlign: "center",
          }}
        >
          {icon}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="body1" fontSize={"1.1rem"} fontWeight={800}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={400}
          sx={{
            opacity: 0.8,
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

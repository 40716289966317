import { Box } from "@mui/material";
import PointsBlock from "../blocks/PointsBlock";
import AccountReferralSection from "./AccountReferralSection";

export const AccountSynqerSection = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1100px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <PointsBlock />
        <AccountReferralSection />
      </Box>
    </Box>
  );
};

export default AccountSynqerSection;

export const getVideoSrc = (safariFile, defaultFile, navigator) => {
  const getBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("safari") !== -1 &&
      userAgent.indexOf("chrome") === -1
    ) {
      return "safari";
    } else if (userAgent.indexOf("chrome") !== -1) {
      return "chrome";
    } else {
      return "other";
    }
  };

  const browser = getBrowser();
  if (browser === "safari") {
    return safariFile;
  } else {
    return defaultFile;
  }
};

import { useTheme } from "@mui/material";
import { Doc } from "../../../../../../../icons";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../../../../examples/blocks/action-bar/ActionButton";

export const ApiDocsButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleGoToDocs = () => {
    navigate("/docs");
  };

  return (
    <ActionButton
      icon={Doc}
      label="Docs"
      onClick={handleGoToDocs}
      bgColor={theme.palette.colors.blue[500]}
    />
  );
};

import React from "react";
import { Button, useTheme } from "@mui/material";

interface SignupButtonProps {
  handleClick: () => void;
  sx?: any;
  viewName: string;
}

const SignupButton: React.FC<SignupButtonProps> = ({
  handleClick,
  sx,
  viewName,
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={handleClick}
      disableRipple
      sx={{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: "inherit",
        backgroundColor: theme.palette.colors.blue[500],
        boxShadow: "none",
        borderRadius: "40px",
        transition: "color 0.0s",
        "&:hover": {
          backgroundColor: theme.palette.colors.blue[300],
        },
        ...sx,
      }}
    >
      {viewName}
    </Button>
  );
};

export default SignupButton;

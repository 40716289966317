import { Typography, useTheme, Box, Link } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../app/store";
import { ExamplesActionBar } from "./action-bar/ExamplesActionBar";

export const ExampleDetails = ({ setIsSplitScreen }) => {
  const theme = useTheme();
  const { selectedExample } = useAppSelector((state) => state.pageManager);
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [truncatedText, setTruncatedText] = useState("");
  const textRef = useRef(null);

  const lineHeight = 1.5;
  const maxLines = 3;
  const lineHeightInPx = 14 * lineHeight;
  const minHeight = `${maxLines * lineHeightInPx}px`;

  useEffect(() => {
    setExpanded(false);
  }, [selectedExample]);

  useEffect(() => {
    if (textRef.current && selectedExample) {
      const fullText = selectedExample.info;
      let truncated = fullText;
      setTruncatedText(fullText);

      const handleResize = () => {
        const element = textRef.current;
        element.innerText = fullText;

        // Check if text overflows
        const isOverflowing = element.scrollHeight > element.clientHeight;
        setIsOverflowing(isOverflowing);

        if (isOverflowing) {
          while (
            element.scrollHeight > element.clientHeight &&
            truncated.length > 0
          ) {
            truncated = truncated.slice(0, -1);
            element.innerText = truncated + "...  show more";
          }

          setTruncatedText(truncated + "...");
        } else {
          setTruncatedText(fullText);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [selectedExample, expanded]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const isFoothold = selectedExample?.title === "Foothold";

  if (isFoothold) {
    return null;
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography
          variant="h4"
          color={theme.palette.colors.blue[900]}
          textAlign={"left"}
          width={"100%"}
        >
          {selectedExample?.title}
        </Typography>
        <ExamplesActionBar setIsSplitScreen={setIsSplitScreen} />
      </Box>
      <Box
        sx={{
          position: "relative",
          minHeight: minHeight,
          overflow: expanded ? "visible" : "hidden",
          width: "100%",
        }}
      >
        <Typography
          ref={textRef}
          variant="body1"
          fontSize={"14px"}
          textAlign="left"
          width={"100%"}
          color={theme.palette.colors.blue[900]}
          sx={{
            lineHeight: `${lineHeight}em`,
            display: "-webkit-box",
            WebkitLineClamp: expanded ? "none" : maxLines,
            WebkitBoxOrient: "vertical",
            overflow: expanded ? "visible" : "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {expanded ? selectedExample?.info : truncatedText}
        </Typography>
        {!expanded && isOverflowing && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              paddingLeft: "4px",
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={handleToggleExpand}
              sx={{
                color: theme.palette.colors.blue[900],
                padding: "0 4px",
              }}
            >
              show more
            </Link>
          </Box>
        )}
        {expanded && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              paddingLeft: "4px",
              //background: `linear-gradient(to right, transparent, ${theme.palette.background.paper} 50%)`,
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={handleToggleExpand}
              sx={{
                color: theme.palette.colors.blue[900],
                padding: "0 4px",
                marginTop: "16px",
                top: 18,
              }}
            >
              show less
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

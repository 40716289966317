import {
  alpha,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppSelector } from "../../../../app/store";

const AccountHeaderSection = () => {
  const theme = useTheme();
  const userDetailsSlice = useAppSelector((state) => state.userDetails);

  return (
    <Box
      position={"relative"}
      display="flex"
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      marginBottom={"60px"}
    >
      <Box
        display="flex"
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={"20px"}
        marginBottom={"20px"}
      >
        <Typography
          fontSize={{ xs: 28, md: 38 }}
          fontWeight={800}
          color={theme.palette.colors.grayScale.white}
          textAlign={"center"}
          paddingLeft={{ xs: "20px", md: "0px" }}
          paddingRight={{ xs: "20px", md: "0px" }}
          sx={{
            opacity: 0.8,
          }}
        >
          {userDetailsSlice.userDetails ? (
            "Welcome, " + userDetailsSlice.userDetails?.firstName + "!"
          ) : (
            <CircularProgress size={24} thickness={6} />
          )}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={{ xs: 700, md: 500 }}
            textAlign={"center"}
            paddingLeft={{ xs: "20px", md: "0px" }}
            paddingRight={{ xs: "20px", md: "0px" }}
            color={{
              xs: alpha(theme.palette.colors.blue[200], 0.8),
              md: theme.palette.colors.blue[200],
            }}
          >
            To your account dashboard
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountHeaderSection;

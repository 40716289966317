import React, { useEffect } from "react";
import {
  Select,
  MenuItem,
  useTheme,
  CircularProgress,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { IApiRegion } from "../../../../../../types/api-key/apiKeyTypes";
import { useInputStyles } from "../inputStyles";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { CellComponentBase } from "../CellComponentBase";
import { fetchRegions } from "../../../../../../features/api-key/apiKeySlice";

interface StorageRegionBlockProps {
  region: IApiRegion;
  setRegion: (region: IApiRegion) => void;
  isEdit: boolean;
}

export const StorageRegionBlock: React.FC<StorageRegionBlockProps> = ({
  region,
  setRegion,
  isEdit,
}) => {
  const theme = useTheme();
  const inputStyles = useInputStyles({ theme });
  const dispatch = useAppDispatch();

  // Get the available regions and loading state from the Redux store
  const availableRegions = useAppSelector((state) => state.apiKey.meta);
  const apiKeys = useAppSelector((state) => state.apiKey.apiKeys);
  const loading = useAppSelector((state) => state.apiKey.loading);
  const userId = useAppSelector((state) => state.userAuth.userAuth?.user?._id); // Get the userId from the Redux store

  useEffect(() => {
    if (userId) {
      loadRegions();
    }
  }, [dispatch, userId]);

  const setRegionForNewKey = () => {
    if (!isEdit) {
      const mostRecentRegion =
        apiKeys?.length > 0
          ? apiKeys.reduce((mostRecent, current) => {
              return new Date(mostRecent.created) > new Date(current.created)
                ? mostRecent
                : current;
            }, apiKeys[0])
          : null;

      if (mostRecentRegion) {
        setRegion(mostRecentRegion.region);
      }
    }
  };

  const loadRegions = async () => {
    const regions = await dispatch(fetchRegions());
    setRegionForNewKey();
    return regions;
  };

  const handleRegionChange = (event: SelectChangeEvent) => {
    const selectedRegion = availableRegions.find(
      (region) => region.label === event.target.value
    );
    if (selectedRegion) {
      setRegion(selectedRegion);
    }
  };

  return (
    <CellComponentBase
      title="Storage Region"
      italicizeInstruction={isEdit}
      instruction={
        isEdit
          ? "This was set when the API key was created. It cannot be changed."
          : "Choose carefully. This cannot be changed later."
      }
      showDisabled={isEdit}
    >
      <Box position="relative" display="flex" alignItems="center">
        <Select
          fullWidth
          name="region"
          value={loading ? "" : region.label}
          onChange={handleRegionChange}
          displayEmpty
          disabled={loading || isEdit}
          renderValue={(value) =>
            value ? value.toString() : "Select Storage Region"
          }
          sx={{
            ...inputStyles,
            borderRadius: "8px",
            height: "40px",
            paddingTop: "2px",
            paddingBottom: "0px",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiSelect-select": {
              paddingTop: "8px",
              paddingBottom: "8px",
              height: "100%",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.colors.blue[900],
            },
          }}
        >
          <MenuItem value="" disabled>
            Select Storage Region
          </MenuItem>
          {availableRegions.map((regionOption: IApiRegion) => (
            <MenuItem key={regionOption.location} value={regionOption.label}>
              {regionOption.label}
            </MenuItem>
          ))}
        </Select>
        {loading && (
          <Box
            position="absolute"
            top="50%"
            marginTop="4px"
            marginLeft="130px"
            sx={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress
              size={20}
              thickness={4}
              sx={{ color: theme.palette.colors.grayScale[100] }}
            />
          </Box>
        )}
      </Box>
    </CellComponentBase>
  );
};

// create an enum for storage keys
export enum StorageKeys {
  userEngagement = "userEngagement",
  userDetails = "userDetails",
  userCredentials = "userCredentials",
  userAuth = "userAuth",
}

export class LocalStorageService {
  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key: string) {
    return JSON.parse(localStorage?.getItem(key));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}

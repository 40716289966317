import { Box, Typography, useTheme } from "@mui/material";

const CopiedPopup = ({ copied, position }) => {
  const theme = useTheme();

  if (!copied) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        left: `${position.x}px`,
        top: `${position.y - 60}px`,
        transform: "translateX(-50%)",
        backgroundColor: theme.palette.colors.blue[100],
        color: theme.palette.colors.blue[900],
        borderRadius: "8px",
        padding: "8px",
        zIndex: 1500,
        animation: "driftUpFadeOutGrow 1s ease-in-out 0.5s 1 normal both",
      }}
    >
      <Typography variant="body2" fontSize={"12px"} fontWeight={500}>
        Copied to clipboard!
      </Typography>
    </Box>
  );
};

export default CopiedPopup;

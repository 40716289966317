import React, { useState } from "react";
import { IconButton, Menu, MenuItem, alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Sort } from "../../../../../../icons";

const HEIGHT = "32px";
const ICON_SIZE = "20px";

interface Framework {
  name: string;
  color: string;
  image?: React.ElementType;
}

interface SmallFilterProps {
  frameworks: Framework[];
  activeFilter: string;
  onFilterChange: (value: string) => void;
}

const SmallFilter: React.FC<SmallFilterProps> = ({
  frameworks,
  activeFilter,
  onFilterChange,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dropdownBgColor =
    activeFilter === "Show All"
      ? theme.palette.colors.royalBlue[500]
      : frameworks.find((framework) => framework.name === activeFilter)
          ?.color || theme.palette.colors.royalBlue[500];

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getMenuItemStyles = (isSelected: boolean) => ({
    backgroundColor: isSelected
      ? `${dropdownBgColor} !important`
      : "white !important",
    color: isSelected
      ? "white !important"
      : `${theme.palette.text.primary} !important`,
    height: "40px !important",
    "&:hover": {
      backgroundColor: isSelected
        ? `${alpha(dropdownBgColor, 0.8)} !important`
        : `${alpha(theme.palette.colors.blue[900], 0.05)} !important`,
    },
  });

  const uniqueFrameworks = [
    "Show All",
    ...Array.from(
      new Set(frameworks.map((framework) => framework.name))
    ).sort(),
  ];

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        sx={{
          height: HEIGHT,
          backgroundColor: `${dropdownBgColor} !important`,
          color: "white !important",
          borderRadius: "8px !important",
          paddingLeft: "6px !important",
          paddingRight: "6px !important",
          "&:hover": {
            backgroundColor: `${alpha(dropdownBgColor, 0.8)} !important`,
            boxShadow: "none !important",
          },
        }}
      >
        <Sort
          sx={{
            fill: "white !important",
            height: ICON_SIZE,
            width: ICON_SIZE,
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "white !important",
            color: `${theme.palette.text.primary} !important`,
            borderRadius: "16px !important",
          },
          "& .MuiList-root": {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
          },
        }}
      >
        {uniqueFrameworks.map((name, index) => {
          if (name === "Show All" && index > 0) return null;

          const FrameworkImage = frameworks.find(
            (framework) => framework.name === name
          )?.image;
          return (
            <MenuItem
              key={name}
              onClick={() => onFilterChange(name)}
              sx={getMenuItemStyles(activeFilter === name)}
            >
              {FrameworkImage && (
                <FrameworkImage
                  sx={{
                    fill:
                      activeFilter === name
                        ? "white !important"
                        : `${theme.palette.text.primary} !important`,
                    height: ICON_SIZE,
                    width: ICON_SIZE,
                    marginRight: "8px !important",
                  }}
                />
              )}
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SmallFilter;

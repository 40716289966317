import { Button, Typography, alpha, useTheme } from "@mui/material";
import { AuthViews } from "../ViewContentHelper";
import { useAppDispatch } from "../../../../../app/store";
import {
  logout,
  setManualLogout,
} from "../../../../../features/users/userAuthSlice";

export const BackToLoginButton = ({ setView }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const callLogout = () => {
    dispatch(setManualLogout(true));
    dispatch(logout());
    setView(AuthViews.Login);
  };

  return (
    <Button
      sx={{
        marginRight: "16px",
        marginLeft: "16px",
        fontWeight: "300",
        fontSize: "14px",
        textTransform: "none",
        marginTop: "16px",
        color: alpha(theme.palette.colors.grayScale.white, 0.8),
        "&:hover": {
          color: theme.palette.colors.grayScale.white,
        },
      }}
      onClick={callLogout}
      disableRipple
    >
      Back to &nbsp;
      <Typography
        component="span"
        sx={{
          marginTop: "1px",
          fontWeight: "bold",
          color: alpha(theme.palette.colors.grayScale.white, 0.7),
        }}
      >
        Login
      </Typography>
    </Button>
  );
};

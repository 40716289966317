import { useEffect, useState } from "react";
import { Grid, Typography, alpha, useTheme } from "@mui/material";
import CustomCheckbox from "../../../../ui-elements/CustomCheckbox";

interface AuthCheckboxBlockProps {
  title: string;
  subtitle: string;
  setValue: (value: string) => void;
  value: string;
}

const AuthCheckboxBlock = ({
  title,
  subtitle,
  setValue,
  value,
}: AuthCheckboxBlockProps) => {
  const theme = useTheme();
  const [checked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value === "true" ? true : false);
  }, [value]);

  const handleCheckboxChange = async (isChecked: boolean) => {
    setValue(isChecked ? "true" : "false");
  };

  const termsLink = `${process.env.REACT_APP_FRONTEND_URL}/terms`;
  const privacyLink = `${process.env.REACT_APP_FRONTEND_URL}/privacy`;
  const termsText = "Terms & Conditions";
  const privacyText = "Privacy Policy";
  const spacer = " / ";

  const TermsTitleComponent = () => {
    return (
      <Typography
        fontWeight={300}
        fontSize={"12px"}
        color={theme.palette.colors.grayScale.white}
        sx={{
          a: {
            color: theme.palette.colors.grayScale.white,
            fontWeight: 800,
            // remove underline
            textDecoration: "none",
            "&:hover": {
              color: alpha(theme.palette.colors.grayScale.white, 0.8),
              cursor: "pointer",
            },
          },
        }}
      >
        <a href={termsLink} target="_blank" rel="noreferrer">
          {termsText}
        </a>
        {spacer}
        <a href={privacyLink} target="_blank" rel="noreferrer">
          {privacyText}
        </a>
      </Typography>
    );
  };

  return (
    <Grid
      container
      direction={"row"}
      alignContent={"center"}
      justifyContent="start"
      paddingBottom={"16px"}
    >
      <Grid item paddingTop={"3px"} sx={{ width: "24px" }}>
        <CustomCheckbox
          isChecked={checked}
          setIsChecked={handleCheckboxChange}
          size="16px"
        />
      </Grid>
      <Grid
        item
        container
        sx={{ width: "calc(100% - 24px)" }}
        paddingLeft={"6px"}
        direction={"column"}
      >
        {title === "Terms" ? (
          <TermsTitleComponent />
        ) : (
          <Typography
            fontWeight={800}
            fontSize={"12px"}
            color={alpha(theme.palette.colors.grayScale.white, 0.9)}
          >{`${title}`}</Typography>
        )}
        <Typography
          fontWeight={300}
          fontSize={"12px"}
          color={alpha(theme.palette.colors.grayScale.white, 0.6)}
        >
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthCheckboxBlock;

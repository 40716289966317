import { useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { CodeHeader } from "./sections/CoderHeaderSection";
import { DeveloperSection } from "./sections/developer-section/DeveloperSection";
import { SvgName } from "../../../images/SvgAssets";
import {
  SectionBase,
  ContentOrientation,
  DotsLocation,
} from "../synchronizer/sections/SectionBase";
import NewWaySection from "../synchronizer/sections/new-way-section/NewWaySection";

const CoderPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const theme = useTheme();

  return (
    <Box
      flexDirection={"column"}
      display="flex"
      alignItems={"center"}
      right={0}
      left={0}
      top={0}
      bottom={0}
      marginTop={"-70px"}
      paddingTop={"120px"}
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
      }}
    >
      <CodeHeader />
      <DeveloperSection />
      <SectionBase
        title={`What is Multisynq${"\u2122"}?`}
        description="Multisynq is DePIN infrastructure for the synchronization of everything. We are a decentralized, low-latency Synchronization Network, allowing individuals to monetize their surplus internet connection by selling bandwidth to developers creating unified experiences on the Internet."
        image={SvgName.LogoImg}
        color={theme.palette.colors.blue[500]}
        orientationPreference={ContentOrientation.leftToRight}
      />
      <SectionBase
        title={"How does it work?"}
        description={`Multisynq connects Synqers and Coders.  Anyone can be a Synqer, running Synchronizers on their desktop as a part of the community and the network, earning Multipoint rewards. And Coders easily use Multisynq Network to build multiplayer games and apps that are low latency, perfectly synchronized and very low cost to operate.`}
        image={SvgName.UsersImg}
        color={theme.palette.colors.purple[500]}
        orientationPreference={ContentOrientation.rightToLeft}
        dotsLocation={DotsLocation.right}
      />
      <NewWaySection />
      <SectionBase
        title={"Synchronize everything."}
        description={`Multisynq revolutionizes real-time synchronization, providing a unified solution across diverse applications and games. Client deterministic computation, decentralized architecture and robust security empower developers to deliver unmatched user experiences. Multisynq ushers in a new era of perfectly synchronized online environments at latencies targeting 15-30ms.`}
        image={SvgName.ClockGear}
        color={theme.palette.colors.blue[500]}
        orientationPreference={ContentOrientation.vertical}
      />
      <SectionBase
        title={"Leveraging the security of blockchain."}
        description={`Multisynq ensures robust security and privacy through end-to-end encryption and access controls, leveraging blockchain's decentralized architecture for enhanced resilience and transparency, fostering trust in real-time synchronization and collaboration within the digital landscape.`}
        color={theme.palette.colors.purple[500]}
        image={SvgName.BlockShield}
        orientationPreference={ContentOrientation.leftToRight}
        dotsLocation={DotsLocation.left}
      />
    </Box>
  );
};

export default CoderPage;

import { alpha, Box, Typography, useTheme } from "@mui/material";
import { CustomIconButton } from "../../../../../../ui-elements/CustomIconButton";
import { useAppDispatch } from "../../../../../../../app/store";
import { openApiKeyPopup } from "../../../../../../../features/ui/apiKeyPopupSlice";
import { Add } from "../../../../../../../icons";
import ActionButton from "../../../../../examples/blocks/action-bar/ActionButton";

export const CreateApiKeyButton = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleAddKey = () => {
    dispatch(openApiKeyPopup());
  };

  return (
    <ActionButton
      icon={Add}
      label="New"
      onClick={handleAddKey}
      bgColor={theme.palette.colors.royalBlue[500]}
    />
  );
};

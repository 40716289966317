import React from "react";
import { Box, Tooltip, Typography, Divider } from "@mui/material";
import { CustomIconButton } from "../../../../../ui-elements/CustomIconButton";
import { Delete } from "../../../../../../icons";
import { useTheme } from "@mui/material/styles";

interface AllowedUrlCellProps {
  url: string;
  index: number;
  displayUrls: string[];
  handleRemoveUrl: (index: number) => void;
  isAllowDevCell: boolean;
}

export const AllowedUrlCell: React.FC<AllowedUrlCellProps> = ({
  url,
  index,
  displayUrls,
  handleRemoveUrl,
  isAllowDevCell,
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.colors.blue[900];

  return (
    <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={url} placement="top">
        <Typography
          variant="body2"
          color={isAllowDevCell ? theme.palette.colors.blue[500] : primaryColor}
          sx={{
            fontWeight: isAllowDevCell ? "700" : "600",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            fontSize: "0.8rem",
            flex: 1,
            padding: "8px",
            paddingRight: "32px",
            paddingLeft: "16px",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          {url}
        </Typography>
      </Tooltip>

      <CustomIconButton
        icon={<Delete />}
        onClick={() => handleRemoveUrl(index)}
        sx={{
          backgroundColor: "transparent",
          marginTop: "8px",
          marginBottom: "8px",
          marginRight: "8px",
          padding: "4px",
          height: "24px",
          width: "24px",
        }}
      />

      {index < displayUrls.length - 1 && <Divider />}
    </Box>
  );
};

import { Box } from "@mui/material";
import { AuthViewState } from "../ViewContentHelper";
import { useEffect } from "react";

interface AuthFormWrapperProps {
  viewState: AuthViewState;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
}

export const AuthFormWrapper: React.FC<AuthFormWrapperProps> = ({
  viewState,
  handleSubmit,
  children,
}) => {
  useEffect(() => {
    //console.log("viewState", viewState);
  }, [viewState]);

  return (
    <Box
      component={"form"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      width={"100%"}
      paddingLeft={"16px"}
      paddingRight={"16px"}
      sx={{
        visibility: viewState === AuthViewState.Loading ? "hidden" : "visible",
      }}
      onSubmit={handleSubmit}
    >
      {children}
    </Box>
  );
};

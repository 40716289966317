import React from "react";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  setFilteredExamples,
  setFilters,
} from "../../../../../../features/ui/pageManagerStateSlice";
import SmallFilter from "./SmallFilter";
import LargeFilter from "./LargeFilter";
import { useMediaQuery } from "@mui/material";

interface Framework {
  name: string;
  color: string;
  image?: React.ElementType;
}

const FilterDropdown = ({ frameworks }: { frameworks: Framework[] }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const allExamples = useAppSelector((state) => state.pageManager.allExamples);
  const activeFilter = useAppSelector((state) => state.pageManager.filters);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFilterChange = (value: string) => {
    dispatch(setFilters(value));
    const filtered =
      value === "Show All"
        ? allExamples
        : allExamples.filter((example) => example.framework === value);
    dispatch(setFilteredExamples(filtered));
  };

  return isSmallScreen ? (
    <SmallFilter
      frameworks={frameworks}
      activeFilter={activeFilter}
      onFilterChange={handleFilterChange}
    />
  ) : (
    <LargeFilter
      frameworks={frameworks}
      activeFilter={activeFilter}
      onFilterChange={handleFilterChange}
    />
  );
};

export default FilterDropdown;

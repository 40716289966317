import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";

import {
  IAuthFormData,
  IGoogleAuthFormData,
  IUserAuth,
} from "../../types/user/userTypes";
import {
  clearUserCredentials,
  loadUserCredentials,
} from "./userCredentialsSlice";
import { RootState } from "../../app/store";
import {
  LocalStorageService,
  StorageKeys,
} from "../../services/LocalStorageService";
import { handleAxiosError } from "../../app/errorHandler";
import { clearUserDetails, loadUserDetails } from "./userDetailsSlice";
import { clearUserEngagement, loadUserEngagement } from "./userEngagement";

// TODO - validate clean up state?
const initialState = {
  loading: false,
  userAuth: LocalStorageService.get("userAuth") as IUserAuth | null,
  manualLogout: false,
  forceLogout: false,
  reactivationToken: null as string | null,
  reactivationEmailSent: false,
  activeReferralCode: null as string | null,
  emailValidatedFlow: false,
};

export const loginGoogleUser = createAsyncThunk(
  "userAuth/loginGoogleUser",
  async (
    googleAuthFormData: IGoogleAuthFormData,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      googleAuthFormData.reactivationToken = state.userAuth.reactivationToken;
      dispatch(setReactivationToken(null));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));
      const { data } = await api.loginGoogleUser(googleAuthFormData);
      LocalStorageService.set(StorageKeys.userAuth, data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const signupGoogleUser = createAsyncThunk(
  "userAuth/signupGoogleUser",
  async (
    googleAuthFormData: IGoogleAuthFormData,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const referralCode = state.userAuth.activeReferralCode;

      if (referralCode) {
        googleAuthFormData = {
          ...googleAuthFormData,
          referralCode: referralCode,
        };
      }

      dispatch(setLoading(true));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      const { data } = await api.signUpGoogleUser(googleAuthFormData);
      LocalStorageService.set(StorageKeys.userAuth, data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const login = createAsyncThunk(
  "userAuth/login",
  async (
    loginFormData: IAuthFormData,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      loginFormData.reactivationToken = state.userAuth.reactivationToken;
      dispatch(setReactivationToken(null));
      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));
      const { data } = await api.login(loginFormData);
      LocalStorageService.set(StorageKeys.userAuth, data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const signup = createAsyncThunk(
  "userAuth/signup",
  async (
    signupFormData: IAuthFormData,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const referralCode = state.userAuth.activeReferralCode;
      if (referralCode) {
        signupFormData = {
          ...signupFormData,
          referralCode: referralCode,
        };
      }

      dispatch(setForceLogout(false));
      dispatch(setManualLogout(false));
      dispatch(setLoading(true));
      const { data } = await api.signUp(signupFormData);
      LocalStorageService.set(StorageKeys.userAuth, data);
      dispatch(setUserAuth(data));
      dispatch(loadUser());
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const requestReactivation = createAsyncThunk(
  "userAuth/requestReactivation",
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const payload = { email: email };
      const { data } = await api.requestReactivationEmail(payload);
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const validateToken = createAsyncThunk(
  "userAuth/validateToken",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.validateToken();
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const logout = createAsyncThunk(
  "userAuth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(clearUserDetails());
      dispatch(clearUserCredentials());
      dispatch(clearUserEngagement());
      LocalStorageService.clear();
      return "Logout successful";
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const loadUser = createAsyncThunk(
  "userAuth/loadUser",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      let user = state.userAuth.userAuth?.user;
      if (user === undefined || user === null) {
        const userAuth = LocalStorageService.get(
          StorageKeys.userAuth
        ) as IUserAuth;
        user = userAuth.user;
      }

      const userDetailsId = user?.userDetails;
      const userCredentialsId = user?.userCredentials;

      const promises = [];
      promises.push(dispatch(loadUserDetails(userDetailsId)));
      promises.push(dispatch(loadUserCredentials(userCredentialsId)));
      promises.push(dispatch(loadUserEngagement()));

      await Promise.all(promises);
      dispatch(setLoading(false));
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteUser = createAsyncThunk(
  "userAuth/deleteUser",
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.deleteUser(userId);
      dispatch(logout());
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const cancelUser = createAsyncThunk(
  "userAuth/cancelUser",
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.cancelUser(userId);
      dispatch(logout());
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const getReferralCode = createAsyncThunk(
  "userAuth/getReferralCode",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.getReferralCode();
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserAuth: (state, action) => {
      state.userAuth = action.payload;
    },
    setManualLogout: (state, action) => {
      state.manualLogout = action.payload;
    },
    setForceLogout: (state, action) => {
      state.forceLogout = action.payload;
    },
    setReactivationToken: (state, action) => {
      state.reactivationToken = action.payload;
    },
    setReactivationEmailSent: (state, action) => {
      state.reactivationEmailSent = action.payload;
    },
    setActiveReferalCode: (state, action) => {
      state.activeReferralCode = action.payload;
    },
    setEmailValidatedFlow: (state, action) => {
      state.emailValidatedFlow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });

    builder.addCase(loginGoogleUser.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(signupGoogleUser.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(login.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });

    builder.addCase(signup.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
    builder.addCase(validateToken.rejected, (state) => {
      LocalStorageService.clear();
      state.userAuth = null;
    });
  },
});

export const {
  setManualLogout,
  setLoading,
  setUserAuth,
  setForceLogout,
  setReactivationToken,
  setReactivationEmailSent,
  setActiveReferalCode,
  setEmailValidatedFlow,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;

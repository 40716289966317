import { Box, Stack, Typography, alpha } from "@mui/material";

export const ExampleThumbnail = ({
  example,
  selectedExample,
  handleClick,
  theme,
  getDataForFramework,
  IconComponent,
}) => {
  return (
    <Box
      key={example.id}
      display={"flex"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      marginRight={"20px"}
      marginBottom={"0px"}
      paddingBottom={"0px"}
      width={"190px"}
      height={"130px"}
      sx={{
        cursor: "pointer",
        borderRadius: "8px",
        transition: "all 0.3s",
        opacity: selectedExample === example ? 1 : 0.6,
        "&:hover": {
          backgroundColor: alpha(theme.palette.colors.grayScale.black, 0.1),
        },
      }}
      onClick={(event) => handleClick(event, example)}
    >
      <Stack>
        <Box
          display={"flex"}
          flexDirection={"row"}
          position={"absolute"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          bottom={{ xs: "8px", sm: "0px" }}
          width={"190px"}
          height={"30px"}
          sx={{
            borderRadius: "0px 0px 8px 8px",
            zIndex: 1000,
            backgroundColor: getDataForFramework(example.framework).color,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            width={"100%"}
            height={"100%"}
          >
            <Box sx={{ width: "24px", height: "24px" }}>
              <IconComponent frameworkName={example.framework} />
            </Box>
            <Typography
              variant={"body1"}
              textAlign={"left"}
              fontSize={"12px"}
              width={"100%"}
              color={theme.palette.colors.grayScale.white}
              sx={{ fontWeight: 600, marginLeft: "10px" }}
            >
              {example.framework}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"190px"}
          height={"130px"}
          sx={{
            borderRadius: "8px",
            color: theme.palette.colors.blue[900],
            backgroundColor: alpha(theme.palette.colors.grayScale.black, 0.05),
          }}
        >
          <img
            src={example.image}
            alt={example.title}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              pointerEvents: "none",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import landingPageMov from "../../../../../videos/landingPageAnimation.mov";
import landingPageWebm from "../../../../../videos/landingPageAnimation.webm";
import { getVideoSrc } from "../../../../helpers/VideoSource";
import "./HeaderAnimation.css";

const headerFrames = [
  {
    title: "Join the Revolution",
    text: "Signup now and start earning rewards instantly!",
    start: 0,
    end: 183 - 90,
  },
  {
    title: "Lightning Fast",
    text: "Utilize your idle bandwidth to facilitate high performance multiplayer sessions.",
    start: 242,
    end: 425,
  },
  {
    title: "Rewards at Rest",
    text: "Your Synchronizer operates in the background, earning rewards while you sleep.",
    start: 454,
    end: 659,
  },
  {
    title: "Create with Ease",
    text: "Craft bit-identical experiences for multiple users at only 20% of traditional costs.",
    start: 672,
    end: 861,
  },
  {
    title: "Developer's Dream",
    text: "Skip the backend complexity. Build using client-side code with robust encryption.",
    start: 873,
    end: 1038,
  },
  {
    title: "Blazing Performance",
    text: "Achieve ultra-fast connections - powered by The Missing Protocol of the Internet.",
    start: 1048,
    end: 1195,
  },
];

export const HeaderAnimation = ({ theme, selected, selectFrame }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoSource, setVideoSource] = useState("");
  const videoRef = useRef(null);
  const frameRef = useRef(headerFrames[0]);
  const [currentTime, setCurrentTime] = useState(0);
  const [fadeStateTitle, setFadeStateTitle] = useState("fadeInTitle");
  const [fadeStateSubtitle, setFadeStateSubtitle] = useState("fadeInSubtitle");

  const updateVideoSource = () => {
    const source = getVideoSrc(
      landingPageMov,
      landingPageWebm,
      window.navigator
    );
    console.log("source", source);
    setVideoSource(source);
  };

  useEffect(() => {
    updateVideoSource();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadeddata", handleLoadedData);
      videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener("loadeddata", handleLoadedData);
          videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        }
      };
    }
  }, [videoSource]);

  const handleLoadedData = () => {
    setIsLoading(false);
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime * 30;
      const newFrame = headerFrames.find(
        (frame) => currentTime >= frame.start && currentTime <= frame.end
      );

      const currentFrame = frameRef.current;
      if (newFrame && newFrame !== currentFrame) {
        setFadeStateTitle("fadeOutTitle");
        setFadeStateSubtitle("fadeOutSubtitle");
        setTimeout(() => {
          frameRef.current = newFrame;
          setFadeStateTitle("fadeInTitle");
          setFadeStateSubtitle("fadeInSubtitle");
        }, 500);
      }
      setCurrentTime(videoRef.current.currentTime);

      // Check if the current time exceeds the end of the last frame
      const endOfLastFrame = headerFrames[headerFrames.length - 1].end;
      if (currentTime > endOfLastFrame) {
        handleVideoEnd();
      }
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      frameRef.current = headerFrames[0];
      setFadeStateTitle("fadeInTitle");
      setFadeStateSubtitle("fadeInSubtitle");
    }
  };

  const videoHeight = useMediaQuery(theme.breakpoints.down("sm"))
    ? "240px"
    : "360px";

  return (
    <Box
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Box
        flexDirection={{ xs: "column-reverse", md: "column" }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
          display={"flex"}
          paddingBottom={"24px"}
        >
          <Typography
            variant="h4"
            align="center"
            color={theme.palette.colors.grayScale.white}
            className={fadeStateTitle}
            gutterBottom
          >
            {frameRef.current.title}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color={theme.palette.colors.grayScale.white}
            className={fadeStateSubtitle}
            sx={{
              maxWidth: { xs: "300px", sm: "400px" },
              width: { xs: "100%", sm: "400px" },
              paddingLeft: "16px",
              paddingRight: "16px",
              marginRight: "16px",
              marginLeft: "16px",
              opacity: 0.8,
              minHeight: "64px",
            }}
            gutterBottom
          >
            {frameRef.current.text}
          </Typography>
        </Box>
        <Box
          flexDirection={"row"}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          width={"500px"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            position={"relative"}
            sx={{
              pointerEvents: "none",
              overflow: "hidden",
              marginLeft: "0px",
              height: { xs: "280px", sm: "360px" },
            }}
          >
            {isLoading && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection={"column"}
                  alignItems="center"
                  height={{ xs: "280px", sm: "360px" }}
                  width="100%"
                  position="absolute"
                  top="80"
                  left="0"
                  zIndex="1"
                >
                  <CircularProgress
                    thickness={6}
                    sx={{
                      opacity: 0.8,
                      color: theme.palette.colors.grayScale.white,
                      marginBottom: 4,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ ml: 2, color: "white", opacity: 0.8 }}
                  >
                    Loading...
                  </Typography>
                </Box>
                <Box height={`420px`} width="100%" />
              </>
            )}
            <video
              ref={videoRef}
              src={videoSource}
              autoPlay
              muted
              playsInline
              onEnded={handleVideoEnd}
              style={{
                height: videoHeight,
                position: "relative",
                objectFit: "cover",
                left: "0px",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "32px" }}>
          <Typography
            variant="h3"
            align="center"
            color={theme.palette.colors.grayScale.white}
            sx={{
              display: { xs: "block", md: "none" },
            }}
            gutterBottom
          >
            How It Works
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userAuthReducer from "../features/users/userAuthSlice";
import userCredentialsSlice from "../features/users/userCredentialsSlice";
import userDetailsSlice from "../features/users/userDetailsSlice";
import userEngagement from "../features/users/userEngagement";
import confirmationPopupSlice from "../features/ui/confirmationPopupSlice";
import errorSlice from "../features/ui/errorSlice";
import publicUiStateSlice from "../features/ui/publicUiStateSlice";
import pageManagerStateSlice from "../features/ui/pageManagerStateSlice";
import apiKeyPopupSlice from "../features/ui/apiKeyPopupSlice";
import apiKeySlice from "../features/api-key/apiKeySlice";

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    userCredentials: userCredentialsSlice,
    userDetails: userDetailsSlice,
    userEngagement: userEngagement,
    confirmationPopup: confirmationPopupSlice,
    errorMessenger: errorSlice,
    publicUiState: publicUiStateSlice,
    pageManager: pageManagerStateSlice,
    apiKeyPopup: apiKeyPopupSlice,
    apiKey: apiKeySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.headers"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export default store;

import { ReactComponent as Dots } from "../images/dots.svg";
import { ReactComponent as NoderSvg } from "../images/noder.svg";
import { ReactComponent as ReferralArrow } from "../images/referral-arrow.svg";
import { ReactComponent as BlockShield } from "./landing-imgs/block-shield-img.svg";
import { ReactComponent as ClockGear } from "./landing-imgs/clock-gear-img.svg";
import { ReactComponent as LogoImg } from "./landing-imgs/logo-img.svg";
import { ReactComponent as UsersImg } from "./landing-imgs/users-img.svg";
import { ReactComponent as DiscordLogo } from "./socials/discord-logo.svg";
import { ReactComponent as DiscordLogoWhite } from "./socials/discord-logo-white.svg";
import { ReactComponent as InstagramLogo } from "./socials/insta-logo.svg";
import { ReactComponent as InstagramLogoWhite } from "./socials/insta-logo-white.svg";
import { ReactComponent as XLogo } from "./socials/x-logo.svg";
import { ReactComponent as XLogoWhite } from "./socials/x-logo-white.svg";
import { ReactComponent as CoderImg } from "../images/coder-page/coder-img.svg";
import { ReactComponent as CopyLogo } from "./docs-page/copy-logo.svg";
import { ReactComponent as CroquetLogo } from "./docs-page/croquet-logo.svg";
import { ReactComponent as MicroverseLogo } from "./docs-page/microverse-logo.svg";
import { ReactComponent as ReactLogo } from "./docs-page/react-logo.svg";
import { ReactComponent as ShowcaseLogo } from "./docs-page/showcase-logo.svg";
import { ReactComponent as UnityLogo } from "./docs-page/unity-logo.svg";
import { ReactComponent as LogoLong } from "./logo-long.svg";
import { ReactComponent as Logo } from "./docs-page/logo-logo.svg";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as RogueEngineLogo } from "./docs-page/rogue-engine-logo.svg";
import { ReactComponent as ReactTogetherLogo } from "./docs-page/react-together-logo.svg";

export enum SvgName {
  Dots = "dots",
  Synqer = "noder",
  ReferralArrow = "referral-arrow",
  BlockShield = "block-shield",
  ClockGear = "clock-gear",
  LogoImg = "logo-img",
  UsersImg = "users-img",
  DiscordLogo = "discord-logo",
  DiscordLogoWhite = "discord-logo-white",
  InstagramLogo = "insta-logo",
  InstagramLogoWhite = "insta-logo-white",
  XLogo = "x-logo",
  XLogoWhite = "x-logo-white",
  CoderImg = "coder-img",
  CopyLogo = "copy-logo",
  CroquetLogo = "croquet-logo",
  MicroverseLogo = "microverse-logo",
  ReactLogo = "react-logo",
  ShowcaseLogo = "showcase-logo",
  UnityLogo = "unity-logo",
  LogoLong = "logo-long",
  Logo = "logo",
  Rocket = "rocket",
  RogueEngineLogo = "rogue-engine-logo",
  ReactTogetherLogo = "react-together-logo",
}

const SVG_COMPONENTS = {
  [SvgName.Dots]: Dots,
  [SvgName.Synqer]: NoderSvg,
  [SvgName.ReferralArrow]: ReferralArrow,
  [SvgName.BlockShield]: BlockShield,
  [SvgName.ClockGear]: ClockGear,
  [SvgName.LogoImg]: LogoImg,
  [SvgName.UsersImg]: UsersImg,
  [SvgName.DiscordLogo]: DiscordLogo,
  [SvgName.DiscordLogoWhite]: DiscordLogoWhite,
  [SvgName.InstagramLogo]: InstagramLogo,
  [SvgName.InstagramLogoWhite]: InstagramLogoWhite,
  [SvgName.XLogo]: XLogo,
  [SvgName.XLogoWhite]: XLogoWhite,
  [SvgName.CoderImg]: CoderImg,
  [SvgName.CopyLogo]: CopyLogo,
  [SvgName.CroquetLogo]: CroquetLogo,
  [SvgName.MicroverseLogo]: MicroverseLogo,
  [SvgName.ReactLogo]: ReactLogo,
  [SvgName.ShowcaseLogo]: ShowcaseLogo,
  [SvgName.UnityLogo]: UnityLogo,
  [SvgName.LogoLong]: LogoLong,
  [SvgName.Logo]: Logo,
  [SvgName.Rocket]: Rocket,
  [SvgName.RogueEngineLogo]: RogueEngineLogo,
  [SvgName.ReactTogetherLogo]: ReactTogetherLogo,
};

export default SVG_COMPONENTS;

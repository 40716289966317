const MaskShape = ({ scale }: { scale: number }) => (
  <svg
    width={`${300 * scale}`}
    height={`${364 * scale}`}
    viewBox={`0 0 ${300 * scale} ${364 * scale}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="mask">
        <path
          d={`M${300 * scale} ${214 * scale}C${300 * scale} ${
            296.843 * scale
          } ${232.843 * scale} ${364 * scale} ${150 * scale} ${364 * scale}C${
            67.1573 * scale
          } ${364 * scale} 0 ${296.843 * scale} 0 ${214 * scale}C0 0.5 0 0.5 ${
            150 * scale
          } 0.5C${300 * scale} 0.5 ${300 * scale} 0.5 ${300 * scale} ${
            214 * scale
          }Z`}
          transform={`translate(${57 * scale}, 0)`}
        />{" "}
      </clipPath>
    </defs>
  </svg>
);

export default MaskShape;

import { Box, Drawer, IconButton, List, useTheme } from "@mui/material";
import { Logo, Dev, Close, Team } from "../../../icons"; // Make sure the path to your icons is correct
import CleanSVG from "../../../services/CleanSVG";
import { SvgName } from "../../../images/SvgAssets";
import { MenuItem } from "./MenuItem";
import { AuthSection } from "./AuthSection";
import DrawerTermsFooter from "./DrawerTermsFooter";
import { MenuDivider } from "./MenuDivider";
import { PlayArrowRounded } from "@mui/icons-material";

export const MobileMenuDrawer = ({
  handleMenuClick,
  setDrawerOpen,
  drawerOpen,
}) => {
  const theme = useTheme();

  const handleClick = (viewName: string, forceNavigate = false) => {
    setDrawerOpen(false);

    if (viewName !== null && viewName !== undefined && viewName !== "") {
      handleMenuClick(viewName, forceNavigate);
    }
  };

  const menuItems = (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <List sx={{ width: "100%" }}>
        <AuthSection handleMenuClick={handleClick} theme={theme} />
        <MenuDivider hideTop />
        <MenuItem
          IconComponent={Dev}
          text="Coder"
          onClick={() => handleClick("coder")}
          isSubMenu={false}
        />
        <MenuDivider />
        <MenuItem
          IconComponent={Logo}
          text="Synqer"
          onClick={() => handleClick("synqer")}
          isSubMenu={false}
        ></MenuItem>

        <MenuDivider />
        <MenuItem
          IconComponent={PlayArrowRounded}
          text="Examples"
          onClick={() => handleClick("examples")}
          isSubMenu={false}
        />
        <MenuDivider />
        <MenuItem
          IconComponent={Team}
          text="Team"
          onClick={() => handleClick("team")}
          isSubMenu={false}
        />
        <MenuDivider />
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      aria-label="Mobile Menu"
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: { xs: "100%", sm: "400px" },
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height: "40px", mt: "10px" }}
      >
        {<Box display={{ xs: "none", sm: "block" }} width={"30px"} />}
        <Box
          display={"flex"}
          sx={{
            width: "160px",
            height: "auto",
            marginLeft: "16px",
            marginTop: "5px",
          }}
        >
          <CleanSVG
            name={SvgName.LogoLong}
            style={{
              width: "100%",
              color: theme.palette.colors.royalBlue[500],
            }}
          />
        </Box>

        <IconButton
          aria-label="close drawer"
          edge="end"
          onClick={() => setDrawerOpen(false)}
          size="small"
          sx={{
            height: "30px",
            width: "30px",
            marginTop: "5px",
            marginRight: "23px",
          }}
        >
          <Close
            sx={{
              fill: theme.palette.colors.royalBlue[900],
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
      </Box>
      {menuItems}
      <DrawerTermsFooter />
    </Drawer>
  );
};

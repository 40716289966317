import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/errorHandler";
import {
  LocalStorageService,
  StorageKeys,
} from "../../services/LocalStorageService";
import { IUserEngagement } from "../../types/user/userEngagement";

const initialState = {
  userEngagement: LocalStorageService.get(
    StorageKeys.userEngagement
  ) as IUserEngagement | null,
};

export const loadUserEngagement = createAsyncThunk(
  "userEngagement/loadUserEngagement",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.loadUserEngagement();
      dispatch(setUserEngagement(data));
      LocalStorageService.set(StorageKeys.userEngagement, data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearUserEngagement = createAsyncThunk(
  "userEngagement/clearUserEngagement",
  async (_, { dispatch }) => {
    LocalStorageService.remove(StorageKeys.userEngagement);
    dispatch(setUserEngagement(null));
  }
);

export const userEngagementSlice = createSlice({
  name: "userEngagement",
  initialState: initialState,
  reducers: {
    setUserEngagement: (state, action) => {
      state.userEngagement = action.payload;
    },
  },
});

export const { setUserEngagement } = userEngagementSlice.actions;

export default userEngagementSlice.reducer;

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Box,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import logo_blue from "../images/logo-blue.svg";
import { Circle } from "@mui/icons-material";
import CleanSVG from "../services/CleanSVG";
import { SvgName } from "../images/SvgAssets";

const getNameForLogo = (title) => {
  if (title.includes("React Together")) {
    return SvgName.ReactTogetherLogo;
  }
  if (title.includes("Unity")) {
    return SvgName.UnityLogo;
  }
  if (title.includes("Kernel")) {
    return SvgName.Logo;
  }
  if (title.includes("Web Showcase")) {
    return SvgName.ShowcaseLogo;
  }
  if (title.includes("Virtual DOM")) {
    return SvgName.Logo;
  }
  if (title.includes("Microverse")) {
    return SvgName.MicroverseLogo;
  }
  if (title.includes("Worldcore")) {
    return SvgName.CroquetLogo;
  }
  if (title.includes("Rogue Engine")) {
    return SvgName.RogueEngineLogo;
  }

  return null;
};

const getTitleWithLogo = (title, theme, link, color) => {
  const svgName = getNameForLogo(title);
  const content = (
    <Box display="flex" alignItems="center">
      {svgName && (
        <CleanSVG
          name={svgName}
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            height: svgName === SvgName.Logo ? "50px" : "38px",
            width: svgName === SvgName.Logo ? "50px" : "38px",
            color: color,
            fill: color,
          }}
        />
      )}
      {title}
    </Box>
  );

  return link ? (
    <a
      href={link}
      style={{ textDecoration: "none", color: color }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content}
    </a>
  ) : (
    content
  );
};

const getTextFromReactNode = (node) => {
  if (typeof node === "string") {
    return node;
  }
  if (Array.isArray(node)) {
    return node.map(getTextFromReactNode).join("");
  }
  if (node.props && node.props.children) {
    return getTextFromReactNode(node.props.children);
  }
  return "";
};

const getLinkFromReactNode = (node) => {
  if (node.props && node.props.href) {
    return node.props.href;
  }
  if (node.props && node.props.children) {
    return getLinkFromReactNode(node.props.children);
  }
  return null;
};

export const getCustomMarkdownComponents = (
  theme: Theme,
  customBullets = false
) => {
  return {
    h1: ({ node, ...props }) => {
      const title = getTextFromReactNode(props.children);
      const link = getLinkFromReactNode(props.children);
      const color = theme.palette.text.primary;
      return (
        <h1
          style={{
            marginTop: "24px",
            marginBottom: "8px",
            fontSize: "2em",
            fontWeight: "bold",
            color: color,
          }}
        >
          {getTitleWithLogo(title, theme, link, color)}
        </h1>
      );
    },
    h2: ({ node, ...props }) => {
      const title = getTextFromReactNode(props.children);
      const link = getLinkFromReactNode(props.children);
      const color = theme.palette.colors.blue[500];
      return (
        <h2
          style={{
            marginTop: "24px",
            marginBottom: "4px",
            fontSize: "1.5em",
            fontWeight: "800",
            color: color,
          }}
        >
          {getTitleWithLogo(title, theme, link, color)}
        </h2>
      );
    },
    a: ({ node, ...props }) => (
      <a
        style={{ textDecoration: "none", color: theme.palette.primary.main }}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      />
    ),
    p: ({ node, ...props }) => (
      <p style={{ marginBottom: "16px" }} {...props} />
    ),
    ul: ({ node, ordered, children, depth, ...props }) => {
      const margin = depth * 20;
      return (
        <List
          style={{
            paddingTop: customBullets ? "16px" : "0px",
            marginLeft: `${margin}px`,
          }}
        >
          {children}
        </List>
      );
    },
    li: ({ node, children, ...props }) => {
      if (!customBullets) {
        return (
          <ListItem sx={{ margin: 0, padding: 0 }} {...props}>
            <ListItemIcon>
              <Circle
                sx={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  color: theme.palette.colors.blue[500],
                  margin: "0px",
                  marginLeft: "24px",
                  padding: "0px",
                }}
              />
            </ListItemIcon>
            <ListItemText primary={children} />
          </ListItem>
        );
      }
      return (
        <ListItem>
          <ListItemIcon>
            <img src={logo_blue} alt="multisynq logo" width="30" height="30" />
          </ListItemIcon>
          <ListItemText primary={children} />
        </ListItem>
      );
    },
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={style}
          language={match[1]}
          PreTag="div"
          {...props}
          customStyle={{ marginBottom: "16px" }}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} style={{ marginBottom: "16px" }} {...props}>
          {children}
        </code>
      );
    },
    img: ({ src, alt, title }) => (
      <img
        src={src}
        alt={alt}
        title={title}
        style={{ maxWidth: "100%", height: "auto" }}
      />
    ),
  };
};

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/errorHandler";
import { IApiKey, IApiRegion } from "../../types/api-key/apiKeyTypes";
import { RootState } from "../../app/store";
import { dummyKeys } from "./dummyApiKeys";

const isTest = false;

interface ApiKeyState {
  loading: boolean;
  apiKeys: IApiKey[];
  meta: IApiRegion[];
  error: any;
  filterString: string;
}

const initialState: ApiKeyState = {
  loading: false,
  apiKeys: isTest ? dummyKeys : [],
  meta: [],
  error: null,
  filterString: "",
};

// Fetch API key metadata (regions)
export const fetchRegions = createAsyncThunk(
  "apiKeys/fetchRegions",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.userAuth.userAuth?.user?._id;
      const meta = await api.fetchRegions();
      return meta.data.map(({ transformRequest, ...rest }) => rest);
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// List API keys for the authenticated user
export const listApiKeys = createAsyncThunk(
  "apiKeys/listKeys",
  async (_, { getState, rejectWithValue }) => {
    try {
      if (isTest) {
        return dummyKeys; // FOR TESTING
      }

      const state = getState() as RootState;
      const userId = state.userAuth.userAuth?.user?._id;
      const keys = await api.listApiKeys(true);
      return keys.data || []; // Return an empty array if keys.data is falsy
    } catch (error) {
      // if 404, return empty array
      if (error.response?.status === 404) {
        return [];
      } else {
        return rejectWithValue(handleAxiosError(error));
      }
    }
  }
);

// Create a new API key
export const createApiKey = createAsyncThunk(
  "apiKeys/createKey",
  async (keyData: Omit<IApiKey, "userId">, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.userAuth.userAuth?.user?._id;
      const fullKeyData = { ...keyData, userId };
      const key = await api.createApiKey(fullKeyData);
      return key.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// Update an API key
export const updateApiKey = createAsyncThunk(
  "apiKeys/updateKey",
  async (keyData: IApiKey, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.userAuth.userAuth?.user?._id;
      const fullKeyData = { ...keyData, userId };
      const updatedKey = await api.updateApiKey(fullKeyData);
      return updatedKey.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// Delete an API key
export const deleteApiKey = createAsyncThunk(
  "apiKeys/deleteKey",
  async (keyData: Omit<IApiKey, "userId">, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.userAuth.userAuth?.user?._id;
      const fullKeyData = { ...keyData, userId };
      const result = await api.deleteApiKey(fullKeyData);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

const apiKeySlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    resetApiKeys: (state) => {
      state.apiKeys = [];
      state.meta = [];
      state.error = null;
    },
    setFilterString: (state, action: PayloadAction<string>) => {
      state.filterString = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.meta = action.payload;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(listApiKeys.pending, (state) => {
        state.loading = true;
      })
      .addCase(listApiKeys.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKeys = action.payload || [];
      })
      .addCase(listApiKeys.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.apiKeys = [];
      })
      .addCase(createApiKey.pending, (state) => {
        state.loading = true;
      })
      .addCase(createApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKeys.push(action.payload);
      })
      .addCase(createApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateApiKey.fulfilled, (state, action) => {
        state.loading = false;
        const updatedKey = action.payload;
        const index = state.apiKeys.findIndex(
          (key) => key.apiKey === updatedKey.apiKey
        );
        if (index !== -1) {
          state.apiKeys[index] = updatedKey;
        }
      })
      .addCase(updateApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKeys = state.apiKeys.filter(
          (key) => key.apiKey !== action.payload
        );
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetApiKeys, setFilterString } = apiKeySlice.actions;

// Selector for filtered API keys
export const selectFilteredApiKeys = (state: RootState) => {
  const { apiKeys, filterString } = state.apiKey;
  if (!filterString.trim()) {
    return apiKeys;
  }
  const lowercasedFilter = filterString.toLowerCase();
  return apiKeys.filter(
    (key) =>
      key.apiKey.toLowerCase().includes(lowercasedFilter) ||
      key.name.toLowerCase().includes(lowercasedFilter) ||
      key.region.location.toLowerCase().includes(lowercasedFilter) ||
      key.region.label.toLowerCase().includes(lowercasedFilter) ||
      key.allowedUrls.some((url) =>
        url.toLowerCase().includes(lowercasedFilter)
      ) ||
      new Date(key.lastUsed)
        .toLocaleString()
        .toLowerCase()
        .includes(lowercasedFilter) ||
      new Date(key.created)
        .toLocaleString()
        .toLowerCase()
        .includes(lowercasedFilter) ||
      (key.allowDev &&
        "localhost and local network".toLowerCase().includes(lowercasedFilter))
  );
};

export default apiKeySlice.reducer;

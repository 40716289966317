import { Box, Typography, alpha, colors, lighten } from "@mui/material";
import ImageLoader from "../../../synchronizer/sections/header-section/ImageLoader";

export const BlockTitle = ({ theme, title }) => {
  return (
    <div id={title == "Resources" ? "resources" : undefined}>
      <Typography
        variant="body1"
        fontWeight={800}
        paddingTop={"8px"}
        paddingLeft={"8px"}
        color={theme.palette.colors.blue[900]}
        paddingBottom={"8px"}
      >
        {title}
      </Typography>
    </div>
  );
};

export const BlockWrapper = ({ theme, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      width={"100%"}
      marginTop={{ xs: "4px", sm: "0px" }}
      padding={"8px"}
      sx={{
        borderRadius: "8px",
        backgroundColor: lighten(theme.palette.colors.blue[100], 0.5),
      }}
    >
      {children}
    </Box>
  );
};

export const ResourcesLink = ({ theme, navigate, text, route }) => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent default anchor behavior

    if (route.endsWith(".pdf")) {
      // If the route ends with .pdf, open it in a new browser tab
      window.open(route, "_blank");
    } else {
      // Use internal navigation for everything else
      navigate(route);
    }
  };

  const linkStyle = {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.colors.blue[900],
    display: "block",
    "&:hover": {
      color: theme.palette.colors.royalBlue[500],
      transition: "0.15s",
      backgroundColor: alpha(theme.palette.colors.blue[900], 0.05),
      borderRadius: "4px",
    },
  };

  return (
    <a
      href={route} // Maintain href for SEO purposes; functionality overridden by onClick
      onClick={handleClick}
      style={{ textDecoration: "none", width: "100%" }}
    >
      <Typography
        variant="body1"
        fontSize={"13px"}
        fontWeight={500}
        color={theme.palette.colors.blue[900]}
        paddingTop={"6px"}
        paddingBottom={"6px"}
        paddingLeft={"8px"}
        paddingRight={"8px"}
        width={"100%"}
        sx={linkStyle}
      >
        {text}
      </Typography>
    </a>
  );
};

export const WhyCell = ({ theme, title, img, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width={"100%"}
      height={"100%"}
      alignContent={"center"}
      sx={{
        paddingTop: "16px",
      }}
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        height="100px"
        width="100px"
        minHeight={"100px"}
        minWidth={"100px"}
        sx={{
          backgroundColor: lighten(theme.palette.colors.blue[100], 0.5),
          borderRadius: "50px",
        }}
      >
        <ImageLoader
          heightOverride={75}
          widthOverride={75}
          src={img}
          placeholder={img}
          alt="Why Cell"
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        paddingLeft={"16px"}
      >
        <Typography
          fontSize={"14px"}
          fontWeight={800}
          color={theme.palette.colors.blue[900]}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export const WhyList = ({ children }) => {
  return (
    <Box
      component="ul"
      sx={{
        marginTop: "-10px",
        p: 0,
        "& li": {
          mb: 0.5,
        },
      }}
    >
      {children}
    </Box>
  );
};

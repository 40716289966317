import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

interface UrlErrorBlockProps {
  urlErrorMessage: string;
  hideUrlError: () => void;
}

export const UrlErrorBlock: React.FC<UrlErrorBlockProps> = ({
  urlErrorMessage,
  hideUrlError,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.red[100],
        borderRadius: "8px",
        padding: "16px",
        margin: "16px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: "8px",
          textAlign: "center",
        }}
      >
        {urlErrorMessage}
      </Typography>
      <Typography sx={{ textAlign: "center", marginBottom: "8px" }}>
        Please ensure your URL is unique and valid. The URL should be in a
        standard format (e.g., example.com, sub.example.com). You can include or
        omit the protocol (http://, https://). Non-ASCII characters must be
        Punycode-encoded.
      </Typography>
      <Typography
        onClick={hideUrlError}
        sx={{
          fontWeight: "bold",
          color: theme.palette.colors.red[500],
          "&:hover": {
            color: theme.palette.colors.red[600],
            cursor: "pointer",
          },
        }}
      >
        Dismiss
      </Typography>
    </Box>
  );
};

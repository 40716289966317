import React, { useState, useEffect, KeyboardEvent, useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Add } from "../../../../../../icons";
import { CustomButton } from "../../../../../ui-elements/CustomButton";
import CustomCheckbox from "../../../../../ui-elements/CustomCheckbox";
import { CellComponentBase } from "../CellComponentBase";
import { URLValidator } from "../../../../../../services/UrlValidator";
import { UrlErrorBlock } from "./UrlErrorBlock";
import { ApiTextField } from "./ApiTextField";
import { AllowedUrlCell } from "./AllowedUrlCell";

interface AllowedUrlsBlockProps {
  urls: string[];
  setUrls: (urls: string[]) => void;
  setAllowDev: (allowDev: boolean) => void;
  allowDev: boolean;
}

export const AllowedUrlsBlock: React.FC<AllowedUrlsBlockProps> = ({
  urls = [],
  setUrls,
  setAllowDev,
  allowDev,
}) => {
  const theme = useTheme();
  const [newUrl, setNewUrl] = useState("");
  const [displayUrls, setDisplayUrls] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [showUrlError, setShowUrlError] = useState(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const primaryColor = theme.palette.colors.blue[900];

  useEffect(() => {
    setDisplayUrls(allowDev ? ["localhost and local network", ...urls] : urls);
  }, [urls, allowDev]);

  useEffect(() => {
    setDisabled(newUrl.trim() === "");
  }, [newUrl]);

  const handleRemoveUrl = (index: number) => {
    if (allowDev && index === 0) {
      setAllowDev(false);
      return;
    }

    const updatedUrls = urls.filter(
      (_, i) => i !== (allowDev ? index - 1 : index)
    );
    setUrls(updatedUrls);
  };

  const handleNewUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUrl(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddUrl();
    }
  };

  const handleAddUrl = () => {
    const errorMessage = URLValidator.validate(newUrl, urls);

    if (!errorMessage) {
      setUrls([...urls, newUrl.trim()]);
      setNewUrl("");
      hideUrlError();
      inputRef.current?.focus();
    } else {
      setUrlErrorMessage(errorMessage);
      setShowUrlError(true);
    }
  };

  const hideUrlError = () => {
    setShowUrlError(false);
  };

  const handleAllowDevChange = (checked: boolean) => {
    setAllowDev(checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CellComponentBase
        title="Allowed URLs"
        instruction="Enter a list of partial URLs (e.g., example.com, or bla.example.com/foo/bar) where this key will be valid.  Enable all local development by checking the box, or add individual local URLs of your development machine (e.g. localhost or 192.168.0.123)."
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
            marginBottom: "16px",
            gap: "8px",
          }}
        >
          <CustomCheckbox
            isChecked={!!allowDev} // Ensure it's boolean
            setIsChecked={handleAllowDevChange}
            size="16px"
          />
          <Box
            sx={{ display: "flex", flexDirection: "column", marginLeft: "4px" }}
          >
            <Typography variant="body1">
              Allow localhost & local network
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginBottom: "8px",
          }}
        >
          <ApiTextField
            placeholder="Enter URL to add..."
            value={newUrl}
            onChange={handleNewUrlChange}
            onKeyDown={handleKeyPress}
            name="newUrl"
            inputRef={inputRef}
          />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <CustomButton
              onClick={handleAddUrl}
              disabled={newUrl.trim() === ""}
              sx={{ height: "28px", fontSize: "0.8rem" }}
            >
              Add
            </CustomButton>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              borderRadius: "4px",
              padding: "4px",
              height: "28px",
              width: "28px",
              backgroundColor: disabled
                ? theme.palette.colors.grayScale[100]
                : theme.palette.colors.blue[500],
              cursor: disabled ? "default" : "pointer",
              fill: disabled
                ? theme.palette.colors.grayScale[200]
                : theme.palette.colors.grayScale.white,
              "&:hover": {
                backgroundColor: disabled
                  ? theme.palette.colors.grayScale[100]
                  : theme.palette.colors.blue[600],
              },
              "&:active": {
                transform: disabled ? "none" : "scale(0.9)",
              },
            }}
            onClick={disabled ? undefined : handleAddUrl}
          >
            <Add />
          </Box>
        </Box>

        {showUrlError && (
          <UrlErrorBlock
            urlErrorMessage={urlErrorMessage}
            hideUrlError={hideUrlError}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.colors.grayScale[75],
            borderRadius: "8px",
          }}
        >
          {displayUrls.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Typography
                variant="body1"
                fontSize="0.8rem"
                color={primaryColor}
                sx={{ opacity: 0.5 }}
              >
                No Approved URLs
              </Typography>
            </Box>
          ) : (
            <Box sx={{ paddingTop: "4px", paddingBottom: "4px" }}>
              {displayUrls.map((url, index) => (
                <AllowedUrlCell
                  key={index}
                  url={url}
                  index={index}
                  displayUrls={displayUrls}
                  handleRemoveUrl={handleRemoveUrl}
                  isAllowDevCell={index === 0 && allowDev}
                />
              ))}
            </Box>
          )}
        </Box>
      </CellComponentBase>
    </Box>
  );
};

// UtilityService.tsx
const UtilityService = {
  /**
   * Extracts the string after "#" in the URL and replaces "%20" with a space.
   * @param location - The URL or location string that contains the "#".
   * @returns The formatted string after the "#".
   */
  getStringFromLocation: (location: string): string => {
    const hashIndex = location.indexOf("#");
    if (hashIndex !== -1) {
      const fragment = location.substring(hashIndex + 1);
      return decodeURIComponent(fragment); // replaces "%20" with spaces
    }
    return ""; // returns an empty string if there's no "#"
  },

  /**
   * Converts a string into a URL fragment by replacing spaces with "%20" and adding "#".
   * @param str - The input string to be converted.
   * @returns The URL fragment string.
   */
  getLocationFromString: (str: string): string => {
    const encodedString = encodeURIComponent(str); // replaces spaces with "%20"
    return `#${encodedString}`;
  },

  // make date read as "1 day ago", hours ago, minutes ago, seconds ago etc. Depending on what's most recent

  getRelativeTimeString: (date: Date): string => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffInSeconds = Math.floor(diff / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInYears > 0) {
      return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    } else if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
    }
  },
  getDateString: (date: Date): string => {
    const locale = navigator.language;
    const isUS = locale.startsWith("en-US");

    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: isUS ? "numeric" : "2-digit",
      day: "2-digit",
    };

    const dateString = date.toLocaleDateString(locale, options);
    return dateString;
  },
};

export default UtilityService;

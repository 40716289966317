// BetaPending.tsx
import React from "react";
import { Typography, alpha, useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";

interface ReactivationEmailSentViewProps {
  setView: (view: AuthViews) => void;
}

const ReactivationEmailSentView: React.FC<ReactivationEmailSentViewProps> = ({
  setView,
}) => {
  const theme = useTheme();

  return (
    <AuthViewWrapper
      title="Check Your Email"
      viewState={AuthViewState.Default}
      currentErrorMessage={""}
      clearErrorMessage={() => {
        console.log("clearErrorMessage");
      }}
    >
      <Typography
        variant="body2"
        color={alpha(theme.palette.colors.grayScale.white, 0.7)}
        textAlign={"center"}
        lineHeight={1.6}
        sx={{ mb: 2, mt: 2 }}
      >
        {`You have been sent a Reactivation email. Please click the link in the button to be redirected to the login page in order to complete the reactivation process.`}
      </Typography>
    </AuthViewWrapper>
  );
};

export default ReactivationEmailSentView;

import { Typography, Button, Box, useTheme, alpha } from "@mui/material";
import React from "react";
import { CustomEmoji } from "../../../../ui-elements/CustomIcon";
import {
  BustsInSilhouetteEmoji,
  GiftEmoji,
  LightningEmoji,
  MicrophoneEmoji,
  PilotEmoji,
} from "../../../../../icons/emojis";
import { useNavigate } from "react-router-dom";

interface PhaseCardProps {
  title: string;
  subtitle: string;
  signupBonus: string;
  pointMultiplier: string;
  referralMuliplier: string;
  isActive?: boolean;
}

export const PhaseCard: React.FC<PhaseCardProps> = ({
  title,
  subtitle,
  signupBonus,
  pointMultiplier,
  referralMuliplier,
  isActive = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const bulletPoint = ({ key, value, emoji }) => {
    return (
      <Box
        display="flex"
        flexDirection={"row"}
        paddingBottom={"4px"}
        alignItems={"center"}
      >
        {isActive && (
          <CustomEmoji
            image={emoji}
            sx={{
              height: "30px",
              width: "30px",
            }}
          />
        )}
        <Typography
          variant="body2"
          color="inherit"
          fontSize={"20px"}
          fontWeight={800}
          paddingRight={"8px"}
          paddingLeft={"8px"}
        >
          {key}
        </Typography>
        <Typography
          variant="body2"
          color="inherit"
          fontWeight={300}
          paddingTop={"2.6px"}
        >
          {value}
        </Typography>
      </Box>
    );
  };

  const submit = () => {
    const navRoute = "/auth?signup=true";
    navigate(navRoute);
  };

  return (
    <Box
      height={"100%"}
      padding={"16px"}
      maxWidth={{ xs: "350px", md: "300px" }}
      width={"100%"}
      position={isActive ? undefined : "relative"}
      sx={{
        backgroundColor: isActive
          ? theme.palette.colors.royalBlue[500]
          : theme.palette.colors.grayScale[200],

        borderRadius: "16px",
        opacity: isActive ? 0.9 : 0.8,
        transform: isActive ? "scale(1)" : "scale(0.9)",
        boxShadow: isActive ? "0px 0px 15px 5px rgba(0, 0, 0, 0.2)" : "none",
      }}
    >
      <Box flexDirection={"column"}>
        <Box
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {isActive && (
            <CustomEmoji
              image={PilotEmoji}
              sx={{
                height: "32px",
                width: "32px",
                paddingBottom: "5px",
                marginLeft: "-4px",
                marginRight: "4px",
              }}
            />
          )}
          <Typography
            gutterBottom
            variant="h4"
            fontSize={"20px"}
            component="div"
            color="inherit"
            fontWeight={700}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="inherit"
          paddingBottom={"16px"}
          fontWeight={500}
          sx={{ opacity: 0.9 }}
        >
          {subtitle}
        </Typography>
      </Box>
      {bulletPoint({
        key: signupBonus,
        value: "Signup Bonus",
        emoji: GiftEmoji,
      })}
      {bulletPoint({
        key: pointMultiplier,
        value: "Point Multiplier",
        emoji: LightningEmoji,
      })}
      {bulletPoint({
        key: referralMuliplier,
        value: "Point Referrals",
        emoji: BustsInSilhouetteEmoji,
      })}

      <Button
        onClick={submit}
        variant="contained"
        disabled={!isActive}
        sx={{
          "&:disabled": {
            color: alpha(theme.palette.colors.grayScale.white, 0.7),
          },
          "&:hover": {
            backgroundColor: theme.palette.colors.blue[400],
            boxShadow: "none",
          },

          marginTop: "16px",
          borderRadius: "30px",
          height: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          textTransform: "none",
          background: isActive ? theme.palette.colors.blue[500] : undefined,
          color: isActive
            ? theme.palette.colors.grayScale.white
            : theme.palette.colors.grayScale.white,
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        {isActive ? `Join Now` : `Claim`}
      </Button>
    </Box>
  );
};

export const privacyHtmlContent = `<html>

<head>
    <meta charset="UTF-8">
    <link rel="stylesheet" type="text/css" href="theme.css">
    <link rel="icon" type="image/png" href="/icon.png">
    <title>Multisynq - Privacy Statement</title>
</head>

<body>
    <h1>Privacy Policy</h1>
    <p>Effective Jan 2023</p>

    <h3>Scope</h3>

    <p>This Privacy Policy describes how Multisynq collects, process and stores your personal information. You
        accept this Privacy Policy when you open an account or use this website (“the Site”).
        By registering for an account or using the Site, you agree to the terms of this Privacy Policy. If you do not
        agree
        with this Privacy Policy, please do not use the Site.</p>

    <p>We may update this Privacy Policy at any time by simply posting such addition or modification on the Site. Your
        continued use of the Site following the posting of changes to these terms will mean you accept those changes.
        If at any point we decide to use personally identifiable information in a manner materially different from that
        stated in this Privacy Policy at the time such information was collected, we will notify users by way of an
        email or
        via a prominent notice on our Site. We do not collect information from individuals under the age of 18. If you
        are
        under 18, do not use this Site.

    <h3>Collection and Use of Your Personal Information</h3>
    <p>We ask for your username, email address and a password when you create an account on the Site and for your use of
        the Metaverse Web Showcase. We do not collect sensitive information (as such term is defined in the California
        Privacy Rights Act or the General Data Protection Regulation). Signing up for an account allows us to
        communicate
        with you concerning changes in our Terms of Service or this Privacy Policy, and to alert you to new
        demonstrations
        available on the Site or to further developments relating to Multisynq. Any emails that we send to you will
        include a functionality that allows you to opt out of receiving marketing emails or administrative emails or
        both.
        We do not ‘sell’ or ‘share’ information as such terms are defined in the California Privacy Rights Act.</p>

    <p>If you send us emails or other communications that include personal information, we will retain that information
        in
        our files to improve any service that we may provide to you in the future. You can, at any time, request that we
        delete such information from our files, and we will do so within sixty (60) days of request.</p>

    <p>When you use the Site, our servers automatically record standard information that your browser sends whenever you
        visit a website. These server logs may include information such as the page you visited, your Internet Protocol
        address, Multisynq cookie information, browser type, browser language, and the date and time of your visit.
        Multisynq
        uses this traffic data to help diagnose problems with its server, analyze trends and administer the Site. To opt
        out
        of such recording, please email <a
            href="mailto:privacy@multisynq.io?subject=Privacy Policy">privacy@multisynq.io</a>
    </p>

    <p>Generally, Multisynq automatically collects non-personally identifiable usage information, such as the numbers and
        frequency of visitors to our site and its components. This data is only used in the aggregate. This type of
        collective data enables us to determine how users interact with the Site, so we can identify areas for
        improvement.
        In accordance with the California Privacy Rights Act, we will not penalize you for exercising your rights by
        taking
        actions such as denying you services; increasing the price of services or decreasing service quality.</p>

    <h3>Disclosure of Personal Information</h3>
    <p>We may share with third parties certain pieces of aggregated, non-personally identifiable information such as
        the
        number of users who accessed or used particular demonstrations or other features of the Site. Such
        information does
        not identify you individually.</p>

    <p>We may employ other companies to perform tasks on our behalf and sometimes need to share your information
        with them
        to assist us in providing information or services to you. Examples include analyzing data, providing
        marketing
        assistance, and providing customer service. Any such other companies will be contractually obligated to us
        not to
        use such information for any other purpose. We will contract with those entities to ensure they provide the
        same
        level of privacy protection.</p>

    <p>In some cases, we may choose to buy or sell assets. In these types of transactions, customer and website user
        information is typically one of the business assets that is transferred. Moreover, if Multisynq, or
        substantially all
        of its assets, were acquired, customer and 3website user information would be one of the assets that is
        transferred.
        Additionally, we may disclose your personal information after notifying you and obtaining your consent.</p>

    <p>We may also need to disclose personal information when required by law, if we have a good-faith belief that
        such
        action is necessary to comply with a current judicial proceeding, a court order or legal process, enforce or
        apply
        our Terms of Service or other agreements that we have with you; or protect the rights, property, or safety
        of
        Multisynq, our employees, our users, or others.</p>

    <h3>Use of Cookies</h3>
    <p>We use cookies to make Multisynq easier for you to use, for example by remembering that you are logged in.
        Cookies are
        alphanumeric identifiers that we transfer to your computer‘s hard drive through your web browser to enable
        our
        systems to recognize your browser and tell us how and when pages in our site are visited and by how many
        people.
        Multisynq cookies do not collect personal information, and we do not combine information collected through
        cookies
        with other personal information to tell us who you are.</p>

    <p>The “help” portion of the toolbar on the majority of browsers will direct you on how to prevent your browser
        from
        accepting new cookies, how to command the browser to tell you when you receive a new cookie, or how to fully
        disable
        cookies. You can set your browser to refuse all cookies, but some Multisynq features and services may not
        function
        properly if your cookies are disabled. We recommend that you leave the cookies enable, however, to improve
        your
        experience with the Site.</p>

    <h3>Information Security</h3>
    <p>We take reasonable technical and administrative precautions to protect your data, but we cannot guarantee its
        safety
        against all types of fraud or misuse. If you provide personal information, we cannot verify its total
        security
        against all types of interception.</p>

    <h3>Do-Not-Track</h3>
    <p>Some browsers offer Do-Not-Track settings to prevent any information from being distributed. Since these
        settings
        have not been legally established as standard practice, we do not acknowledge these settings.</p>

    <h3>Your rights to your Information</h3>
    <p>At any time, you may opt to review or change your account settings, including contact information. If you
        wish to
        delete your account, you may do so to remove most of your information, however, some identifying
        information will be
        retained to prevent fraud. In addition, you have the right to request a copy of any personal data held
        by Multisynq
        and you have the right to request any corrections to such data.</p>

    




</body>

</html>`;

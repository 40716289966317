import {
  Demolition,
  Fountain,
  Gallery,
  Guardians,
  MRI,
  Multiblaster,
  Mythos,
  Piix,
  Foothold,
  Quub,
  Threeview,
  WideWideWorld,
  ScrollSync,
  RobotDeathmatch,
  Ploma,
} from "../../../images/examples-page";
import { Framework } from "./FrameworkData";

export interface IExample {
  title: string;
  image: string;
  exampleUrl: string;
  externalURL?: string;
  noSplit?: boolean;
  docsLink: string;
  info: string;
  framework: string;
  frameworkLogo?: string;
  exampleWindowOverrideComponent?: React.ComponentType<any>;
  hasFullScreen?: boolean;
}

export const examples: IExample[] = [
  {
    title: "MultiBlaster",
    image: Multiblaster,
    exampleUrl: "https://apps.multisynq.io/multiblaster/",
    docsLink: "https://github.com/croquet/multiblaster/blob/main/index.html",
    info: "This is an homage to Asteroids, but multiplayer. Control your ship using WASD and hit Space to shoot, or use the on-screen joystick and a second finger/button to shoot. It is implemented as a single HTML page, containing about 250 lines of JS for the multiplayer game logic, and 150 lines for rendering. The game runs at 20 Hz, but the rendering happens at a smooth 60 or more fps (see View Smoothing).",
    framework: Framework.CROQUET_JS_WEB_API,
  },
  {
    title: "Wide Wide World",
    image: WideWideWorld,
    exampleUrl: "https://apps.multisynq.io/w3/",
    docsLink:
      "https://github.com/croquet/worldcore/tree/w3-on-croquet-2.0/examples/w3",
    info: `Wide Wide World is a multiplayer village-building simulation written in Croquet using the Worldcore engine.
    \nIf you want other people to join your world click on the Share button. The world is persistent. If you leave and come back later to the same URL in the Copy Link option, everything will be as you left it
    \nSelect a tool in the upper left, then click on the terrain to dig, fill, plant trees, or add people or water. Press “F” for full screen.
    \nEverything you do is instantly shared with the other players. The game stays perfectly in synch, even with hundreds of AIs and a real-time water simulation. Use the slider on the right to view an underground cutaway of the terrain. The simulation is a fully 3D voxel world!
    \nYou can also toggle first-person mode by clicking the walk icon at the upper right. This will switch the camera to follow a random AI. The AIs wander randomly now, but in the future you’ll be able to order them to cut down trees, plant crops, mine stone, build buildings, and fight off wild animals .Check back to see what improvements we’ve made!`,
    framework: Framework.WORLDCORE,
  },
  {
    title: "MRI",
    image: MRI,
    exampleUrl: "https://apps.multisynq.io/mri/",
    docsLink: "https://github.com/croquet/brain",
    info: "This demo shows client-side generation of images from a raw MRI dataset designed to be used in telemedicine, where physicians increasingly communicate with patients remotely.  In viewing mode, adjust the slice index or drag the image to rotate the head.  This will cause each client to update its view bit identically and simultaneously. Each client can separately choose whether to view the skull or just the brain. Click draw to enter drawing mode (double-click to lock the mode), where you can independently choose a slice, then draw to annotate that slice for everyone.",
    framework: Framework.CROQUET_JS_WEB_API,
  },
  {
    title: "Piix",
    image: Piix,
    exampleUrl: "https://apps.multisynq.io/piix/",
    docsLink: "https://github.com/croquet/piix",
    info: "PiiX is a lightweight cross platform image sharing web app that enables multiple users to simultaneously draw, add and remove comments and leave markup on various image formats. Use it to collaboratively mark up plans with your architect or take a photo of a wall of salad dressings at the market and share it to ask which one you are supposed to buy. Works integrated with the camera on your phone.",
    framework: Framework.VIRTUAL_DOM,
  },
  {
    title: "Ploma",
    image: Ploma,
    exampleUrl: "https://apps.multisynq.io/ploma/",
    docsLink: "https://github.com/yoshikiohshima/ploma",
    info: "Ploma is a high-fidelity ballpoint pen rendering for pressure sensitive tablets. It allows multiple participants to draw simultaneously. Its interpolation method produces realistic lines drawn by ballpoint pen.",
    framework: Framework.VIRTUAL_DOM,
  },
  {
    title: "Robot Deathmatch",
    image: RobotDeathmatch,
    // Note: The digital ocean URL is a versioned link (that can change) with no branding.
    // The rogueengine url has branding but will not change.
    //exampleUrl: "https://rogue-hosting.nyc3.cdn.digitaloceanspaces.com/66c4bca341ca339add1e5d93/index.html",
    exampleUrl: "https://play.rogueengine.io/RogueEngine/robot-deathmatch",
    docsLink: "",
    info: "Robot Deathmatch is a Rogue Engine multiplayer game. Rogue Engine is an advanced and versatile game development platform which features Multisynq Network fully integrated to empower developers with robust multiplayer tools and a user-friendly interface. It supports both 2D and 3D game development, offering a wide range of features such as physics simulation, real-time rendering, and asset management. With its modular architecture, Rogue Engine allows for extensive customization and scalability, making it suitable for indie developers and large studios alike.",
    framework: Framework.ROGUE_ENGINE,
  },
  // {
  //   title: "Fountain",
  //   image: Fountain,
  //   exampleUrl: "",
  //   docsLink: "https://github.com/croquet/worldcore/tree/main/demos/fountain",
  //   info: "Fountain is a multiuser physics demo written using Croquet. Hundreds of blocks tumble and fall identically without a central server. Multiple users can shoot new blocks into the spray and their actions are perfectly mirrored everywhere as well.",
  //   framework: Framework.WORLDCORE,
  // },
  {
    title: "Guardians",
    image: Guardians,
    exampleUrl: "https://apps.multisynq.io/guardians/",
    docsLink: "https://github.com/croquet/croquet-for-web-guardians",
    info: "Guardians is an instantly-joinable multiplayer game where you and your friends are defending your central spaceship from increasingly large waves of evil bots. If the bots reach your ship, they detonate - use your tank's rebound rounds to eliminate the threat!",
    framework: Framework.WORLDCORE,
  },
  {
    title: "Demolition",
    image: Demolition,
    exampleUrl: "https://apps.multisynq.io/demolition-unity/",
    docsLink: "https://github.com/croquet/croquet-for-unity-demolition",
    info: "Demolish towers of blocks by firing balls! Can you find the TNT in each level to set off a chain reaction? Unleash chaos - then compare your screens to see that every block is always perfectly synced!",
    framework: Framework.UNITY,
  },
  {
    title: "Foothold",
    image: Foothold,
    noSplit: true,
    exampleUrl: "https://apps.multisynq.io/foothold/",
    docsLink: "",
    info: "",
    framework: Framework.UNITY,
  },
  {
    title: "ScrollSync",
    image: ScrollSync,
    exampleUrl: "https://multisynq.io/scroll",
    docsLink: "https://github.com/yoshikiohshima/page-synchronizer",
    info: "Allows users to synchronise page scrolling, allowing mutiple users to share the same browsing experience",
    framework: Framework.CROQUET_JS_WEB_API,
    hasFullScreen: true,
  },
  {
    title: "Threeview",
    image: Threeview,
    exampleUrl: "https://apps.multisynq.io/threeview/?default=true",
    docsLink: "https://github.com/croquet/threeview",
    info: "Shared upload and rendering of 3D model files. When any participant drops a model into the scene, all participants see the model and can drag and zoom to control the shared point of view. Dropping a new model replaces the current one. Model types are supported on a best-effort basis using some of THREE.js’s standard loaders: OBJ files (optionally with associated materials and textures, gathered in a directory or .zip archive); GLB (optionally with animation, which will also be synced across participants); FBX (also animatable).",
    framework: Framework.CROQUET_JS_WEB_API,
  },
  // {
  //   title: "Microverse: Gallery",
  //   image: Gallery,
  //   exampleUrl: "https://apps.multisynq.io/gallery/",
  //   docsLink: "https://github.com/croquet/gallery/",
  //   info: "Microverse Gallery is an immersive, virtual meeting space and capabilities showcase personal or business use. It is built on the Multisynq Network and the Worldcore framework. This Gallery world includes a synchronized PDF reader, video player, live updating Bitcoin data chart and a simple Multisynq app.",
  //   framework: Framework.MICROVERSE,
  // },
  // {
  //   title: "Microverse: Mythos",
  //   image: Mythos,
  //   exampleUrl: "https://apps.multisynq.io/mythos/",
  //   docsLink: "https://github.com/croquet/mythos",
  //   info: "Mythos is a procedural, grassy outdoor world built using Microverse, a framework for building perfectly synchronized multiplayer immersive 3D virtual worlds on the web. You may release thousands of AIs (use the menu) who are trained to be afraid of humans and who are perfectly synchronized for all participants with almost no bandwidth. It is built on Multisynq and the Worldcore framework. Invite someone to join you there using the menu.",
  //   framework: Framework.MICROVERSE,
  // },
  // {
  //   title: "Quub",
  //   image: Quub,
  //   exampleUrl: "",
  //   docsLink: "https://github.com/croquet/worldcore/tree/main/demos/quub",
  //   info: "Quub is a multiuser building toy written using Croquet. Dozens of users can simultaneously place Lego-like blocks onto a shared base plate. Every user sees every block as it's placed even though there is no central server coordinating their inputs.",
  //   framework: Framework.WORLDCORE,
  // },
];

// ensure that we use the dev examples when running on the dev domain
// update to check external URL as well
// note: wont work on localhost!!
if (window.location.hostname.endsWith("multisynq.dev")) {
  examples.forEach((example) => {
    example.exampleUrl = example.exampleUrl?.replace(
      "multisynq.io",
      "multisynq.dev"
    );
    example.externalURL = example.externalURL?.replace(
      "multisynq.io",
      "multisynq.dev"
    );
  });
}

import { useEffect } from "react";
import { useAppDispatch } from "../../app/store";
import { setShowPageWrapper } from "../../features/ui/publicUiStateSlice";
import { PageProps } from "../page-manager/PageManager";
import { CustomButton } from "../ui-elements/CustomButton";

import { useState } from "react";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import logo_long_blue from "../../images/logo-long-blue.svg";
import { Box, Typography } from "@mui/material";

function SynchronizerLoginPage({ showPageWrapper }: PageProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  const [isAuthenticated, set_isAuthenticated] = useState(false);
  const [openLink, set_openLink] = useState<HTMLAnchorElement | null>(null);

  const handleSuccess = (response: CredentialResponse) => {
    const token = response.credential;
    if (!Object.prototype.hasOwnProperty.call(response, "error")) {
      const link = document.createElement("a");
      link.href = `multisynq://googleLogin?token=${token}`;
      document.body.appendChild(link);
      link.click();
      set_openLink(link);
      set_isAuthenticated(true);
    }
  };

  const handleError = () => {
    console.log("Error!");
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      margin={"24px"}
      textAlign={"center"}
    >
      <img src={logo_long_blue} style={{ maxWidth: "100%" }} />
      {isAuthenticated ? (
        <>
          <Typography variant="h3">You can now close this tab</Typography>
          <Typography paragraph={true}>
            If the Synchronizer App does not open automatically, click the
            button below
          </Typography>
          <CustomButton onClick={() => openLink?.click()}>
            Open the Synchronizer App
          </CustomButton>
        </>
      ) : (
        <>
          <Typography variant="h3" gutterBottom={true}>Logging you into the Synchronizer App...</Typography>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            useOneTap={true}
            cancel_on_tap_outside={false}
          />
        </>
      )}
    </Box>
  );
}

export default SynchronizerLoginPage;

import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  darken,
  lighten,
  Button,
  useTheme,
  Icon,
} from "@mui/material";
import footholdTitle from "../../../../images/examples-page/foothold-title.png";
import footholdBackground from "../../../../images/examples-page/foothold-background.jpg";
import { IExample } from "../ExampleData";
import { Phantom } from "../../../../icons";

const FootholdExample = ({
  example,
}: {
  example: IExample;
}): React.ReactElement => {
  const theme = useTheme();
  const baseColor = "#FFC36A";
  const starItemColor = "#FFC36A";
  const starItemTextColor = "#701701";
  const phantomColor = "#AB9FF2";

  const StarItem = ({ content }: { content: React.ReactNode }) => (
    <ListItem
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: 4,
        mb: 3,
        py: 5,
        px: 3,
        backgroundColor: starItemColor,
        color: starItemTextColor,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: starItemTextColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 2,
            flexShrink: 0,
            fill: phantomColor,
            padding: "4px",
          }}
        >
          <Icon
            component={Phantom}
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        </Box>
        <Typography
          variant="h6"
          component="h3" // Use h3 which is a block-level element
          sx={{ fontWeight: 900, color: starItemTextColor }}
        >
          Connect Your Wallet (Optional)
        </Typography>
      </Box>
      <Box sx={{ flex: 1, color: starItemTextColor }}>
        <Typography
          variant="body2"
          component="div" // Change to "div" to avoid nested block-level issues
          sx={{
            ml: 7,
            fontWeight: 400,
          }}
        >
          {content}
        </Typography>
      </Box>
    </ListItem>
  );

  const BulletItem = ({
    title,
    content,
    number,
  }: {
    title: string;
    content: React.ReactNode;
    number: number;
  }) => (
    <ListItem
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        borderRadius: 4,
        mb: 2,
        py: 3,
        px: 3,
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: baseColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 2,
          flexShrink: 0,
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{ fontWeight: 900, color: theme.palette.colors.grayScale.black }}
        >
          {number}
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h6"
          component="h3"
          sx={{ fontWeight: 900, color: baseColor, mb: 1 }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: lighten(baseColor, 0.1) }}>
          {content}
        </Typography>
      </Box>
    </ListItem>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        backgroundImage: `url(${footholdBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
    >
      <img
        src={footholdTitle}
        alt="Foothold Title"
        style={{ width: "auto", height: "125px" }}
      />

      <Typography
        variant="h4"
        component="h4"
        textAlign={"center"}
        color={baseColor}
        sx={{
          fontWeight: 900,
          mt: 1,
          mb: 3,
        }}
      >
        Experience Web3 Gaming with Foothold by Multisynq
      </Typography>
      <List sx={{ maxWidth: "800px", width: "100%", px: 2 }}>
        <StarItem
          content={
            <>
              Want to save your High Score? Connect a Phantom wallet to do so.
              Don't have one? No worries -just install the{" "}
              <Button
                href="https://phantom.app/"
                target="_blank"
                rel="noopener"
                sx={{
                  color: phantomColor,
                  position: "relative",
                  overflow: "hidden",
                  background: starItemTextColor,
                  p: "0px",
                  pr: "8px",
                  pb: 0.25,
                  pt: 0.25,
                  minWidth: "auto",
                  fontSize: "0.9rem",
                  fontWeight: 900,
                  height: "24px",
                  transition: "background 0.3s ease", // Add transition for smooth effect
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme.palette.colors.purple[500],
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                  },
                  "&:hover": {
                    background: starItemTextColor, // Keep the original background
                    "&::before": {
                      opacity: 1,
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0,
                    fill: phantomColor,
                    padding: "4px",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <Icon
                    component={Phantom}
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </Box>
                <span style={{ position: "relative", zIndex: 1 }}>Phantom</span>
              </Button>{" "}
              browser extension and create a wallet when you're ready.
            </>
          }
        />
        <BulletItem
          number={1}
          title="Join or Start a Game"
          content="Visit this page and immerse yourself in synchronized multiplayer action with hundreds of interactable AIs."
        />
        <BulletItem
          number={2}
          title="Defend Your Base"
          content="Engage in a thrilling battle to protect your rocketship."
        />
        <BulletItem
          number={3}
          title="Submit Your High Score"
          content="Compete to top the leaderboard!"
        />
      </List>
      <Button
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          minWidth: "160px",
          fontSize: "1.5rem",
          color: theme.palette.colors.grayScale.black,
          backgroundColor: baseColor,
          borderRadius: 2,
          "&:hover": { backgroundColor: darken(baseColor, 0.1) },
        }}
        onClick={() => {
          window.open(`${example.exampleUrl}`, "_blank");
        }}
      >
        Play
      </Button>
      {/* <Box
        sx={{
          marginTop: 5,
          padding: 5,
          width: "80%",
          backgroundColor: theme.palette.colors.grayScale.black,
          borderRadius: 8,
        }}
      >
        <Typography
          variant="h6"
          textAlign="left"
          color={baseColor}
          sx={{ mb: 0, fontWeight: "bold" }}
        >
          Why Foothold
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="• Unmatched Synchronization"
              secondary="Enjoy lag-free, perfectly synced gameplay."
              primaryTypographyProps={{ color: baseColor, fontWeight: 900 }}
              secondaryTypographyProps={{ color: baseColor, fontWeight: 400 }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="• Simplified Development"
              secondary="No need for complex netcode or backend—everything runs client-side."
              primaryTypographyProps={{ color: baseColor, fontWeight: 900 }}
              secondaryTypographyProps={{ color: baseColor, fontWeight: 400 }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="• Web3 Power"
              secondary="Decentralized, secure, and scalable gaming at its best."
              primaryTypographyProps={{ color: baseColor, fontWeight: 900 }}
              secondaryTypographyProps={{ color: baseColor, fontWeight: 400 }}
            />
          </ListItem>
        </List>
      </Box> */}
    </Box>
  );
};

export default FootholdExample;

import { styled } from "@mui/material/styles";
import { ReactComponent as DownIcon } from "./down-icon.svg";
import { ReactComponent as UpIcon } from "./up-icon.svg";
import { ReactComponent as ScrollToggleIcon } from "./scroll-toggle-icon.svg";
import { ReactComponent as LeftIcon } from "./left-icon.svg";
import { ReactComponent as RightIcon } from "./right-icon.svg";
import { ReactComponent as ArrowRightIcon } from "./right-arrow-icon.svg";
import { ReactComponent as CloseIcon } from "./close-icon.svg";
import { ReactComponent as DevIcon } from "./dev-icon.svg";
import { ReactComponent as DocIcon } from "./doc-icon.svg";
import { ReactComponent as LoginIcon } from "./login-icon.svg";
import { ReactComponent as LogoIcon } from "./logo-icon.svg";
import { ReactComponent as WebIcon } from "./web-icon.svg";
import { ReactComponent as UserIcon } from "./user-icon.svg";
import { ReactComponent as UnityIcon } from "./unity-icon.svg";
import { ReactComponent as CroquetIcon } from "./croquet-icon.svg";
import { ReactComponent as MicroverseIcon } from "./microverse-icon.svg";
import { ReactComponent as WorldCoreIcon } from "./worldcore-icon.svg";
import { ReactComponent as QrIcon } from "./qr-icon.svg";
import { ReactComponent as InfoIcon } from "./info-icon.svg";
import { ReactComponent as SplitScreenIcon } from "./split-screen-icon.svg";
import { ReactComponent as CloseDrawerIcon } from "./close-drawer-icon.svg";
import { ReactComponent as OpenDrawerIcon } from "./open-drawer-icon.svg";
import { ReactComponent as LeftArrowIcon } from "./left-arrow-icon.svg";
import { ReactComponent as FilterIcon } from "./filter-icon.svg";
import { ReactComponent as ShowAllIcon } from "./show-all-icon.svg";
import { ReactComponent as TeamIcon } from "./team-icon.svg";
import { ReactComponent as XIcon } from "./x-icon.svg";
import { ReactComponent as LinkedInIcon } from "./linked-in-icon.svg";
import { ReactComponent as PartnersIcon } from "./partners-icon.svg";
import { ReactComponent as PhantomIcon } from "./phantom-icon.svg";
import { ReactComponent as ExpandIcon } from "./expand-icon.svg";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import { ReactComponent as DeleteIcon } from "./delete-icon.svg";
import { ReactComponent as EditIcon } from "./edit-icon.svg";
import { ReactComponent as SortIcon } from "./sort-icon.svg";
import { ReactComponent as AddIcon } from "./add-icon.svg";
import { ReactComponent as TelegramIcon } from "./telegram-icon.svg";
import { ReactComponent as DiscordIcon } from "./discord-icon.svg";

const StyledDownIcon = styled(DownIcon)({});
const StyledUpIcon = styled(UpIcon)({});
const StyledScrollToggleIcon = styled(ScrollToggleIcon)({});
const StyledLeftIcon = styled(LeftIcon)({});
const StyledRightIcon = styled(RightIcon)({});
const StyledArrowRightIcon = styled(ArrowRightIcon)({});
const StyledCloseIcon = styled(CloseIcon)({});
const StyledDevIcon = styled(DevIcon)({});
const StyledDocIcon = styled(DocIcon)({});
const StyledLoginIcon = styled(LoginIcon)({});
const StyledLogoIcon = styled(LogoIcon)({});
const StyledWebIcon = styled(WebIcon)({});
const StyledUserIcon = styled(UserIcon)({});
const StyledUnityIcon = styled(UnityIcon)({});
const StyledCroquetIcon = styled(CroquetIcon)({});
const StyledMicroverseIcon = styled(MicroverseIcon)({});
const StyledWorldCoreIcon = styled(WorldCoreIcon)({});
const StyledQrIcon = styled(QrIcon)({});
const StyledInfoIcon = styled(InfoIcon)({});
const StyledSplitScreenIcon = styled(SplitScreenIcon)({});
const StyledCloseDrawerIcon = styled(CloseDrawerIcon)({});
const StyledOpenDrawerIcon = styled(OpenDrawerIcon)({});
const StyledLeftArrowIcon = styled(LeftArrowIcon)({});
const StyledFilterIcon = styled(FilterIcon)({});
const StyledShowAllIcon = styled(ShowAllIcon)({});
const StyledTeamIcon = styled(TeamIcon)({});
const StyledXIcon = styled(XIcon)({});
const StyledLinkedInIcon = styled(LinkedInIcon)({});
const StyledPartnersIcon = styled(PartnersIcon)({});
const StyledPhantomIcon = styled(PhantomIcon)({});
const StyledExpandIcon = styled(ExpandIcon)({});
const StyledDeleteIcon = styled(DeleteIcon)({});
const StyledEditIcon = styled(EditIcon)({});
const StyledCopyIcon = styled(CopyIcon)({});
const StyledSortIcon = styled(SortIcon)({});
const StyledAddIcon = styled(AddIcon)({});
const StyledTelegramIcon = styled(TelegramIcon)({});
const StyledDiscordIcon = styled(DiscordIcon)({});

interface IconProps {
  sx?: object;
}

function Down(props: IconProps) {
  return <StyledDownIcon sx={props.sx} />;
}

function Up(props: IconProps) {
  return <StyledUpIcon sx={props.sx} />;
}

function ScrollToggle(props: IconProps) {
  return <StyledScrollToggleIcon sx={props.sx} />;
}

function Left(props: IconProps) {
  return <StyledLeftIcon sx={props.sx} />;
}

function Right(props: IconProps) {
  return <StyledRightIcon sx={props.sx} />;
}

function ArrowRight(props: IconProps) {
  return <StyledArrowRightIcon sx={props.sx} />;
}

function Close(props: IconProps) {
  return <StyledCloseIcon sx={props.sx} />;
}

function Dev(props: IconProps) {
  return <StyledDevIcon sx={props.sx} />;
}

function Doc(props: IconProps) {
  return <StyledDocIcon sx={props.sx} />;
}

function Login(props: IconProps) {
  return <StyledLoginIcon sx={props.sx} />;
}

function Logo(props: IconProps) {
  return <StyledLogoIcon sx={props.sx} />;
}

function Web(props: IconProps) {
  return <StyledWebIcon sx={props.sx} />;
}

function User(props: IconProps) {
  return <StyledUserIcon sx={props.sx} />;
}

function Unity(props: IconProps) {
  return <StyledUnityIcon sx={props.sx} />;
}

function Croquet(props: IconProps) {
  return <StyledCroquetIcon sx={props.sx} />;
}

function Microverse(props: IconProps) {
  return <StyledMicroverseIcon sx={props.sx} />;
}

function WorldCore(props: IconProps) {
  return <StyledWorldCoreIcon sx={props.sx} />;
}

function Qr(props: IconProps) {
  return <StyledQrIcon sx={props.sx} />;
}

function Info(props: IconProps) {
  return <StyledInfoIcon sx={props.sx} />;
}

function SplitScreen(props: IconProps) {
  return <StyledSplitScreenIcon sx={props.sx} />;
}

function CloseDrawer(props: IconProps) {
  return <StyledCloseDrawerIcon sx={props.sx} />;
}

function OpenDrawer(props: IconProps) {
  return <StyledOpenDrawerIcon sx={props.sx} />;
}

function ArrowLeft(props: IconProps) {
  return <StyledLeftArrowIcon sx={props.sx} />;
}

function Filter(props: IconProps) {
  return <StyledFilterIcon sx={props.sx} />;
}

function ShowAll(props: IconProps) {
  return <StyledShowAllIcon sx={props.sx} />;
}

function Team(props: IconProps) {
  return <StyledTeamIcon sx={props.sx} />;
}

function X(props: IconProps) {
  return <StyledXIcon sx={props.sx} />;
}

function LinkedIn(props: IconProps) {
  return <StyledLinkedInIcon sx={props.sx} />;
}

function Partners(props: IconProps) {
  return <StyledPartnersIcon sx={props.sx} />;
}

function Phantom(props: IconProps) {
  return <StyledPhantomIcon sx={props.sx} />;
}

function Expand(props: IconProps) {
  return <StyledExpandIcon sx={props.sx} />;
}

function Delete(props: IconProps) {
  return <StyledDeleteIcon sx={props.sx} />;
}

function Edit(props: IconProps) {
  return <StyledEditIcon sx={props.sx} />;
}

function Copy(props: IconProps) {
  return <StyledCopyIcon sx={props.sx} />;
}

function Sort(props: IconProps) {
  return <StyledSortIcon sx={props.sx} />;
}

function Add(props: IconProps) {
  return <StyledAddIcon sx={props.sx} />;
}

function Telegram(props: IconProps) {
  return <StyledTelegramIcon sx={props.sx} />;
}

function Discord(props: IconProps) {
  return <StyledDiscordIcon sx={props.sx} />;
}

export {
  Down,
  Up,
  ScrollToggle,
  Left,
  Right,
  ArrowRight,
  ArrowLeft,
  Close,
  Dev,
  Doc,
  Login,
  Logo,
  Web,
  User,
  Unity,
  Croquet,
  Microverse,
  WorldCore,
  Qr,
  Info,
  SplitScreen,
  CloseDrawer,
  OpenDrawer,
  Filter,
  ShowAll,
  Team,
  X,
  LinkedIn,
  Partners,
  Phantom,
  Expand,
  Delete,
  Edit,
  Copy,
  Sort,
  Add,
  Telegram,
  Discord,
};

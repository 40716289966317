import { Typography, useTheme, Box, Link } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../app/store";
import FilterDropdown from "./action-bar/filter/FilterDropdown";
import { generateFrameworks } from "../FrameworkData";

export const ExamplesThumbnailHeader = () => {
  const theme = useTheme();
  const { selectedExample } = useAppSelector((state) => state.pageManager);
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [truncatedText, setTruncatedText] = useState("");
  const textRef = useRef(null);

  const lineHeight = 1.5;
  const maxLines = 3;
  const lineHeightInPx = 14 * lineHeight; // 14px is the font size
  const minHeight = `${maxLines * lineHeightInPx}px`;

  useEffect(() => {
    setExpanded(false);
  }, [selectedExample]);

  useEffect(() => {
    if (textRef.current && selectedExample) {
      const fullText = selectedExample.info;
      let truncated = fullText;
      setTruncatedText(fullText);

      const handleResize = () => {
        const element = textRef.current;
        element.innerText = fullText;

        // Check if text overflows
        const isOverflowing = element.scrollHeight > element.clientHeight;
        setIsOverflowing(isOverflowing);

        if (isOverflowing) {
          while (
            element.scrollHeight > element.clientHeight &&
            truncated.length > 0
          ) {
            truncated = truncated.slice(0, -1);
            element.innerText = truncated + "...  show more";
          }

          setTruncatedText(truncated + "...");
        } else {
          setTruncatedText(fullText);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [selectedExample, expanded]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const frameworks = generateFrameworks({ theme });

  return (
    <Box
      // row
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      width={"100%"}
    >
      <Typography
        variant="h4"
        color={theme.palette.colors.blue[900]}
        textAlign={"left"}
        marginRight={"12px"}
      >
        Examples
      </Typography>
      <FilterDropdown frameworks={frameworks} />
    </Box>
  );
};

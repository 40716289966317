import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../../../../../../app/store";
import { KeyFilterService } from "../../../../../../../services/KeyFilterService";

interface DataDisplayProps {
  title: string;
  dataString: string;
  featured?: boolean;
}

export const DataDisplay: React.FC<DataDisplayProps> = ({
  title,
  dataString,
  featured = false,
}) => {
  const filterString = useAppSelector((state) => state.apiKey.filterString);
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: featured ? "0.9rem" : "0.8rem",
        opacity: featured ? 0.7 : 0.6,
        "& .highlight": {
          backgroundColor: "yellow",
          opacity: 1,
        },
      }}
    >
      <Box component="span" fontWeight={featured ? 700 : 600}>
        {title}:
      </Box>{" "}
      <Box component="span" fontWeight="300">
        {KeyFilterService.highlightText(dataString, filterString, theme)}
      </Box>
    </Typography>
  );
};

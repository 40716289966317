import { Grid, useTheme } from "@mui/material";
import ProfileImage from "./profile-header/ProfileImage";
import { TeamMenuTab } from "../TeamPage";

const MenuGrid = ({
  activeTab,
  team,
  advisors,
  setActiveTeamMember,
  setActiveAdvisor,
  activeTeamMember,
  activeAdvisor,
}) => {
  const theme = useTheme();
  const providedSize = 150;

  return (
    <Grid
      container
      spacing={4}
      width={"100%"}
      maxWidth={"700px"}
      justifyContent={"center"}
      sx={{ marginTop: "20px", marginBottom: "60px" }}
    >
      {activeTab === TeamMenuTab.TEAM &&
        team.map((member, index) => (
          <Grid
            item
            key={index}
            onClick={() => setActiveTeamMember(index)}
            sx={{
              cursor: "pointer",
            }}
          >
            <ProfileImage
              src={member.image}
              size={providedSize}
              theme={theme}
              showSelected={activeTeamMember === index}
              isFeatured={false}
              firstName={team[index].firstName}
            />
          </Grid>
        ))}
      {activeTab === TeamMenuTab.ADVISORS &&
        advisors.map((advisor, index) => (
          <Grid
            item
            key={index}
            onClick={() => setActiveAdvisor(index)}
            sx={{
              cursor: "pointer",
            }}
          >
            <ProfileImage
              src={advisor.image}
              size={providedSize}
              theme={theme}
              showSelected={activeAdvisor === index}
              isFeatured={false}
              firstName={advisors[index].firstName}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default MenuGrid;

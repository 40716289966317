import React, { useState, useEffect } from "react";

interface ImageLoaderProps {
  src: string;
  placeholder: string;
  alt: string;
  heightOverride?: number | null;
  widthOverride?: number | null;
}

function ImageLoader({
  src,
  placeholder,
  alt,
  heightOverride = null,
  widthOverride = null,
}: ImageLoaderProps) {
  const [imageSrc, setImageSrc] = useState(placeholder);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log("height, width: ", heightOverride, widthOverride);
  }, [heightOverride, widthOverride]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
      setLoading(false);
    };
  }, [src]);

  return (
    <div
      style={{
        width: widthOverride ?? "400px",
        height: heightOverride ?? "400px",
      }}
    >
      {loading && (
        <img
          src={placeholder}
          alt={alt}
          style={{
            width: widthOverride ?? "100%",
            height: heightOverride ?? "400px",
          }}
        />
      )}
      <img
        src={imageSrc}
        alt={alt}
        style={{
          width: widthOverride ?? "100%",
          height: heightOverride ?? "400px",
          display: loading ? "none" : "block",
        }}
      />
    </div>
  );
}

export default ImageLoader;

export class UserDataFormatterService {
  static formattedNumber(numberToFormat) {
    if (numberToFormat >= 1000000000) {
      return (numberToFormat / 1000000000).toLocaleString() + "b";
    }
    if (numberToFormat >= 1000000) {
      return (numberToFormat / 1000000).toLocaleString() + "m";
    }

    if (numberToFormat >= 1000) {
      return (numberToFormat / 1000).toLocaleString() + "k";
    }
    return numberToFormat.toLocaleString();
  }

  static formattedSince(createdAt) {
    const dateNow = new Date();
    const dateThen = new Date(createdAt);
    const diff = Math.abs(dateNow.getTime() - dateThen.getTime());
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 1) {
      return days + "d";
    }
    if (hours > 1) {
      return hours + "h";
    }
    if (minutes > 1) {
      return minutes + "m";
    }
    return seconds + "s";
  }
}

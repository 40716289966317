// create react component
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  loginGoogleUser,
  login,
  loadUser,
  requestReactivation,
  setReactivationEmailSent,
} from "../../../../features/users/userAuthSlice";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import {
  IAuthFormData,
  IGoogleAuthFormData,
  IGoogleResponse,
} from "../../../../types/user/userTypes";
import { CustomInput } from "../../../ui-elements/CustomInput";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { AuthFormWrapper } from "./blocks/AuthFormWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { setSignInMessage } from "../../../../features/ui/publicUiStateSlice";

interface LoginViewProps {
  viewState: AuthViewState;
  setViewState: (state: AuthViewState) => void;
  setView: (view: AuthViews) => void;
}

const LoginView: React.FC<LoginViewProps> = ({
  viewState,
  setViewState,
  setView,
}) => {
  const publicUiSlice = useAppSelector((state) => state.publicUiState);
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);
  const userCredentials = userCredentialsSlice?.userCredentials;
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const reactivateToken = userAuthSlice?.reactivationToken;
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userDetails = userDetailsSlice?.userDetails;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const [showReactivate, setShowReactivate] = useState(false);
  const [showReactivateSent, setShowReactivateSent] = useState(false);

  const initialLoginFormData = {
    email: "",
    password: "",
  } as IAuthFormData;

  const [formData, setFormData] = useState(initialLoginFormData);

  useEffect(() => {
    dispatch(loadUser());
    updateForUserOnboardingState();
  }, []);

  useEffect(() => {
    updateForUserOnboardingState();
  }, [userCredentials?.verified, userDetails?.onboarded]);

  const updateForUserOnboardingState = async () => {
    const urlParams = new URLSearchParams(location.search);
    const referralCode = urlParams.get("referral");
    if (referralCode) {
      const userLoggedIn =
        userAuthSlice.userAuth?.token !== null &&
        userAuthSlice.userAuth?.token !== undefined;
      if (userLoggedIn) {
        setView(AuthViews.ReferralPreview);
        return;
      }
    }

    if (publicUiSlice.signInMessage !== "") {
      setCurrentErrorMessage(publicUiSlice.signInMessage);
      setTimeout(() => {
        dispatch(setSignInMessage(""));
      }, 1000);
    }

    const login = urlParams.get("login");
    if (login) {
      let sliceWasUpdated = false;
      if (publicUiSlice.activeEmail !== "") {
        setFormData({ ...formData, email: publicUiSlice.activeEmail });
        sliceWasUpdated = true;
      }

      if (!sliceWasUpdated) {
        navigate("/auth");
      }
      return;
    }

    if (userCredentials && userDetails) {
      if (userAuthSlice.reactivationEmailSent) {
        setView(AuthViews.ReactivationEmailSent);
      } else if (!userCredentials?.verified) {
        setView(AuthViews.VerificationPending);
      } else if (!userDetails.onboarded) {
        setView(AuthViews.UserDetails);
      } else if (userDetails.onboarded === true) {
        navigate("/account");
      }
    }
  };

  useEffect(() => {
    //console.log("newViewState", viewState);
  }, [viewState]);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (showReactivate) {
      handleRequestReactivationEmail();
      return;
    }

    if (e) e.preventDefault();
    setViewState(AuthViewState.Loading);
    callReportError(null);
    const response = await dispatch(login(formData));
    setViewState(AuthViewState.Default);
    if (response.type === "userAuth/login/rejected") {
      if (response.payload !== undefined && response.payload !== null) {
        if (response.payload === "Canceled") {
          setShowReactivate(true);
          callReportError(
            "Your account has been canceled. If you would like to reactivate your account, please click the Reactivate button below."
          );
          return;
        }
      }

      callReportError(response.payload);
    } else {
      const userPayload = response.payload.user;
      processSuccess(userPayload);
    }
  };

  const processSuccess = (userPayload) => {
    const details = userPayload.userDetails;
    const credentials = userPayload.userCredentials;
    if (details.onboarded && details.onboarded === true) {
      callReportError(null);
      // if path starts with /auth
      if (window.location.pathname.startsWith("/auth")) {
        navigate("/account");
      }
    } else {
      callReportError(null);
      if (!credentials?.verified) {
        return setView(AuthViews.VerificationPending);
      }

      setView(AuthViews.UserDetails);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () =>
    setShowPassword((previousShowPassword) => !previousShowPassword);

  const googleSuccess = async (response: IGoogleResponse) => {
    try {
      setViewState(AuthViewState.Loading);

      const googleAuthFormData = {
        token: response.credential,
      } as IGoogleAuthFormData;

      setViewState(AuthViewState.Loading);
      const result = await dispatch(loginGoogleUser(googleAuthFormData));
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setViewState(AuthViewState.Default);

      if (result.payload !== null && result.payload !== undefined) {
        if (result.payload.user !== null && result.payload.user !== undefined) {
          const userPayload = result.payload.user;
          processSuccess(userPayload);
          return;
        }
      }

      if (result.payload !== "") {
        if (result.payload !== undefined && result.payload !== null) {
          const resultPayload = result.payload as string;
          if (resultPayload.startsWith("Canceled-")) {
            const email = resultPayload.substring(9);
            console.log("email", email);
            setFormData({ ...formData, email: email });
            setShowReactivate(true);
            callReportError(
              "Your account has been canceled. If you would like to reactivate your account, please click the Reactivate button below."
            );
            return;
          }
        }

        callReportError(result.payload);
      }
    } catch (error) {
      console.log(error);
      callReportError("Google sign in was unsuccessful");
    }
  };

  const googleError = () => {
    callReportError("Google sign in was unsuccessful");
  };

  const handleSubmitPressed = () => {
    handleSubmit(null);
  };

  const handleRequestReactivationEmail = async () => {
    setViewState(AuthViewState.Loading);
    callReportError(null);

    const response = await dispatch(requestReactivation(formData.email));
    setView(AuthViews.ReactivationEmailSent);
    setViewState(AuthViewState.Default);
    if (response.type === "userAuth/requestReactivation/rejected") {
      callReportError(response.payload);
    } else {
      dispatch(setReactivationEmailSent(true));
      setView(AuthViews.ReactivationEmailSent);
    }
  };

  const viewWith = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AuthViewWrapper
      title={"Sign In"}
      viewState={viewState}
      currentErrorMessage={currentErrorMessage}
      clearErrorMessage={clearErrorMessage}
    >
      <AuthFormWrapper viewState={viewState} handleSubmit={handleSubmit}>
        {reactivateToken && (
          <Typography
            sx={{
              color: theme.palette.colors.grayScale.white,
              fontSize: "12px",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "600",
              padding: "8px",
              opacity: 0.8,
            }}
          >
            {"Please sign in to reactivate your account."}
          </Typography>
        )}
        {userAuthSlice.emailValidatedFlow && (
          <Typography
            variant="body2"
            color={theme.palette.colors.grayScale.white}
            sx={{ opacity: 0.8 }}
          >
            Your email has been verified. Please sign in.
          </Typography>
        )}
        <Box
          flexDirection={"column"}
          justifyContent={"center"}
          sx={{
            width: viewWith ? "100%" : "360px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <CustomInput
            name="email"
            label="Email Address"
            autoComplete={"email"}
            value={formData.email}
            onChange={handleChange}
            type="email"
            forceInvisible={viewState === AuthViewState.Loading}
            onSubmit={handleSubmitPressed}
            sx={{
              marginBottom: "8px",
              marginTop: "16px",
              width: `calc(100% + 8px)`,
            }}
          />
          <CustomInput
            name="password"
            label="Password"
            autoComplete={"current-password"}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            handleShowPassword={handleShowPassword}
            onSubmit={handleSubmitPressed}
            forceInvisible={viewState === AuthViewState.Loading}
            sx={{
              width: `calc(100% + 8px)`,
            }}
          />
        </Box>
        <Button
          sx={{
            marginTop: "8px",
            fontSize: "12px",
            textTransform: "none",
            color: alpha(theme.palette.colors.grayScale.white, 0.7),
          }}
          onClick={() => setView(AuthViews.RequestResetPassword)}
        >
          Forgot password?
        </Button>
        <Box
          display={"flex"}
          paddingTop={"16px"}
          flexDirection="column"
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          {!showReactivate && (
            <CustomButton
              onClick={() => handleSubmit}
              component={"button"}
              type="submit"
              disabled={viewState === AuthViewState.Loading}
              sx={{
                marginTop: "16px",
                marginBottom: "8px",
                width: "194px",
              }}
            >
              {viewState === AuthViewState.Loading ? (
                <CircularProgress size={24} />
              ) : (
                <></>
              )}
              {viewState === AuthViewState.Loading ? "" : "Sign In"}
            </CustomButton>
          )}
          {!showReactivate && (
            <Box>
              <GoogleLogin
                width="194px"
                theme="outline"
                size="large"
                onSuccess={
                  googleSuccess as (
                    credentialResponse: CredentialResponse
                  ) => void
                }
                onError={googleError}
                // cookiePolicy={"single_host_origin"}
              />
            </Box>
          )}
          {showReactivate && (
            <CustomButton
              onClick={() => handleRequestReactivationEmail()}
              component={"button"}
              type="submit"
              disabled={
                viewState === AuthViewState.Loading || showReactivateSent
              }
              sx={{
                marginTop: "16px",
                marginBottom: "8px",
              }}
            >
              {viewState === AuthViewState.Loading ? (
                <CircularProgress size={24} />
              ) : (
                <></>
              )}
              {showReactivateSent ? "Sent" : "Reactivate Account"}
            </CustomButton>
          )}

          <Button
            sx={{
              marginRight: "8px",
              marginLeft: "8px",
              fontWeight: "300",
              fontSize: { xs: "12px", md: "14px" },
              textTransform: "none",
              marginTop: "16px",
              color: alpha(theme.palette.colors.grayScale.white, 0.8),
              "&:hover": {
                color: theme.palette.colors.grayScale.white,
              },
            }}
            onClick={() => setView(AuthViews.Signup)}
            disableRipple
          >
            Don't have an account? &nbsp;
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: alpha(theme.palette.colors.grayScale.white, 0.7),
              }}
            >
              Sign Up
            </Typography>
          </Button>
        </Box>
      </AuthFormWrapper>
    </AuthViewWrapper>
  );
};

export default LoginView;

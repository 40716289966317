import { Box, IconButton, alpha } from "@mui/material";
import { ArrowRight, ArrowLeft } from "../../../../icons";

export const ExamplesPageController = ({
  currentPage,
  theme,
  totalPages,
  setCurrentPage,
}) => {
  const handleNextPage = () => {
    const targetPage = currentPage === totalPages ? 1 : currentPage + 1;
    setCurrentPage(targetPage);
  };

  const handlePrevPage = () => {
    const targetPage = currentPage === 1 ? totalPages : currentPage - 1;
    setCurrentPage(targetPage);
  };

  const IconButtonComponent = ({ onClick, isPrev }) => (
    <IconButton
      onClick={onClick}
      sx={{
        height: "32px",
        width: "32px",
        padding: "0",
        marginBottom: "1px",
        borderRadius: "8px",
        marginLeft: isPrev ? "0" : "8px",
        marginRight: isPrev ? "8px" : "0",
        color: theme.palette.colors.blue[500],
      }}
    >
      {isPrev ? (
        <ArrowLeft
          sx={{
            height: "24px",
            width: "24px",
            fill:
              currentPage === 1
                ? alpha(theme.palette.colors.grayScale.black, 0.3)
                : theme.palette.colors.blue[500],
          }}
        />
      ) : (
        <ArrowRight
          sx={{
            height: "24px",
            width: "24px",
            fill:
              currentPage === totalPages
                ? alpha(theme.palette.colors.grayScale.black, 0.3)
                : theme.palette.colors.blue[500],
          }}
        />
      )}
    </IconButton>
  );

  return (
    <Box
      display={{ xs: "none", sm: "flex" }}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"30px"}
    >
      <IconButtonComponent onClick={handlePrevPage} isPrev={true} />
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <Box
              key={page}
              width={"12px"}
              height={"12px"}
              borderRadius={"50%"}
              margin={"0 5px"}
              sx={{
                backgroundColor:
                  currentPage === page
                    ? theme.palette.colors.blue[500]
                    : alpha(theme.palette.colors.grayScale.black, 0.3),
                cursor: "pointer",
              }}
              onClick={() => setCurrentPage(page)}
            />
          )
        )}
      </Box>
      <IconButtonComponent onClick={handleNextPage} isPrev={false} />
    </Box>
  );
};

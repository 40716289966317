import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import CopiedPopup from "../blocks/CopiedPopup";
import { useAppSelector } from "../../../../app/store";
import ReferralHandBlock from "../blocks/ReferralHandBlock";

const AccountReferralSection = () => {
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [animating, setAnimating] = useState(false);
  const [animationState, setAnimationState] = useState("default"); // Added state

  const referralUrl = () => {
    const referralCode = userAuthSlice.userAuth?.user?.referralCode;
    const url = `${process.env.REACT_APP_FRONTEND_URL}/auth?referral=${referralCode}`;
    return url;
  };

  const handleGetReferralCode = async (event) => {
    navigator.clipboard.writeText(referralUrl());
    if (animating) {
      return;
    }
    setPosition({ x: event.clientX, y: event.clientY });
    setCopied(true);
    setAnimating(true);
    setTimeout(() => {
      setAnimating(false);
      setCopied(false);
    }, 3000);
  };

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingTop={"60px"}
      paddingBottom={"60px"}
      sx={{
        background: theme.palette.background.backdrop,
      }}
    >
      {isMediumScreen && (
        <Typography
          variant="h4"
          paddingBottom={"4px"}
          marginTop={{ xs: "0px", md: "44px" }}
          marginBottom={{ xs: "32px", md: "0px" }}
          fontWeight={900}
          color={theme.palette.text.primary}
          textAlign={"center"}
          paddingLeft={"8px"}
          paddingRight={"8px"}
        >
          Copy. Share. EARN!
        </Typography>
      )}
      <ReferralHandBlock
        handleGetReferralCode={handleGetReferralCode}
        animationState={animationState}
        setAnimationState={setAnimationState} // Pass setAnimationState
      />
      {!isMediumScreen && (
        <Typography
          variant="h4"
          paddingBottom={"4px"}
          marginTop={"44px"}
          fontWeight={900}
          color={theme.palette.text.primary}
        >
          Copy. Share. EARN!
        </Typography>
      )}

      <Button
        focusRipple={false}
        sx={{
          color: theme.palette.text.primary,
          opacity: 0.7,
          fontWeight: 600,
          fontSize: 14,
          marginTop: { xs: "24px", md: "0px" },
          marginRight: "16px",
          marginLeft: "16px",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
        }}
        onClick={handleGetReferralCode}
        onMouseEnter={() => setAnimationState("hovered")} // Added handler
        onMouseLeave={() => setAnimationState("default")} // Added handler
        onMouseDown={() => setAnimationState("pressed")} // Added handler
        onMouseUp={() => setAnimationState("hovered")} // Added handler
      >
        {referralUrl()}
      </Button>
      <CopiedPopup copied={copied} position={position} />
      <Typography
        variant="body2"
        fontSize={8}
        paddingBottom={"8px"}
        fontWeight={400}
        color={theme.palette.text.primary}
        paddingTop={"4px"}
        sx={{
          opacity: 0.8,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={handleGetReferralCode}
        onMouseEnter={() => setAnimationState("hovered")} // Added handler
        onMouseLeave={() => setAnimationState("default")} // Added handler
        onMouseDown={() => setAnimationState("pressed")} // Added handler
        onMouseUp={() => setAnimationState("hovered")} // Added handler
      >
        Click To Copy
      </Typography>
    </Box>
  );
};

export default AccountReferralSection;

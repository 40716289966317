import { AxiosError } from "axios";
import store from "./store";
import { setForceLogout } from "../features/users/userAuthSlice";
interface ErrorResponseData {
  message: string;
}

export const handleAxiosError = (error: unknown): string => {
  const e = error as AxiosError<ErrorResponseData>;

  if (e.response) {
    if (e.response.status === 498) {
      store.dispatch(setForceLogout(true));
      return "Invalid Token";
    }

    if (e.response.status === 429) {
      return "Too many requests";
    }

    return e.response.data.message;
  } else {
    // Network error or other unknown errors
    return "An unknown error occurred";
  }
};

interface ErrorResponseDataWithStatus {
  message: string;
  status: number;
}

export const handleAxiosErrorWithStatus = (
  error: unknown
): ErrorResponseDataWithStatus => {
  const e = error as AxiosError<ErrorResponseDataWithStatus>;
  const status = e.response?.status;
  let message = e.message;

  if (e.response) {
    if (e.response.status === 498) {
      store.dispatch(setForceLogout(true));
      message = "Invalid Token";
    }

    if (e.response.status === 429) {
      message = "Too many requests";
    }

    message = e.response.data.message;
  } else {
    // Network error or other unknown errors
    message = "An unknown error occurred";
  }

  return { message, status } as ErrorResponseDataWithStatus;
};

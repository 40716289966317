// ResetPasswordView.tsx
import React, { useState } from "react";
import { Button, Typography, alpha, useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { useAppDispatch } from "../../../../app/store";
import { resetPassword } from "../../../../features/users/userCredentialsSlice";
import { IResetPasswordPayload } from "../../../../types/user/userTypes";
import { CustomInput } from "../../../ui-elements/CustomInput";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { AuthFormWrapper } from "./blocks/AuthFormWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";

interface ResetPasswordViewProps {
  setView: (view: AuthViews) => void;
  token: string; // Assuming token is passed as a prop
  setToken: (token: string) => void;
}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = ({
  setView,
  token,
  setToken,
}) => {
  const theme = useTheme();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (newPassword !== confirmPassword) {
      callReportError("Passwords do not match.");
      return;
    }

    setLoading(true);
    callReportError(null);

    try {
      const resetPasswordPayload = {
        token,
        password: newPassword,
      } as IResetPasswordPayload;

      const result = await dispatch(resetPassword(resetPasswordPayload));
      setLoading(false);
      // Navigate to a success view or login view
      if (result.payload.success === true) {
        setCurrentErrorMessage(result.payload.message);
        setSuccess(true);
      } else {
        const errorMessage = result.payload.payload.message;
        callReportError(errorMessage);
      }
    } catch (error) {
      callReportError("Failed to reset password. Please try again.");
      setLoading(false);
    }

    setToken("");
    // clear inputs
    setNewPassword("");
    setConfirmPassword("");
  };

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmitPressed = () => {
    handleSubmit(null);
  };

  return (
    <AuthViewWrapper
      title="Reset Password"
      viewState={AuthViewState.Default}
      currentErrorMessage={currentErrorMessage}
      clearErrorMessage={clearErrorMessage}
      colorOverride={theme.palette.colors.grayScale.white}
    >
      <AuthFormWrapper
        handleSubmit={handleSubmit}
        viewState={AuthViewState.Default}
      >
        <Typography
          variant="body2"
          color={alpha(theme.palette.colors.grayScale.white, 0.7)}
          textAlign={"center"}
          lineHeight={1.6}
          sx={{ mb: 3 }}
        >
          {`Please enter your new password.`}
        </Typography>
        <CustomInput
          name="newPassword"
          label="New Password"
          onChange={handleNewPasswordChange}
          onSubmit={handleSubmitPressed}
          type="password"
          sx={{ marginBottom: "8px" }}
        />
        <CustomInput
          name="confirmPassword"
          label="Confirm Password"
          onChange={handleConfirmPasswordChange}
          onSubmit={handleSubmitPressed}
          type="password"
        />

        <CustomButton
          component={"button"}
          type="submit"
          onClick={() => handleSubmit}
          disabled={loading || success}
          sx={{
            marginTop: "20px",
          }}
        >
          {success ? "Success!" : "Reset Password"}
        </CustomButton>
        <Button
          sx={{
            marginTop: "8px",
            fontSize: "12px",
            textTransform: "none",
            color: alpha(theme.palette.colors.grayScale.white, 0.7),
          }}
          onClick={() => setView(AuthViews.Login)}
        >
          {`< Back to Login`}
        </Button>
      </AuthFormWrapper>
    </AuthViewWrapper>
  );
};

export default ResetPasswordView;

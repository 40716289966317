import { alpha, Box, Typography, useTheme } from "@mui/material";
import { ProfileCard } from "./profile-card/ProfileCard";
import { useAppSelector } from "../../../../app/store";

const PointsBlock = () => {
  const theme = useTheme();
  const userEngagementSlice = useAppSelector((state) => state.userEngagement);
  const currentPoints = userEngagementSlice.userEngagement?.points;
  const formattedPoints = currentPoints?.toLocaleString("en-US");

  return (
    <Box
      position={"relative"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      height={"100%"}
      paddingBottom={"60px"}
      marginTop={"30px"}
      sx={{
        background: theme.palette.background.backdrop,
        marginLeft: { xs: "0px", md: "40px" },
      }}
    >
      <Typography
        variant="h3"
        paddingBottom={"4px"}
        marginTop={"44px"}
        fontWeight={900}
        textAlign={"center"}
        paddingLeft={"8px"}
        paddingRight={"8px"}
        color={theme.palette.text.primary}
      >
        Your Community Points
      </Typography>
      <Typography
        variant="body2"
        paddingTop={"8px"}
        fontWeight={500}
        color={alpha(theme.palette.text.primary, 0.7)}
        textAlign={"center"}
        paddingLeft={"8px"}
        paddingRight={"8px"}
      >
        {`You have ${formattedPoints} Community Points!`}
      </Typography>
      <ProfileCard />
    </Box>
  );
};

export default PointsBlock;

import { Box, List, useTheme } from "@mui/material";
import { useEffect, useRef } from "react";
import { generateFrameworks } from "../FrameworkData";
import { ExampleThumbnail } from "./ExampleThumbnail";
import { ExamplesPageController } from "./ExamplesController";
import { usePageManager } from "./usePageManager";
import { useAppSelector } from "../../../../app/store";
import { ExamplesThumbnailHeader } from "./ExamplesThumbnailHeader";
import { useLocation } from "react-router-dom";
import UtilityService from "../../../../utility/UtilityService";

export const ExamplesThumbnails = ({ handleThumbnailClick }) => {
  const theme = useTheme();
  const location = useLocation();
  const listRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const frameworkData = generateFrameworks({ theme });

  const filteredExamples = useAppSelector(
    (state) => state.pageManager.filteredExamples
  );
  const selectedExample = useAppSelector(
    (state) => state.pageManager.selectedExample
  );

  const { showShadow, currentPage, totalPages, callSetCurrentPage } =
    usePageManager(listRef, itemRefs, filteredExamples);

  const getDataForFramework = (frameworkName) => {
    return frameworkData.find((framework) => framework.name === frameworkName);
  };

  const IconComponent = ({ frameworkName }) => {
    const Icon = getDataForFramework(frameworkName).image;
    return (
      <Icon
        sx={{
          width: "24px",
          height: "24px",
          fill: theme.palette.colors.grayScale.white,
          marginLeft: "5px",
        }}
      />
    );
  };

  const callThumbnailClick = (event, example, isAnchor = false) => {
    const itemWidth = 190 + 20;
    const itemsPerPage = Math.floor(
      (listRef.current?.clientWidth || 0) / itemWidth
    );
    const clickedIndex = filteredExamples.findIndex(
      (ex) => ex.title === example.title
    );
    const targetPage = Math.floor(clickedIndex / itemsPerPage) + 1;

    if (targetPage !== currentPage) {
      callSetCurrentPage(targetPage);
    }

    const newHash = UtilityService.getLocationFromString(example.title);
    if (!isAnchor && location.hash !== newHash) {
      window.location.hash = newHash;
      handleThumbnailClick(event, example);
    }
  };

  useEffect(() => {
    const handleInitialHash = () => {
      const currentHash = UtilityService.getStringFromLocation(
        window.location.hash
      );
      console.log("currentHash", currentHash);
      if (currentHash) {
        const example = filteredExamples.find(
          (item) => item.title === currentHash
        );
        if (example) {
          callThumbnailClick(new MouseEvent("click"), example, true);
        }
      }
    };

    handleInitialHash();

    const handleHashChange = () => {
      const currentHash = UtilityService.getStringFromLocation(
        window.location.hash
      );
      if (currentHash) {
        const example = filteredExamples.find(
          (item) => item.title === currentHash
        );
        if (example) {
          callThumbnailClick(new MouseEvent("click"), example, true);
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [filteredExamples]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
        overflowX: { xs: "auto", sm: "hidden" },
        overflowY: "hidden",
        padding: { xs: "0", sm: "0px" },
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "20px",
          height: { xs: `130px`, sm: `138px` },
          boxShadow: showShadow
            ? "inset -10px 0 10px -10px rgba(0, 0, 0, 0.5)"
            : "none",
          pointerEvents: "none",
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={"12px"}
        width={"100%"}
      >
        <ExamplesThumbnailHeader />
        <ExamplesPageController
          currentPage={currentPage}
          theme={theme}
          totalPages={totalPages}
          setCurrentPage={callSetCurrentPage}
        />
      </Box>
      <List
        ref={listRef}
        sx={{
          margin: "0px",
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
          overflowX: { xs: "scroll", sm: "hidden" },
          overflowY: "hidden",
        }}
      >
        {filteredExamples.map((example, index) => (
          <li
            key={example.title}
            ref={(el) => (itemRefs.current[index] = el)}
            style={{
              listStyleType: "none",
              flex: "0 0 auto",
              width: "190px",
              marginRight: "0px",
              marginTop: "0px",
              marginLeft: index === 0 ? "0px" : "10px",
              marginBottom: "8px",
            }}
          >
            <ExampleThumbnail
              example={example}
              handleClick={callThumbnailClick}
              selectedExample={selectedExample}
              theme={theme}
              getDataForFramework={getDataForFramework}
              IconComponent={IconComponent}
            />
          </li>
        ))}
      </List>
    </Box>
  );
};

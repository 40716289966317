import { alpha } from "@mui/material";

export const useInputStyles = ({ theme }) => {
  const primaryColor = theme.palette.colors.blue[900];
  const height = "40px";
  const borderRadius = "8px";
  const borderThickness = "1px";

  return {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: height,
      borderRadius: borderRadius,
      color: primaryColor,
      "& fieldset": {
        borderWidth: borderThickness,
        borderRadius: borderRadius,
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
        borderWidth: borderThickness,
      },
      backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.5),
    },
    "& .MuiOutlinedInput-input": {
      color: primaryColor,
      height: "calc(100% - 32px)",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      color: primaryColor,
    },
    "& .MuiFormLabel-root": {
      color: primaryColor,
    },
    "& .MuiSelect-select": {
      color: primaryColor,
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: primaryColor,
        },
      },
      "& .MuiOutlinedInput-input": {
        color: primaryColor,
        opacity: 0.5,
      },
    },
  };
};

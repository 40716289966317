export const termsHtmlContent = `<html>

<head>
    <meta charset="UTF-8">
    <link rel="stylesheet" type="text/css" href="../theme.css">
    <link rel="icon" type="image/png" href="/icon.png">
    <title>Multisynq - Terms and Condition</title>
</head>

<body>
    <h1>Terms of Service</h1>

    <p>Effective July 21 2023</p>

    <h1>MULTISYNQ PLATFORM LICENSE AGREEMENT
        TERMS AND CONDITIONS
    </h1>

    <p>These Multisynq Platform LICENSE AGREEMENT terms and conditions are entered into on the Effective Date
        (as defined in the Order) by and between Multisynq Network (“<b>Multisynq</b>”) and Licensee, the entity
        specified in
        the Order (as defined below), and governs access to and use of the Multisynq Platform. </p>

    <h2>Overview</h2>
    <p>1.1 <b>Platform</b>. Multisynq develops and manages a software system for creating multi-user digital experiences
        on the
        web.
        Under this Agreement, Multisynq will provide Licensee with access to the “<b>Multisynq Platform</b>,” which may
        consist
        of
        lib code, sample code, APIs, Quick Start Frameworks, reference applications, Premium Applications, documentation
        or
        other materials (each a “<b>Platform Tool</b>”) to facilitate the development of, or for incorporation into, or
        distribution with, third party applications (each an “<b>App</b>”) created by or for You, to facilitate access
        to
        Multisynq’s
        network of public reflectors and software services which enable cross platform, real-time interactivity.
    </p>
    <p>1.2 <b>Agreement</b>. This Agreement consists of these terms and conditions, and the “<b>Order</b>,” which means
        the order form
        (online or signed by the parties) that is agreed to by and between Multisynq and Licensee and references these
        terms,
        and that specifies at a minimum the Licensee, Effective Date, and any additional or paid services (such as
        support)
        chosen by Licensee, as well as any fees payable therefor. If and only if specified in the Order, this Agreement
        will
        include the Service Level Agreement and Support Policy referenced therein. Certain terms of this Agreement only
        apply to paid licenses, which means that the Order indicates that payment is required from Licensee, and
        Licensee
        makes such payments as required hereunder.
    </p>
    <p>1.3 <b>Parties</b>. The Order will indicate which party (the Licensee or another party) is responsible for
        payment of
        fees.
        If the Order indicates that a party other than Licensee is responsible for payment of fees, Licensee shall, upon
        Multisynq's request, cause such other party to acknowledge such obligation in writing.
    </p>
    <p>1.3(a). <b>Solo Developer</b>. Licensee may wish access to the Multisynq Platform for its own use, to create
        Apps for
        use
        by Licensee or for licensing to others.
    </p>
    <p>1.3(b). <b>Client Work</b>. Licensee may also wish such access in order to perform work on behalf of a client of
        Licensee,
        in which case Licensee represents and warrants that it has the authority to bind such client, and must ensure
        that
        the applicable terms of this Agreement are agreed to in writing by such client, and that each of Licensee and
        such
        client will be jointly and severally responsible for abiding by the terms of this Agreement. Upon Multisynq’s
        request,
        Licensee shall provide copies of such agreements with clients.
    </p>
    <p>1.3 (c). <b>Enterprise Development</b>. Licensee may also wish such access to develop its own Apps via contractor
        developers engaged by Licensee, in which case Licensee must ensure that the applicable terms of this
        Agreement
        are
        agreed to in writing by such contractor developers, and Licensee will be responsible for such contractors
        abiding by
        the terms of this Agreement. Upon Multisynq’s request, Licensee shall provide copies of such agreements with
        contractors.
    </p>
    <h2>2. MULTISYNQ PLATFORM LICENSE</h2>
    <p>2.1 <b>License</b>. Subject to the terms of this Agreement, Multisynq grants Licensee a limited, worldwide,
        non-assignable,
        non-exclusive, and non-sublicensable license, during the term of this Agreement, to use the Platform Tools
        solely to
        develop Apps that interact with the Multisynq Platform. Multisynq may designate certain scripts or code as
        necessary
        to include in Licensee’s Apps (“Run-Time Files”). Subject to the terms of this Agreement, Multisynq grants
        Licensee a
        limited, worldwide, non-assignable, non-exclusive, and non-sublicensable license, during the term of this
        Agreement,
        to distribute the <b>Run-Time Files</b>, solely as part Apps that interact with the Multisynq Platform,
        provided
        that any
        such distribution must be under terms that disclaim all warranties and liabilities on behalf of Multisynq,
        contain
        restrictions substantially similar to Section 3.4 (No Reverse Engineering), and grant to the end user only
        the right
        to use the Run-Time Files as party of Licensee’s Apps.
    </p>
    <p>2.2 <b>Delivery</b>. Licensee is responsible for downloading via Multisynq’s repository and installing Multisynq OS
        Platform on
        Licensee equipment. Multisynq shall give Licensee access to download theMultisynq Platform at a site to be
        designated
        by Multisynq.
    </p>
    <h2>3. INTELLECTUAL PROPERTY</h2>
    <p>3.2 <b>Ownership</b>. As between Licensee and Multisynq, Multisynq retains all legal right, title and interest in
        and to the
        Platform Tools and Multisynq Platform, including any and all Intellectual Property Rights thereto. As
        used herein,
        "<b>Intellectual Property Rights</b>" means any and all rights under patent law, copyright law, trade secret
        law, trademark
        law, and any and all other proprietary rights. Multisynq reserves all rights not expressly granted to
        Licensee
        hereunder.
    </p>
    <p>3.3 <b>Feedback</b>. If Licensee provides any technical information or suggestions to Multisynq (whether oral or
        written) in
        connection with Multisynq Platform (including but not limited to reporting errors, or making
        suggestions for
        improvements or changes to the Platform Tools or the Multisynq Platform), Licensee hereby grants
        Multisynq a
        perpetual, irrevocable, non-terminable and unrestricted right to use such informatIon or suggestions for
        its
        business purposes, including for product support and development.
    </p>
    <p>3.4 <b>No Reverse Engineering</b>. Except to the extent the following restriction is prohibited by law, Licensee
        shall not
        decompile, reverse engineer, or disassemble the Platform Tools, Multisynq Platform, or any part
        thereof.
    </p>
    <p>3.5 <b>Third Party Open Source Components</b>. Notwithstanding the licenses granted in this Agreement, Licensee
        acknowledges that certain components of Multisynq Platform (“<b>Open Source Components</b>”) may be covered by
        third party
        open source software licenses, which means any software licenses approved as open source licenses by the
        Open Source
        Initiative or any substantially similar licenses. Licensee acknowledges receipt of license notices for
        the Open
        Source Components for the initial delivery of the Product. To the extent required by the licenses
        covering third
        party Open Source Components, the terms of such licenses will apply to such Open Source Components in
        lieu of the
        terms of this Agreement. To the extent the terms of the licenses applicable to third party Open Source
        Components
        prohibit any of the restrictions in this Agreement with respect to such Open Source Component, such
        restrictions
        will not apply to such Open Source Component. To the extent the terms of the licenses applicable to
        third party Open
        Source Components require Seller to make an offer to provide source code or related information in
        connection with
        the Open Source Components, such offer is hereby made. Any request for source code or related
        information should be
        directed only to: <a href="support@multisynq.io">support@multisynq.io</a>.
    </p>
    <p>3.6 <b>Updates and Changes</b>. Multisynq may, in its sole discretion, update or change Multisynq Platform.
        Licensee
        acknowledges that future versions of Multisynq Platform may be incompatible with Apps developed using
        previous
        versions of Multisynq Platform. For paid licenses, Multisynq shall provide a reasonable notice of any
        incompatibility
        known to Multisynq, no less than 30 days prior to such change.
    </p>
    <p>3.7 <b>Trademarks and Branding</b>. Nothing in this Agreement gives Licensee a right to use any of Multisynq's
        trade names,
        trademarks, service marks, logos, domain names, or other distinctive brand features. Further, Licensee
        will not
        remove, obscure, or alter any proprietary rights notices (including copyright and trademark notices)
        that may be
        affixed to or contained within any of the Platform Tools.
    </p>
    <p>3.8 <b>Support</b>. Except as may be expressly set forth in the Order, Multisynq is not obligated to provide any
        technical or
        other support (“Support”) for Multisynq Platform. However, Licensee may post questions to the Multisynq
        Licensee
        Discord channel at <a href="https://croquet.io/discord" target="_blank">https://croquet.io/discord</a> If the
        Order so specifies, Multisynq shall, during the term
        of this
        Agreement, provide support in accordance with the Support Policy referenced in the Order. Multisynq may
        update its
        Support Policy upon written notice.
    </p>
    <h2>4. USE OF MULTISYNQ PLATFORM</h2>
    <p>4.1 <b>Acceptable Use</b>. Licensee shall use Multisynq Platform only for purposes that are permitted by
        any applicable
        laws, statutes or regulations. From time to time, Multisynq may publish Acceptable Use Policies, in
        which case
        Licensee must also abide by such policies. Multisynq may, in its sole discretion, restrict access to
        the Multisynq OS
        Platform for any App that violates the foregoing, or that Multisynq otherwise determines will cause
        liability or
        damage to Multisynq, its systems or technology, or other Multisynq customers or Licensees. Licensee, and
        not Multisynq, is
        solely responsible for any data, content, or resources created, transmitted or displayed via Apps.
        Multisynq may
        update such Acceptable Use Policies upon written notice.
    </p>
    <p>4.2 <b>Data</b>. Licensee shall maintain and process all user data processed through the Apps in accordance
        with Licensee’s
        (or its client’s, if applicable) privacy policy and all applicable privacy laws and regulations in
        any countries in
        which your App is distributed or used. Licensee acknowledges that Multisynq may collect certain data
        through
        Licensee’s or the App’s access to Multisynq Platform. For such data, Multisynq shall abide by its
        then-current
        Privacy Policy, the current version of which can be accessed at <a
            href="https://multisynq.io/privacy.html">https://multisynq.io/privacy.html</a>.
    </p>
    <p>4.3 <b>Licensee Account and API Keys</b>. To use Multisynq Platform, Licensee must create an account at
        <a href="https://croquet.io" target="_blank">https://croquet.io</a>. To activate an account, Licensee may need
        to provide Multisynq with certain
        information,
        including, but not limited to a representative's name and email address. All such information will
        be collected and
        used in accordance with then-current Multisynq’s Privacy Policy, the current version of which is
        located at
        <a href="https://multisynq.io/privacy.html">https://multisynq.io/privacy.html</a>. Licensee is responsible for
        maintaining the confidentiality of any
        credentials,
        including but not limited to API keys, that may be issued to Licensee or its clients or contractors,
        and will be
        responsible for all activity under Licensee’s credentials.
    </p>
    <p>4.4 <b>Service Levels</b>. If the Order so specifies, Multisynq shall, during the term of this Agreement,
        provide support in
        accordance with the Service Level Agreement (SLA) referenced in the Order. SLAs will only apply to
        paid licenses.
        Multisynq may update its SLAs upon written notice.
    </p>
    <h2>5. FEES</h2>
    <p>Unless otherwise indicated in the Order, Licensee’s use of Multisynq Platform under this
        Agreement is free of
        charge. However, an App’s connection to the Multisynq Platform may be subject to fees in
        accordance with Multisynq’s
        latest published pricing as published from time to time (the “Multisynq Fees Schedule”). Use
        within the “free tier” is
        free of charge, but additional use will be subject to the fees in the Multisynq Fees Schedule.
        Your Licensee account
        will be charged the applicable access fee if you elect to use one of Multisynq’s pre-built
        applications (“Premium
        Applications”).
    </p>
    <h2>6. TERM AND TERMINATION</h2>
    <p>6.1 <b>Term</b>. The term of this Agreement commences on the Effective Date, or the date Licensee
        first gains access to
        Multisynq Platform, whichever is first. The term will continue unless and until terminated
        by either you or Multisynq
        as set out below.
    </p>
    <p>6.2 <b>Termination</b>. Multisynq may at any time, terminate this Agreement and deactivate Licensee’s
        account if: (A)
        Licensee breaches any provision of this Agreement; or (B) Multisynq is required to do so by
        law; or (C) upon
        reasonable notice, but in no event less than 30 days notice, if Multisynq elects to no longer
        provide Multisynq OS
        Platform or certain parts thereof. In addition, Multisynq may terminate this Agreement at any
        time, for any reason or
        no reason, on no less than 30 days notice, provided that if such termination is not for
        cause, Multisynq will refund
        any unused prepaid fees on a prorated basis. Multisynq may provide notice via the email
        address set forth in the
        Order. Licensee may, at any time, terminate this Agreement if Multisynq breaches any provision
        of this Agreement and
        does not cure such breach within 30 days after providing Multisynq with notice of such breach
        in reasonable detail.
    </p>
    <p>6.3 <b>Effect of Termination</b>. Upon termination of this Agreement, Licensee shall immediately
        destroy all copies of the
        Platform Tools in its possession or control and discontinue distribution of all Apps.
    </p>
    <p>6.4 <b>Survival</b>. Sections 1.3 (Parties), 3.2 (Ownership), 3.3 (Feedback), 3.4 (No Reverse
        Engineering), 3.5 (Third
        Party Open Source Components), 6.3 (Effect of Termination), 7 (Warranties), 8 (Limitation of
        Liability), 9
        (Confidentiality), 10 (Indemnification), 11 (Dispute Resolution) and 12 (General) will
        survive any termination
        and/or expiration of this Agreement.
    </p>
    <h2>7. WARRANTIES</h2>

    <p>LICENSEE ACKNOWLEDGES THAT ITS USE OF THE PLATFORM TOOLS AND MULTISYNQ PLATFORM IS AT
        LICENSEE’S SOLE RISK AND THAT
        THE PLATFORM TOOLS AND MULTISYNQ PLATFORM ARE PROVIDED "AS IS" AND "AS AVAILABLE"
        WITHOUT WARRANTY OF ANY KIND FROM
        MULTISYNQ. MULTISYNQ EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR
        PURPOSE AND NON-INFRINGEMENT.</p>

    <h2>8. LIMITATION OF LIABILITY</h2>
    <p>MULTISYNQ, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS WILL NOT BE LIABLE
        HEREUNDER FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING IN CONNECTION
        WITH ANY LOSS OF DATA, WHETHER OR
        NOT MULTISYNQ OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF SUCH DAMAGES. IN NO EVENT
        WILL MULTISYNQ’S LIABILITY ARISING
        OUT OF OR RELATED TO THIS AGREEMENT OR TO THE MULTISYNQ PLATFORM OR PLATFORM TOOLS
        EXCEED THE GREATER OF (A) ONE
        HUNDRED US DOLLARS; OR (B) FEES ACTUALLY PAID BY LICENSEE UNDER THIS AGREEMENT.
        THESE LIMITATIONS WILL APPLY
        NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. THE PARTIES
        AGREE THAT THE FOREGOING
        LIMITATIONS REPRESENT A REASONABLE ALLOCATION OF RISK UNDER THIS AGREEMENT.</p>

    <h2> 9. CONFIDENTIALITY</h2>
    <p>All information provided by Licensee to Multisynq is non-confidential, subject only
        to any then-applicable privacy
        policy published by Multisynq.</p>

    <h2>10. INDEMNIFICATION</h2>
    <p>Licensee shall defend, indemnify and hold harmless Multisynq, its affiliates and
        their respective directors, officers,
        shareholders, employees and agents from and against any and all claims, actions,
        suits or proceedings, as well as
        any and all losses, liabilities, damages, costs and expenses (including
        reasonable attorney’s fees) arising out of
        or accruing from (a) use of the Platform Tools or Multisynq Platform, (b) any
        App developed with the Platform
        Tools, including due to such App infringing any copyright, trademark, trade
        secret, trade dress, patent or other
        intellectual property right of any person or defaming any person or violates
        their rights of publicity or privacy,
        and (c) any non-compliance with the terms of this Agreement.
    </p>
    <h2>11. DISPUTE RESOLUTION</h2>

    <p>Any dispute arising out of or relating to this Agreement shall be resolved
        exclusively by
        final binding arbitration administered by JAMS and conducted before one
        commercial arbitrator. The arbitration will
        take place in Los Angeles County, California. Judgment on any arbitration award
        may be entered in any court having
        proper jurisdiction. Nonetheless, a party may file an application in any court
        of competent jurisdiction for a
        provisional remedy pending the commencement of the arbitration. The prevailing
        party in any arbitration or court
        proceeding will be entitled to reasonable costs and attorneys’ fees. Process may
        be served upon a party by mailing
        such process to its address for notices as provided for herein. Should any
        litigation be commenced among the parties
        in relation to this Agreement, the party prevailing in such litigation shall be
        entitled, in addition to such other
        relief as may be granted, to its attorneys’ fees in connection with such
        litigation or in a separate action brought
        for that purpose.</p>

    <h2>12. GENERAL</h2>
    <p>12.1 <b>Entire Agreement</b>. This Agreement constitutes the whole legal agreement
        regarding the subject matter of this
        Agreement. Any modification of this Agreement must be in writing and duly agreed
        to by the parties.
    </p>
    <p>12.2 <b>Waiver</b>. If either party does not exercise or enforce any legal right or
        remedy which is contained in this
        Agreement, this will not be deemed a waiver of such party’s rights.
    </p>
    <p>12.3 <b>Warranty</b>. Each of Multisynq and Licensee represents and warrants that it is
        duly organized, validly existing and
        in good standing under the laws of its state of its incorporation and has the
        power and authority to enter into this
        Agreement.
    </p>
    <p>12.4 <b>Severability</b>. If any court of law, having the jurisdiction to decide on this
        matter, rules that any provision
        of this Agreement is invalid, then that provision will be removed from this
        Agreement and, to the extent possible
        under law, reformed to effectuate the intent of the parties. The remaining
        provisions of this Agreement will
        continue to be valid and enforceable.
    </p>
    <p>12.5 <b>No Third Party Beneficiaries</b>. No other person or company shall be third
        party beneficiaries to this Agreement.
    </p>
    <p>12.6 <b>Export Restrictions</b>. The licenses granted hereunder are subject to United
        states export laws and regulations.
    </p>
    <p>12.7 <b>No Assignment</b>. The rights granted in this Agreement may not be assigned or
        transferred by Licensee without
        Multisynq’s prior written approval, which it may give or withhold in its
        discretion. Licensee has no right to delegate
        its responsibilities or obligations under this Agreement without Multisynq’s prior
        written approval. Multisynq may
        freely assign, transfer or delegate its rights or obligations hereunder.</p>

    <p>12.8 <b>Governing Law</b>. This Agreement shall be interpreted according to the laws of
        the State of California without
        regard to its conflict of laws provisions. The United Nations Convention on the
        International Sale of Goods will not
        apply.</p>




</body>

</html>`;

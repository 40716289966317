import { alpha, Box, Typography, useTheme } from "@mui/material";
import { Copy } from "../../../../../../../icons";
import { CustomIconButton } from "../../../../../../ui-elements/CustomIconButton";
import { useState } from "react";
import { KeyFilterService } from "../../../../../../../services/KeyFilterService";
import { useAppSelector } from "../../../../../../../app/store";

export const CopyKeyBlock = ({
  apiKeyString: apiKeyString,
}: {
  apiKeyString: string;
}) => {
  const theme = useTheme();
  const filterString = useAppSelector((state) => state.apiKey.filterString);
  const primaryColor = theme.palette.colors.blue[900];
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(apiKeyString);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop="16px"
      width="100%"
      maxWidth="100%"
      sx={{
        background: theme.palette.colors.grayScale[75],
        borderRadius: "4px",
        padding: "12px",
      }}
    >
      <CustomIconButton
        icon={<Copy />}
        onClick={handleCopy}
        sx={{
          marginRight: "8px",
          height: "50px",
          width: "50px",
          flexShrink: 0,
          background: theme.palette.background.headerBackgroundGradient,
          color: "white",
          fill: "white",
          borderRadius: "8px",
          paddingLeft: "4px",
          paddingRight: "4px",
          "&:hover": {
            background: alpha(theme.palette.colors.royalBlue[500], 0.8),
            fill: "white",
            boxShadow: "none",
            paddingLeft: "3px",
            paddingRight: "3px",
          },
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        width="calc(100% - 40px)"
        marginLeft="6px"
        overflow="hidden"
      >
        <Typography
          variant="subtitle2"
          color={primaryColor}
          width="100%"
          sx={{ opacity: 0.7, fontSize: "0.6rem", fontWeight: "bold" }}
        >
          {isCopied ? "Copied!" : "Key"}
        </Typography>
        <Typography
          variant="body2"
          color={primaryColor}
          sx={{
            fontSize: "0.8rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
          }}
          title={apiKeyString}
        >
          {KeyFilterService.highlightText(apiKeyString, filterString, theme)}
        </Typography>
      </Box>
    </Box>
  );
};

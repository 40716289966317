// VerificationPendingView.tsx
import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { BackToLoginButton } from "./blocks/BackToLoginButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/store";

interface ReferralPreviewViewProps {
  setView: (view: AuthViews) => void;
}

const ReferralPreviewView: React.FC<ReferralPreviewViewProps> = ({
  setView,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const goToMyAccount = async () => {
    navigate("/account");
  };

  const cleanErrorMessage = () => {
    if (currentErrorMessage) {
      return currentErrorMessage;
    }
    return null;
  };

  return (
    <AuthViewWrapper
      title="You've Used A Referral Link"
      viewState={AuthViewState.Default}
      currentErrorMessage={cleanErrorMessage()}
      clearErrorMessage={clearErrorMessage}
    >
      <Typography
        variant="body2"
        color={theme.palette.colors.grayScale.white}
        textAlign={"center"}
        lineHeight={1.6}
        sx={{ mb: 2, mt: 2, opacity: 0.8 }}
      >
        {`You have attempted to use a referral link but are currently logged in to your account.  You can either 1) log out, 2) click the link again, and 3) sign up with a different account using that link OR press the button below to continue on to your account page.`}
      </Typography>

      <CustomButton
        sx={{
          marginTop: "26px",
        }}
        onClick={() => goToMyAccount()}
        disableRipple
      >
        Go To My Account
      </CustomButton>
      <BackToLoginButton setView={setView} />
    </AuthViewWrapper>
  );
};

export default ReferralPreviewView;

import React, { useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { openApiKeyPopup } from "../../../../../../../features/ui/apiKeyPopupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import UtilityService from "../../../../../../../utility/UtilityService";
import { ApprovedUrlsDisplayBlock } from "./ApprovedUrlsDisplayBlock";
import { CopyKeyBlock } from "./CopyKeyBlock";
import { DataDisplay } from "./DataDisplay";
import { IApiKey } from "../../../../../../../types/api-key/apiKeyTypes";
import { ApiKeyCellActionButtons } from "./ApiKeyCellActionButtons";
import { deleteApiKey } from "../../../../../../../features/api-key/apiKeySlice";
import { KeyFilterService } from "../../../../../../../services/KeyFilterService";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import { Check } from "@mui/icons-material";

interface KeyCellProps {
  apiKey: IApiKey;
  refreshOnDelete: () => Promise<void>;
}

const KeyCell: React.FC<KeyCellProps> = ({ apiKey, refreshOnDelete }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const primaryColor = theme.palette.colors.blue[900];
  const { name, region, lastUsed, created, allowedUrls, apiKey: key } = apiKey;

  const createdDateDisplayString = UtilityService.getDateString(
    new Date(created)
  );
  const lastUsedDateDisplayString =
    lastUsed &&
    lastUsed !== "1970-01-01T00:00:00.000Z" &&
    lastUsed !== undefined
      ? UtilityService.getDateString(new Date(lastUsed))
      : "Never";

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const filterString = useAppSelector((state) => state.apiKey.filterString);

  function regionDisplayString() {
    const label = region.label;
    return `${label}`;
  }

  const handleEdit = () => {
    dispatch(openApiKeyPopup(apiKey));
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    setDeleting(true);
    await dispatch(deleteApiKey(apiKey));
    await refreshOnDelete();
    setDeleting(false);
    setDeleteDialogOpen(false);
  };

  const UrlSection = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        padding={{ xs: "12px", sm: "20px" }}
        width={{ xs: "100%", sm: "40%" }}
        maxWidth={{ xs: "100%", sm: "40%" }}
        sx={{
          overflowX: "none",
          borderLeft: { xs: "none", sm: `1px solid ${theme.palette.divider}` },
          borderTop: { xs: `1px solid ${theme.palette.divider}`, sm: "none" },
        }}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          alignItems="center"
          marginBottom="8px"
        >
          <Box
            display="flex"
            alignItems="baseline"
            marginRight={{ xs: "8px", sm: "0px" }}
          >
            <Typography
              variant="subtitle1"
              color={primaryColor}
              fontWeight="800"
              marginRight="4px"
              sx={{ fontSize: isXs ? "0.9rem" : "inherit" }}
            >
              URLs
            </Typography>
            <Typography
              variant="body2"
              color={primaryColor}
              sx={{ opacity: 0.5, fontSize: "0.8rem" }}
            >
              {allowedUrls.length}
            </Typography>
          </Box>
          {/* <Box display={apiKey.allowDev ? "flex" : "none"} alignItems="center">
            <Box
              sx={{
                backgroundColor: theme.palette.colors.teal[500],
                borderRadius: "16px",
                padding: "2px 6px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Check
                sx={{
                  color: theme.palette.colors.grayScale.white,
                  fontSize: "0.6rem",
                  marginRight: "2px",
                }}
              />
              <Typography
                variant="body2"
                color={theme.palette.colors.grayScale.white}
                sx={{ fontSize: "0.5rem", fontWeight: 700, cursor: "default" }}
              >
                Allow Dev
              </Typography>
            </Box>
          </Box> */}
        </Box>
        <ApprovedUrlsDisplayBlock
          urls={allowedUrls}
          allowDev={apiKey.allowDev}
        />
      </Box>
    );
  };

  const MainSection = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={{ xs: "12px", sm: "20px" }}
        width={{ xs: "100%", sm: "60%" }}
      >
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              fontWeight="900px"
              marginRight="8px"
              paddingBottom="4px"
              color={primaryColor}
              sx={{ fontSize: isXs ? "1.3rem" : "1.6rem" }}
            >
              {KeyFilterService.highlightText(name, filterString, theme)}
            </Typography>
            <Box display="flex">
              <ApiKeyCellActionButtons
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </Box>
          </Box>
          <DataDisplay
            title="Last Used"
            dataString={lastUsedDateDisplayString}
            featured={true}
          />
        </Box>
        <Box display={"flex"} flexDirection={"row"}>
          <CopyKeyBlock apiKeyString={key} />
          <Box display="flex" flexDirection="row" width="100%" />
        </Box>
        <Box paddingTop="16px">
          <DataDisplay title="Created" dataString={createdDateDisplayString} />
          <DataDisplay
            title="Storage Region"
            dataString={regionDisplayString()}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "flex-start", sm: "space-between" }}
        alignItems="stretch"
        width="100%"
        height={{ xs: "100%", sm: "240px" }}
        maxHeight={{ xs: "700px", sm: "240px" }}
        marginBottom="4px"
        marginTop="20px"
        sx={{
          backgroundColor: theme.palette.colors.grayScale.white,
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <MainSection />
        <UrlSection />
      </Box>
      <ConfirmDeletePopup
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        deleting={deleting}
        apiKey={apiKey}
        confirmDelete={confirmDelete}
      />
    </>
  );
};

export default KeyCell;

import UserDetailsView from "./UserDetailsView";
import AnimatedViewWrapper, {
  AnimatedFromDirection,
} from "./blocks/AnimatedViewWrapper";
import VerificationPendingView from "./VerificationPendingView";
import ReactivationEmailSentView from "./ReactivationEmailSentView";
import ResetPasswordView from "./ResetPasswordView";
import SignupView from "./SignupView";
import LoginView from "./LoginView";
import RequestResetPasswordView from "./RequestResetPasswordView";
import ReferralPreviewView from "./ReferralPreviewView";

export enum AuthViews {
  "Signup",
  "Login",
  "VerificationPending",
  "ReactivationEmailSent",
  "UserDetails",
  "ResetPassword",
  "RequestResetPassword",
  "Account",
  "ReferralPreview",
}

export enum AuthViewState {
  "Loading",
  "Error",
  "Default",
}

export const fileTypes = ["JPEG", "PNG"];

export const currentState = ({ loading, error }) => {
  if (loading) {
    return AuthViewState.Loading;
  }
  if (error) {
    return AuthViewState.Error;
  }
  return AuthViewState.Default;
};

export const componentForViewAndState = ({
  currentView,
  viewState,
  setViewState,
  setView,
  prevView,
  token = null,
  setToken = null,
  subscribing = false,
}) => {
  const key = `animated-view-${currentView}`;

  switch (currentView) {
    case AuthViews.Signup:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <SignupView
            viewState={viewState}
            setViewState={setViewState}
            setView={setView}
          />
        </AnimatedViewWrapper>
      );
    case AuthViews.Login:
      return (
        <AnimatedViewWrapper
          key={key}
          direction={
            prevView === AuthViews.Signup ||
            prevView === AuthViews.RequestResetPassword
              ? AnimatedFromDirection.Left
              : AnimatedFromDirection.Center
          }
        >
          <LoginView
            viewState={viewState}
            setViewState={setViewState}
            setView={setView}
          />
        </AnimatedViewWrapper>
      );
    case AuthViews.VerificationPending:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <VerificationPendingView setView={setView} />
        </AnimatedViewWrapper>
      );
    case AuthViews.ReactivationEmailSent:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <ReactivationEmailSentView setView={setView} />
        </AnimatedViewWrapper>
      );
    case AuthViews.UserDetails:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <UserDetailsView
            viewState={viewState}
            setViewState={setViewState}
            setView={setView}
          />
        </AnimatedViewWrapper>
      );
    case AuthViews.RequestResetPassword:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <RequestResetPasswordView setView={setView} />
        </AnimatedViewWrapper>
      );
    case AuthViews.ResetPassword:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <ResetPasswordView
            setView={setView}
            token={token}
            setToken={setToken}
          />
        </AnimatedViewWrapper>
      );
    case AuthViews.ReferralPreview:
      return (
        <AnimatedViewWrapper key={key} direction={AnimatedFromDirection.Right}>
          <ReferralPreviewView setView={setView} />
        </AnimatedViewWrapper>
      );
    default:
      return null;
  }
};

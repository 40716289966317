import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import createGlobalTheme from "./customTheme/globalTheme";
import { GlobalStylesComponent } from "./customTheme/GlobalStyles";
import PageManager from "./components/page-manager/PageManager";
import ErrorMessengerPopup from "./components/helpers/ErrorMessengerPopup";
import TokenValidator from "./components/helpers/TokenValidator";
import { PageWrapper } from "./components/pages/PageWrapper";

const App: React.FC = () => {
  const theme = createGlobalTheme(false);
  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      // Prevent the default handling (e.g., logging to the console)
      event.preventDefault();
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStylesComponent isDarkMode={false} />
      <GoogleOAuthProvider clientId={clientId}>
        <Router>
          <TokenValidator />
          <ErrorMessengerPopup />
          <PageWrapper>
            <PageManager />
          </PageWrapper>
        </Router>
        {/* <CookieValidator /> */}
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;

import { TextField, useTheme } from "@mui/material";
import { useInputStyles } from "../inputStyles";

interface ApiTextFieldProps {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ApiTextField = ({
  placeholder,
  value,
  onChange,
  name,
  onKeyDown,
  inputRef,
}: ApiTextFieldProps) => {
  const theme = useTheme();
  const inputStyles = useInputStyles({ theme });

  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      variant="outlined"
      sx={inputStyles}
      onKeyDown={onKeyDown ? onKeyDown : undefined}
      inputRef={inputRef}
    />
  );
};

import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { AuthViewState } from "../ViewContentHelper";
import { useEffect } from "react";

interface AuthViewWrapperProps {
  title: string;
  viewState: AuthViewState;
  currentErrorMessage: string | null;
  clearErrorMessage: () => void;
  children: React.ReactNode;
  colorOverride?: string;
}

export const AuthViewWrapper: React.FC<AuthViewWrapperProps> = ({
  title,
  children,
  viewState,
  currentErrorMessage,
  clearErrorMessage,
  colorOverride,
}) => {
  const theme = useTheme();

  useEffect(() => {
    //console.log("viewState", viewState);
  }, [viewState]);

  useEffect(() => {
    //console.log("errorMessage", currentErrorMessage);
  }, [currentErrorMessage]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"top"}
      alignItems={"center"}
      alignContent={"center"}
      sx={(theme) => ({
        width: "360px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      })}
    >
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="h4"
          fontWeight={800}
          color={theme.palette.colors.grayScale.white}
          gutterBottom
        >
          {title}
        </Typography>
      </Box>
      {viewState === AuthViewState.Loading && (
        <Box
          sx={{
            position: "relative",
            top: 20,
          }}
        >
          <CircularProgress thickness={6} />
        </Box>
      )}
      {currentErrorMessage && viewState !== AuthViewState.Loading && (
        <Typography
          sx={{
            color: colorOverride ? colorOverride : theme.palette.error.main,
            fontSize: "12px",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "600",
            padding: "8px",
          }}
          onClick={() => {
            clearErrorMessage();
          }}
        >
          {currentErrorMessage || "Something went wrong, please try again"}
        </Typography>
      )}
      {children}
    </Box>
  );
};

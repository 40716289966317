import * as React from "react";

interface CustomEmojiProps {
  image: string;
  sx?: React.CSSProperties;
}

export const CustomEmoji: React.FC<CustomEmojiProps> = ({ image, sx }) => {
  return <img src={image} style={sx} />;
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import {
  handleAxiosError,
  handleAxiosErrorWithStatus,
} from "../../app/errorHandler";
import { IRegisteredEmail, ISignupPhase } from "../../types/user";

const initialState = {
  activeEmail: "",
  signupPhases: [] as ISignupPhase[],
  showPageWrapper: true,
  signInMessage: "",
};

export const getSignupPhases = createAsyncThunk(
  "publicUiState/getSignupPhases",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const data = await api.getSignupPhases();
      dispatch(setSignupPhases(data.data.payload));
      return data.data.payload;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const registerEmail = createAsyncThunk(
  "publicUiState/registerEmail",
  async (payload: IRegisteredEmail, { dispatch, rejectWithValue }) => {
    try {
      const email = payload.email;
      const data = await api.registerEmail(payload);
      if (data.status === 200) {
        dispatch(setActiveEmail(email));
        return email;
      } else {
        console.log("returning in try block", data);
        return data.data.payload;
      }
    } catch (error) {
      console.log("returning in catch block", error);
      return rejectWithValue(handleAxiosErrorWithStatus(error));
    }
  }
);

const publicUiStateSlice = createSlice({
  name: "publicUiState",
  initialState: initialState,
  reducers: {
    setActiveEmail: (state, action) => {
      state.activeEmail = action.payload;
    },
    setSignupPhases: (state, action) => {
      state.signupPhases = action.payload;
    },
    setShowPageWrapper: (state, action) => {
      state.showPageWrapper = action.payload;
    },
    setSignInMessage: (state, action) => {
      state.signInMessage = action.payload;
    },
  },
});

export const {
  setActiveEmail,
  setSignupPhases,
  setShowPageWrapper,
  setSignInMessage,
} = publicUiStateSlice.actions;
export default publicUiStateSlice.reducer;

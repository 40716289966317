import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

export const CellComponentBase: React.FC<{
  title: string;
  instruction?: string;
  italicizeInstruction?: boolean;
  children: React.ReactNode;
  showDisabled?: boolean;
}> = React.memo(
  ({
    title,
    instruction,
    italicizeInstruction,
    children,
    showDisabled = false,
  }) => {
    const theme = useTheme();

    return (
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 1,
            color: theme.palette.text.primary,
            fontWeight: 800,
            fontSize: "1.1rem",
            opacity: showDisabled ? 0.5 : 1,
          }}
        >
          {title}
        </Typography>

        {instruction && (
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              color: theme.palette.text.primary,
              fontWeight: 400,
              fontStyle: italicizeInstruction ? "italic" : "normal",
              opacity: showDisabled ? 0.5 : 1,
            }}
          >
            {instruction}
          </Typography>
        )}
        {children}
      </Box>
    );
  }
);

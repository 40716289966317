import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { lighten, useTheme } from "@mui/material/styles";

export interface CustomButtonProps extends ButtonProps {
  onClick: () => void;
  setHovering?: (hovering: boolean) => void;
  highlightColor?: string;
  sx?: React.CSSProperties;
  children: React.ReactNode;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  setHovering,
  sx,
  highlightColor,
  children,
  ...props
}) => {
  const theme = useTheme();
  const blueColor = theme.palette.primary.main;

  return (
    <Button
      onClick={onClick}
      onMouseEnter={setHovering ? () => setHovering(true) : undefined}
      onMouseLeave={setHovering ? () => setHovering(false) : undefined}
      variant="contained"
      sx={{
        borderRadius: "4px",
        height: "40px",
        paddingLeft: "20px",
        paddingRight: "20px",
        textTransform: "none",
        backgroundColor: blueColor,
        color: "white",
        fontSize: "16px",
        "&:hover": {
          backgroundColor: highlightColor
            ? highlightColor
            : lighten(blueColor, 0.15),
          boxShadow: "none",
          transition: "0.15s",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

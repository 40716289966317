import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAppSelector } from "../../../../../app/store";
import { DataBlock } from "./DataBlock";
import { UserDataFormatterService } from "../../../../../services/UserDataFormatterService";
import CleanSVG from "../../../../../services/CleanSVG";
import { SvgName } from "../../../../../images/SvgAssets";

export const ProfileCard = () => {
  const theme = useTheme();
  const userDetails = useAppSelector((state) => state.userDetails?.userDetails);
  const userCredentials = useAppSelector(
    (state) => state.userCredentials?.userCredentials
  );
  const userEngagement = useAppSelector(
    (state) => state.userEngagement?.userEngagement
  );
  const cardBackgroundGradient = `linear-gradient(180deg, #006AFF 0%, #002AFF 100%)`;

  const getAccountName = () => {
    if (userDetails?.isNoder && userDetails?.isCoder) {
      return "Synqer and Coder";
    }
    if (userDetails?.isNoder) {
      return "Synqer";
    }
    if (userDetails?.isCoder) {
      return "Coder";
    }
    return "Still Deciding";
  };

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const getUserLocale = (): string => {
    const language = navigator.language;
    if (language && language === "en") {
      return "en-GB";
    }

    return navigator.language || "en-US";
  };

  const readableDate = (date: string | number | Date): string => {
    const locale = getUserLocale();
    const parsedDate = new Date(date);

    if (isNaN(parsedDate.getTime())) {
      return "Invalid Date";
    }

    return parsedDate.toLocaleDateString(locale, {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
    });
  };

  return (
    <Box
      maxWidth={{ xs: "100%", sm: "500px" }}
      width={{ xs: "100%", sm: `calc(100% - 32px)` }}
      sx={{
        background: cardBackgroundGradient,
        borderRadius: { xs: "0px", sm: "8px" },
        color: "white",
        padding: 2,
        position: "relative",
        marginRight: "16px",
        marginLeft: "16px",
        marginTop: { xs: "64px", sm: "24px" },
      }}
    >
      <Box
        display={{ xs: "flex", sm: "none" }}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        left={`calc(50% - 32px) `}
        marginTop={"-60px"}
        marginBottom={"-2px"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            borderRadius: "50%",
            background: theme.palette.colors.blue[500],
            padding: "1px",
            boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.2)",
          }}
        >
          <AccountCircleIcon sx={{ fontSize: 100 }} />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          opacity: 0.1,
          zIndex: 0,
        }}
      >
        <CleanSVG
          name={SvgName.Dots}
          style={{
            width: isXs ? "300%" : "150%",
            height: "auto",
            position: "absolute",
            left: "10%",
            top: isXs ? "60%" : "95%",
            transform: "translate(-52%, -30%)",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box display={{ xs: "none", sm: "flex" }}>
            <AccountCircleIcon sx={{ fontSize: 48, marginRight: 1 }} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={16} fontWeight={900}>
              {userDetails?.firstName ?? "" + " " + userDetails?.lastName ?? ""}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 12 }}>
                {userCredentials?.email ?? "No email"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          justifyContent={"center"}
        >
          <Typography fontSize={16} fontWeight={900}>
            Account
          </Typography>
          <Typography sx={{ fontSize: 12 }}>{getAccountName()}</Typography>
        </Box>
      </Box>
      <Divider sx={{ bgcolor: "white", opacity: 0.2, marginBottom: "16px" }} />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        marginRight={"32px"}
      >
        <DataBlock
          title={"Since"}
          value={readableDate(userDetails?.createdAt)}
        />

        <DataBlock
          title={"Points"}
          value={UserDataFormatterService.formattedNumber(
            userEngagement?.points ?? 0
          )}
        />
        <DataBlock
          title={"Referrals"}
          value={UserDataFormatterService.formattedNumber(
            userEngagement?.referralCount ?? 0
          )}
        />
      </Box>
    </Box>
  );
};

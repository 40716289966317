import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { CustomButton } from "../../ui-elements/CustomButton";
import { Discord, Telegram } from "../../../icons";

const SocialsPage = ({ showPageWrapper }: PageProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [hoveringDiscord, setHoveringDiscord] = useState(false);
  const [hoveringTelegram, setHoveringTelegram] = useState(false);

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "Discord") {
      window.open("https://multisynq.io/discord", "_blank");
    }

    if (buttonName === "Telegram") {
      window.open("https://t.me/multisynq", "_blank");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SocialButton = ({
    icon,
    setHovering,
    title,
  }: {
    icon: React.ReactNode;
    setHovering: (hovering: boolean) => void;
    title: string;
  }) => {
    return (
      <CustomButton
        onClick={() => handleButtonClick(title)}
        setHovering={setHovering}
        highlightColor={theme.palette.colors.blue[100]}
        sx={{
          backgroundColor: theme.palette.colors.grayScale.white,
          color: theme.palette.colors.royalBlue[700],
          fill: theme.palette.colors.royalBlue[700],
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            marginRight={"8px"}
            sx={{
              animation: hoveringDiscord ? "wiggle 1.3s infinite" : "none",
            }}
          >
            {icon}
          </Box>
          {title}
        </Box>
      </CustomButton>
    );
  };

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      right={0}
      left={0}
      top={0}
      bottom={0}
      marginTop={"-70px"}
      paddingTop={"80px"}
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
      }}
    >
      <Box
        display={"flex"}
        flexGrow={2}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          margin: "16px",
          marginTop: "0px",
          padding: "20px",
          paddingTop: "48px",
          paddingBottom: "48px",
          overflowY: "auto",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant={isXs ? "h3" : "h1"}
          color={theme.palette.colors.grayScale.white}
          textAlign={"center"}
          marginBottom={"8px"}
          paddingLeft={{ xs: "16px", sm: "0px" }}
          paddingRight={{ xs: "16px", sm: "0px" }}
        >
          Join The Community
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.colors.grayScale.white}
          paddingBottom={"48px"}
          textAlign={"center"}
          maxWidth={"500px"}
        >
          We're on a bunch of social media platforms. Follow us to stay up to
          date with the latest news and updates.
        </Typography>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <SocialButton
            icon={
              <Discord
                sx={{ width: "24px", height: "24px", marginTop: "8px" }}
              />
            }
            setHovering={setHoveringDiscord}
            title="Discord"
          />
          <SocialButton
            icon={
              <Telegram
                sx={{ width: "24px", height: "24px", marginTop: "8px" }}
              />
            }
            setHovering={setHoveringTelegram}
            title="Telegram"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialsPage;

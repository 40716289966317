import { Box, alpha, useTheme } from "@mui/material";
import HtmlScrubberService from "../../../services/HtmlScrubberService";
import { termsHtmlContent } from "./termsHtmlContent";
import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";

const TermsPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      right={0}
      left={0}
      top={0}
      bottom={0}
      marginTop={"-70px"}
      paddingTop={"80px"}
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
      }}
    >
      <Box
        display={"flex"}
        flexGrow={2}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          margin: "16px",
          marginTop: "0px",
          padding: "20px",
          paddingTop: "0px",
          overflowY: "auto",
          background: alpha(theme.palette.colors.grayScale.white, 0.1),
          color: theme.palette.colors.grayScale.black,
          borderRadius: "8px",
        }}
      >
        <HtmlScrubberService html={termsHtmlContent} />
      </Box>
    </Box>
  );
};

export default TermsPage;

import { Box, Typography } from "@mui/material";

export const SectionHeader = ({ theme, title, alignItems }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={alignItems}
      position={"relative"}
      marginTop={"40px"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={alignItems}
        justifyContent="center"
        width={{ xs: "100%" }}
        paddingTop={"16px"}
        paddingBottom={"16px"}
      >
        <Typography
          variant="h4"
          color={theme.palette.colors.blue[900]}
          paddingBottom={"8px"}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export const SectionContainer = ({ theme, children }) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column" }}
      justifyContent="center"
      alignItems="center"
      width={"100%"}
      paddingLeft={{ xs: "10px", sm: "20px", md: "40px" }}
      paddingRight={{ xs: "10px", sm: "20px", md: "40px" }}
      paddingBottom={{ xs: "16px", sm: "60px" }}
      sx={{
        backgroundColor: theme.palette.colors.grayScale.white,
      }}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        width={"100%"}
        maxWidth={"900px"}
      >
        {children}
      </Box>
    </Box>
  );
};

import { Box, Typography, useTheme } from "@mui/material";
import { darken } from "@mui/material/styles";
import referral_phone_layer_1 from "../../../../images/account-page/referral-phone-layer-1.png";
import referral_phone_layer_2 from "../../../../images/account-page/referral-phone-layer-2.png";

const ReferralHandBlock = ({
  handleGetReferralCode,
  animationState,
  setAnimationState,
}) => {
  const theme = useTheme();
  const anchorSize = 340;
  const relativeWidthFactor = 0.345;
  const relativeHeightFactor = 0.75;
  const screenContentScaleFactor = 0.9;

  // Define transformation values
  const defaultScale = 1;
  const pressedScale = 0.9;

  const defaultTranslateX = 0;
  const defaultTranslateY = 0;
  const pressedTranslateX = -20; // Total movement on press
  const pressedTranslateY = -20;

  const hoverTranslateX =
    defaultTranslateX + (pressedTranslateX - defaultTranslateX) * 0.4;
  const hoverTranslateY =
    defaultTranslateY + (pressedTranslateY - defaultTranslateY) * 0.6;

  // Function to get the transformation based on the animation state
  const getLayer2Transform = () => {
    switch (animationState) {
      case "default":
        return `translate(${defaultTranslateX}px, ${defaultTranslateY}px) scale(${defaultScale})`;
      case "hovered":
        return `translate(${hoverTranslateX}px, ${hoverTranslateY}px) scale(${defaultScale})`; // No scaling on hover
      case "pressed":
        return `translate(${pressedTranslateX}px, ${pressedTranslateY}px) scale(${pressedScale})`;
      default:
        return `translate(${defaultTranslateX}px, ${defaultTranslateY}px) scale(${defaultScale})`;
    }
  };

  return (
    <Box
      display={"flex"}
      position={"relative"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      {/* Layer 1 Image */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        top={-10}
        sx={{
          pointerEvents: "none",
        }}
      >
        <img
          src={referral_phone_layer_1}
          alt="Referral Phone Layer 1"
          style={{
            width: anchorSize,
            height: anchorSize,
          }}
        />
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        position={"absolute"}
        top={"-10px"}
        paddingTop={"38px"}
        width={anchorSize * relativeWidthFactor}
        height={anchorSize * relativeHeightFactor}
        sx={{
          transform: `scale(${screenContentScaleFactor})`,
        }}
      >
        <Typography
          variant="h6"
          fontSize={"1.1rem"}
          fontWeight="bold"
          color="white"
          textAlign={"center"}
          marginBottom={"20px"}
          width={"100px"}
        >
          Referral Code
        </Typography>
        <Box
          sx={{
            backgroundColor:
              animationState === "pressed"
                ? darken(theme.palette.colors.royalBlue[500], 0.3)
                : animationState === "hovered"
                ? darken(theme.palette.colors.royalBlue[500], 0.15)
                : theme.palette.colors.royalBlue[500],
            color: "white",
            fontWeight: "bold",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            userSelect: "none",
            transition: "background-color 0.2s ease",
          }}
          onClick={handleGetReferralCode}
          onMouseEnter={() => setAnimationState("hovered")}
          onMouseLeave={() => setAnimationState("default")}
          onMouseDown={() => setAnimationState("pressed")}
          onMouseUp={() => setAnimationState("hovered")}
        >
          Copy
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        position={"absolute"}
        top={-10}
        sx={{
          pointerEvents: "none",
          transition: "transform 0.2s ease",
          transform: getLayer2Transform(),
        }}
      >
        <img
          src={referral_phone_layer_2}
          alt="Referral Phone Layer 2"
          style={{
            width: anchorSize,
            height: anchorSize,
          }}
        />
      </Box>
    </Box>
  );
};

export default ReferralHandBlock;

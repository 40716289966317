import { Grid, Box } from "@mui/material";
import { useEffect } from "react";
import { FooterSectionTitle } from "../FooterSectionTitle";
import { InternalLink } from "../footer-links/InternalLink";

export const FooterProfileSection = ({ handleClick, isLoggedIn }) => {
  useEffect(() => {
    // is logged in
  }, [isLoggedIn]);

  return (
    <Grid item xs={12} sm={2} marginBottom={"16px"} marginRight={"32px"}>
      <FooterSectionTitle>Profile</FooterSectionTitle>
      <Box>
        {isLoggedIn ? (
          <InternalLink label="Logout" path="/logout" onClick={handleClick} />
        ) : (
          <InternalLink label="Sign In" path="/auth" onClick={handleClick} />
        )}
        {!isLoggedIn && (
          <InternalLink
            label="Sign Up"
            path="/auth?signup=true"
            onClick={handleClick}
          />
        )}
        {isLoggedIn && (
          <InternalLink
            label="Deactivate"
            path="/deactivate"
            onClick={handleClick}
          />
        )}
      </Box>
    </Grid>
  );
};

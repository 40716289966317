import {
  Box,
  Divider,
  Typography,
  alpha,
  darken,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  BlockTitle,
  BlockWrapper,
  ResourcesLink,
  WhyCell,
  WhyList,
} from "./DeveloperSectionComponents";
import {
  cost,
  performance,
  speed,
} from "../../../../../images/coder-page/index";
import { SectionContainer, SectionHeader } from "../CoderPageSectionComponents";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { setSignInMessage } from "../../../../../features/ui/publicUiStateSlice";

export const DeveloperSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const isLoggedIn =
    userAuthSlice.userAuth?.token !== null &&
    userAuthSlice.userAuth?.token !== "" &&
    userAuthSlice.userAuth?.token !== undefined;

  const isExtraSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleGetApiKeyClicked = () => {
    if (isLoggedIn) {
      navigate("/account?login=true");
    } else {
      dispatch(
        setSignInMessage("You must log in before you can get an API key")
      );
      navigate("/auth");
    }
  };

  const LeftSection = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        paddingLeft={{ xs: "16px", sm: "0px" }}
        position={"relative"}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="center"
          paddingRight={{ xs: "40px", md: "20px" }}
          paddingBottom={"16px"}
        >
          <Box
            display="flex"
            flexDirection={"column"}
            width={"100%"}
            justifyContent={"flex-start"}
          >
            <WhyCell theme={theme} title={"Lower Costs"} img={cost}>
              <Typography
                component="div"
                paddingLeft={"16px"}
                variant="body1"
                color={theme.palette.colors.grayScale[600]}
              >
                <WhyList>
                  <li>
                    80%+ lower costs of multiplayer synchronization services
                  </li>
                  <li>
                    Pay only for what you use at $25/TB for games, $66/TB for
                    enterprise
                  </li>
                  <li>No CCU charges, no surprise costs</li>
                  <li>
                    Elimination of network traffic due to client deterministic
                    architecture further reduces costs
                  </li>
                </WhyList>
              </Typography>
            </WhyCell>
            <WhyCell
              theme={theme}
              title={
                "Higher performance from decentralized, low latency network and client side architecture"
              }
              img={performance}
            >
              <Typography
                component="div"
                paddingLeft={"16px"}
                variant="body1"
                color={theme.palette.colors.grayScale[600]}
              >
                <WhyList>
                  <li>Target 15-30ms latency for most cases</li>
                  <li>
                    Model/View architecture with client deterministic
                    synchronized computation eliminates netcode
                  </li>
                </WhyList>
              </Typography>
            </WhyCell>
            <WhyCell
              theme={theme}
              title={"Faster time to market for games and applications"}
              img={speed}
            >
              <Typography
                component="div"
                paddingLeft={"16px"}
                variant="body1"
                color={theme.palette.colors.grayScale[600]}
              >
                <WhyList>
                  <li>No netcode to write; all development is client side</li>
                  <li>
                    Start debugging multiplayer from the first line of code
                  </li>
                </WhyList>
              </Typography>
            </WhyCell>
          </Box>
        </Box>
      </Box>
    );
  };

  const RightSection = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={{ sx: "100%", sm: "300px" }}
        height={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        position={"relative"}
        marginLeft={{ xs: "0px", sm: "8px" }}
      >
        <ApiKeyBlock />
        <Box height={"8px"} />
        <ResourcesBlock />
      </Box>
    );
  };

  const ApiKeyBlock = () => {
    return (
      <BlockWrapper theme={theme}>
        <BlockTitle theme={theme} title="API Key" />
        <Box
          width="100%"
          sx={{
            background: theme.palette.background.headerBackgroundGradient,
            borderRadius: "4px",
            cursor: "pointer",
            padding: "8px",
            // when hover animate to the theme.palette.colors.purple[500]... wash over from left to right
            "&:hover": {
              background: theme.palette.colors.royalBlue[500],
            },
          }}
          onClick={handleGetApiKeyClicked}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            width={"100%"}
            textAlign={"center"}
            color={theme.palette.colors.grayScale.white}
          >
            {`🔑 Get API Key`}
          </Typography>
        </Box>
      </BlockWrapper>
    );
  };

  const ResourcesBlock = () => {
    return (
      <BlockWrapper theme={theme}>
        <BlockTitle theme={theme} title="Resources" />
        <Divider
          color="primary"
          sx={{
            backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.2),
            width: "100%",
            opacity: 0.6,
            marginTop: "5px",
            marginBottom: "5px",
          }}
        />
        <ResourcesLink
          theme={theme}
          navigate={navigate}
          text="Introduction to Multisynq"
          route="/coder/introduction-to-multisynq"
        />
        <ResourcesLink
          theme={theme}
          navigate={navigate}
          text="DePIN Technical Overview"
          route="/downloads/Multisynq DePIN Structure.pdf"
        />
        <ResourcesLink
          theme={theme}
          navigate={navigate}
          text="Multisynq LitePaper"
          route="/downloads/Multisynq Litepaper.pdf"
        />
      </BlockWrapper>
    );
  };

  return (
    <SectionContainer theme={theme}>
      <SectionHeader
        theme={theme}
        title={"Develop with Multisynq"}
        alignItems={{ xs: "center", sm: "flex-start" }}
      />
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <LeftSection />
        <RightSection />
      </Box>
    </SectionContainer>
  );
};

import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

interface EditKeyPopupLoadingOverlayProps {
  message: string;
}

const EditKeyPopupLoadingOverlay: React.FC<EditKeyPopupLoadingOverlayProps> = ({
  message,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
    >
      <Typography
        variant="h6"
        fontWeight={800}
        color={theme.palette.primary.main}
        sx={{
          marginBottom: 4,
        }}
      >
        {message}
      </Typography>
      <CircularProgress size={60} thickness={4} />
    </Box>
  );
};

export default EditKeyPopupLoadingOverlay;

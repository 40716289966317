import React from "react";
import { Box, lighten, Typography, useTheme } from "@mui/material";
import { openApiKeyPopup } from "../../../../../features/ui/apiKeyPopupSlice";
import { useAppDispatch } from "../../../../../app/store";
import { InstructionCell } from "./InstructionCell";
import { useNavigate } from "react-router-dom";

const AccountAPISection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreateApiKey = () => {
    dispatch(openApiKeyPopup());
  };

  const handleSupportedFrameworksClick = () => {
    navigate("/docs");
  };

  const InstructionsBlock = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height={"100%"}
        justifyContent={{ xs: "center", md: "flex-end" }}
        alignItems={{ xs: "center", md: "flex-end" }}
      >
        <Typography
          variant="h4"
          width={{ xs: "100%", md: "500px" }}
          sx={{
            textAlign: "center",
          }}
        >
          Getting Started
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          justifyContent={"space-evenly"}
          width={"100%"}
          maxWidth={"500px"}
          padding="24px"
          marginTop="16px"
          sx={{
            background: theme.palette.colors.grayScale.white,
            borderRadius: "8px",
          }}
        >
          <InstructionCell
            icon="1"
            title="Create Account"
            subtitle="If you're here, you've already done this... good job!"
          />
          <InstructionCell
            icon="2"
            title="Create an API key"
            subtitle="Choose a name, region, and allowed urls for your API key."
          />
          <InstructionCell
            icon="3"
            title="Start building"
            subtitle={
              <React.Fragment>
                {`Dive into one of our`}
                <Box
                  component="span"
                  sx={{
                    color: theme.palette.colors.royalBlue[500],
                    fontWeight: 800,
                    cursor: "pointer",
                    "&:hover": { color: theme.palette.primary.main },
                  }}
                  onClick={handleSupportedFrameworksClick}
                >
                  {" "}
                  Frameworks
                </Box>
                {` to start building.`}
              </React.Fragment>
            }
          />
        </Box>
      </Box>
    );
  };

  const CreateKeyBlock = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ xs: "flex-start", md: "center" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        width={"100%"}
        height="100%"
        marginBottom={{ xs: "40px", md: "0px" }}
        marginTop={{ xs: "40px", md: "0px" }}
      >
        <Box maxWidth={350}>
          <Typography variant="h1" paddingBottom={"8px"} textAlign={"left"}>
            API Keys
          </Typography>

          <Typography
            variant="h5"
            paddingBottom={"16px"}
            sx={{ opacity: "0.7" }}
          >
            Create Your First API Key
          </Typography>
          <Typography variant="body1" paddingBottom={"20px"}>
            API keys are used to authenticate your requests to the Multisynq
            API.
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width="180px"
            height={"60px"}
            marginLeft={{ xs: "-20px", sm: "0px" }}
            sx={{
              background: theme.palette.background.headerBackgroundGradient,
              borderRadius: "8px",
              cursor: "pointer",
              padding: "8px",
              "&:hover": {
                background: theme.palette.colors.royalBlue[500],
              },
              transform: { xs: "scale(0.8)", sm: "scale(1)" },
            }}
            onClick={handleCreateApiKey}
          >
            <Typography
              variant="body1"
              fontSize={"20px"}
              fontWeight={500}
              width={"100%"}
              textAlign={"center"}
              color={theme.palette.colors.grayScale.white}
            >
              {`🔑 Create Key`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
      padding={{ xs: "20px", sm: "40px", md: "60px" }}
      sx={{
        background: `linear-gradient(to right, ${lighten(
          theme.palette.colors.purple[100],
          0.75
        )}, ${lighten(theme.palette.colors.royalBlue[100], 0.75)})`,
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        width="100%"
        maxWidth={"1000px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"20px"}
      >
        <CreateKeyBlock />
        <InstructionsBlock />
      </Box>
    </Box>
  );
};

export default AccountAPISection;

import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  lighten,
  Typography,
  useTheme,
} from "@mui/material";
import ActionBlock from "./blocks/action-block/ActionBlock";
import { openApiKeyPopup } from "../../../../../features/ui/apiKeyPopupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  listApiKeys,
  selectFilteredApiKeys,
} from "../../../../../features/api-key/apiKeySlice";
import KeyCell from "./blocks/key-cell/KeyCell";

const AccountApiKeySection = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const apiKeysSlice = useAppSelector((state) => state.apiKey);
  const { loading } = apiKeysSlice;
  const filteredApiKeys = useAppSelector(selectFilteredApiKeys);

  useEffect(() => {
    dispatch(listApiKeys());
  }, [dispatch]);

  const handleCreateApiKey = () => {
    dispatch(openApiKeyPopup(null));
  };

  const refreshOnDelete = async () => {
    await dispatch(listApiKeys());
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
      paddingTop={"40px"}
      paddingBottom={"60px"}
      paddingLeft={{ xs: "16px", sm: "24px" }}
      paddingRight={{ xs: "16px", sm: "24px" }}
      sx={{
        background: `linear-gradient(to right, ${lighten(
          theme.palette.colors.purple[100],
          0.75
        )}, ${lighten(theme.palette.colors.royalBlue[100], 0.75)})`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={"1000px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          <Box maxWidth={350}>
            <Typography variant="h1" paddingBottom={"8px"} textAlign={"left"}>
              API Keys
            </Typography>
            <Typography
              variant="h5"
              paddingBottom={"24px"}
              sx={{ opacity: "0.7" }}
            >
              Manage your API keys
            </Typography>
          </Box>
        </Box>
        <ActionBlock />

        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="240px"
            sx={{
              marginTop: "20px",
              backgroundColor: theme.palette.colors.grayScale.white,
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h6"
              fontWeight="800"
              fontSize="1.2rem"
              marginBottom="16px"
            >
              Loading...
            </Typography>
            <CircularProgress
              sx={{
                color: theme.palette.colors.blue[900],
              }}
              thickness={5}
            />
          </Box>
        ) : filteredApiKeys.length > 0 ? (
          filteredApiKeys.map((apiKey) => (
            <KeyCell
              key={apiKey.apiKey}
              apiKey={apiKey}
              refreshOnDelete={refreshOnDelete}
            />
          ))
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="240px"
            marginBottom="16px"
            marginTop="16px"
            sx={{
              backgroundColor: theme.palette.colors.grayScale.white,
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" fontWeight="bold" marginBottom="8px">
              {apiKeysSlice.apiKeys.length === 0
                ? "You Have No API Keys"
                : "No API Keys Found"}
            </Typography>
            <Typography variant="body1" marginBottom="24px">
              {apiKeysSlice.apiKeys.length === 0
                ? "Get started today by creating your first API key."
                : "Try adjusting your filter or create a new API key."}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateApiKey}
            >
              Create
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AccountApiKeySection;

import {
  Box,
  Typography,
  CircularProgress,
  useTheme,
  lighten,
} from "@mui/material";

const LoadingBlock = () => {
  const theme = useTheme();

  return (
    <Box
      position={"relative"}
      width={"100%"}
      height={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      paddingBottom={"250px"}
      sx={{
        background: `linear-gradient(to right, ${lighten(
          theme.palette.colors.purple[100],
          0.75
        )}, ${lighten(theme.palette.colors.royalBlue[100], 0.75)})`,
      }}
    >
      <Typography
        variant="h4"
        paddingBottom={"14px"}
        fontWeight={600}
        color={theme.palette.colors.royalBlue[500]}
      >
        Loading
      </Typography>
      <CircularProgress
        thickness={6}
        sx={{ color: theme.palette.colors.royalBlue[500] }}
      />
    </Box>
  );
};

export default LoadingBlock;

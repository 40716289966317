import React from "react";
import { CellComponentBase } from "../CellComponentBase";
import { ApiTextField } from "./ApiTextField";

interface NameBlockProps {
  name: string;
  setName: (name: string) => void;
}

export const NameBlock: React.FC<NameBlockProps> = ({ name, setName }) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const input = e.target as HTMLInputElement;
      input.blur();
    }
  };

  return (
    <CellComponentBase title="Key Name">
      <ApiTextField
        placeholder="Example: 'My First Key'..."
        value={name}
        onChange={(e) => setName(e.target.value)}
        name="name"
        onKeyDown={handleKeyPress}
      />
    </CellComponentBase>
  );
};

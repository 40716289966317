import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import MaskShape from "./MaskShape";

const ProfileImage = ({
  src,
  theme,
  size,
  showSelected,
  isFeatured,
  firstName = null,
  title = null,
}: {
  src: string;
  theme: Theme;
  size: number;
  showSelected: boolean;
  isFeatured: boolean;
  firstName?: string | null;
  title?: string | null;
}) => {
  const baseSize = 300;
  const scale = size / baseSize / 1.5;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: `${baseSize * scale}px`,
        height: `auto`,
        marginBottom: isFeatured ? `${20 * scale + 15}px` : `${20 * scale}px`,
        marginTop: isFeatured ? `${20 * scale + 10}px` : 0,
        opacity: showSelected ? 1 : 0.5,
        transition: "transform 0.15s",
        transform: isFeatured
          ? "scale(1.5)"
          : showSelected
          ? "scale(1.05)"
          : "scale(0.9)",
      }}
    >
      <Box
        position={"absolute"}
        height={`${baseSize * scale}px`}
        width={`${baseSize * scale}px`}
        sx={{
          backgroundColor: showSelected
            ? theme.palette.colors.blue[500]
            : theme.palette.colors.grayScale.white,
          borderRadius: "50%",
          opacity: showSelected ? 1.0 : 0.3,
        }}
      />
      <Box
        sx={{
          position: "relative",
          height: `${364 * scale}px`,
          width: `${300 * scale}px`,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <MaskShape scale={scale} />
        <Box
          component="img"
          src={src}
          sx={{
            position: "absolute",
            width: `${400 * scale}px`,
            height: "auto",
            objectFit: "cover",
            clipPath: "url(#mask)",
            bottom: `-${4 * scale}px`,
            left: `calc(50% - ${7 * scale}px)`,
            transform: "translateX(-50%)",
          }}
        />
      </Box>
      {firstName !== null && (
        <Typography
          variant={"h5"}
          width={"100%"}
          textAlign={"center"}
          sx={{
            position: "absolute",
            bottom: "-12px",
            left: "-1px",
            color: theme.palette.colors.grayScale.white,
            fontSize: `14px`,
            fontWeight: 900,
          }}
        >
          {firstName}
        </Typography>
      )}
    </Box>
  );
};

export default ProfileImage;

import {
  IGoogleAuthFormData,
  IUser,
  IAuthFormData,
  IUserDetails,
  IUserCredentials,
  IResetPasswordPayload,
  IRegisteredEmail,
} from "../../types/user";
import API from "../api";

// current user
export const loginGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/users/loginGoogleUser", googleAuthFormData);
export const signUpGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/letsgo/signUpGoogleUser", googleAuthFormData);
export const login = (authFormData: IAuthFormData) =>
  API.post("/users/login", authFormData);
export const signUp = (authFormData: IAuthFormData) =>
  API.post("/letsgo/signup", authFormData);
export const validateToken = () => API.get("/users/validateToken");
export const updateUser = (updatedUser: IUser) =>
  API.put("/users/updateUser", updatedUser);
export const registerEmail = (payload: IRegisteredEmail) =>
  API.post("/users/registerEmail", payload);
export const getReferralCode = () => API.get("/users/getReferralCode");

// user details
export const loadUserDetails = (id: string) =>
  API.post("/users/loadUserDetails", id);
export const updateUserDetails = (userDetails: IUserDetails) =>
  API.put("/users/updateUserDetails", userDetails);

// user credentials
export const loadUserCredentials = (id: string) =>
  API.post("/users/loadUserCredentials", id);
export const resendVerificationEmail = (email: string) =>
  API.post("/users/resendVerificationEmail", email);
export const sendResetPasswordEmail = (email: any) =>
  API.post("/users/sendResetPasswordEmail", email);
export const resetPassword = (resetPasswordPayload: IResetPasswordPayload) =>
  API.post("/users/resetPassword", resetPasswordPayload);
export const requestReactivationEmail = (email: any) =>
  API.post("/users/requestReactivationEmail", email);

// user engagement
export const loadUserEngagement = () => API.get("/users/fetchUserEngagement");

// user deletion
export const deleteUser = (userId: string) =>
  API.post("/users/deleteUser", userId);
export const cancelUser = (userId: string) =>
  API.post("/users/cancelUser", userId);

import { Box, SxProps, useTheme } from "@mui/material";

export const CustomIconButton = ({
  icon,
  onClick,
  hideBG,
  sx,
}: {
  icon: React.ReactNode;
  onClick: () => void;
  hideBG?: boolean;
  sx?: SxProps;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        padding: hideBG ? "6px" : "4px",
        height: "32px",
        width: "32px",
        color: theme.palette.colors.blue[900],
        backgroundColor: hideBG
          ? "transparent"
          : theme.palette.colors.grayScale[100],
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.colors.grayScale[100],
          color: theme.palette.colors.blue[500],
          fill: theme.palette.colors.blue[500],
        },
        "&:active": {
          transform: "scale(0.9)",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {icon}
    </Box>
  );
};

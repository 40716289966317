// ResetPasswordView.tsx
import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { useAppDispatch } from "../../../../app/store";
import { sendResetPasswordEmail } from "../../../../features/users/userCredentialsSlice";
import { CustomInput } from "../../../ui-elements/CustomInput";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";

interface ResetPasswordViewProps {
  setView: (view: AuthViews) => void;
}

const RequestResetPasswordView: React.FC<ResetPasswordViewProps> = ({
  setView,
}) => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useAppDispatch();

  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    setLoading(true);
    callReportError(null);
    const response = await dispatch(sendResetPasswordEmail(email));

    setLoading(false);

    if (response.type === "userCredentials/sendResetPasswordEmail/rejected") {
      callReportError(response.payload);
    } else {
      setEmailSent(true);
    }
  };

  const handleSubmitPressed = () => {
    handleSubmit(null);
  };

  return (
    <AuthViewWrapper
      title="Reset Password"
      viewState={AuthViewState.Default}
      currentErrorMessage={""}
      clearErrorMessage={null}
    >
      <Typography
        sx={{
          color: alpha(theme.palette.colors.grayScale.white, 0.7),
          fontSize: "12px",
          textAlign: "center",
          fontWeight: "300",
          paddingBottom: "16px",
          paddingLeft: "8px",
        }}
      >
        {emailSent
          ? "Check your email!  A password reset link has been sent to the email address you provided."
          : "Please enter the email address associated with your account. We will send you a link to reset your password."}
      </Typography>

      <Box
        display={"flex"}
        width={"100%"}
        sx={{ paddingLeft: "16px", paddingRight: "8px" }}
      >
        <CustomInput
          name="email"
          label="Email"
          onChange={handleChange}
          onSubmit={handleSubmitPressed}
          type="email"
        />
      </Box>

      {currentErrorMessage && !loading && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            fontSize: "12px",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "600",
            padding: "8px",
            marginTop: "24px",
          }}
          onClick={() => {
            clearErrorMessage();
          }}
        >
          {currentErrorMessage || "Something went wrong, please try again"}
        </Typography>
      )}
      <CustomButton
        onClick={handleSubmitPressed}
        component={"button"}
        type="submit"
        disabled={loading || emailSent}
        sx={{
          marginTop: "20px",
        }}
      >
        {emailSent ? "Sent!" : "Send Reset Link"}
      </CustomButton>
      <Button
        sx={{
          marginTop: "8px",
          fontSize: "12px",
          textTransform: "none",
          color: alpha(theme.palette.colors.grayScale.white, 0.7),
        }}
        onClick={() => setView(AuthViews.Login)}
      >
        {`< Back to Login`}
      </Button>
      {loading && (
        <Box
          sx={{
            position: "relative",
            top: 20,
          }}
        >
          <CircularProgress thickness={6} />
        </Box>
      )}
    </AuthViewWrapper>
  );
};

export default RequestResetPasswordView;

import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { CustomIconButton } from "../../../../../../ui-elements/CustomIconButton";
import { Delete, Edit } from "../../../../../../../icons";

export const ApiKeyCellActionButtons = ({
  handleDelete,
  handleEdit,
}: {
  handleDelete: () => void;
  handleEdit: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="6px"
      justifyContent="center"
      alignItems="center"
    >
      <CustomIconButton
        icon={<Delete />}
        onClick={handleDelete}
        sx={{
          height: "28px",
          width: "28px",
          color: theme.palette.colors.blue[900],
          fill: theme.palette.colors.blue[900],
          opacity: 0.6,
          backgroundColor: "transparent",
          borderRadius: "8px",

          "&:hover": {
            color: theme.palette.colors.red[500],
            fill: theme.palette.colors.red[500],
            backgroundColor: theme.palette.colors.grayScale[75],
            boxShadow: "none",
            opacity: 1,
          },
        }}
      />
      <CustomIconButton
        icon={<Edit />}
        onClick={handleEdit}
        sx={{
          height: "32px",
          backgroundColor: theme.palette.colors.royalBlue[500],
          color: "white",
          fill: "white",
          borderRadius: "8px",
          paddingLeft: "4px",
          paddingRight: "4px",
          "&:hover": {
            backgroundColor: alpha(theme.palette.colors.royalBlue[500], 0.8),
            fill: "white",
            boxShadow: "none",
          },
        }}
      />
    </Box>
  );
};

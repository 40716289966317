import React, { useEffect, useState } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../../../../../../app/store";
import { KeyFilterService } from "../../../../../../../services/KeyFilterService";

interface ApprovedUrlsDisplayBlockProps {
  urls: string[];
  allowDev: boolean;
}

export const ApprovedUrlsDisplayBlock: React.FC<
  ApprovedUrlsDisplayBlockProps
> = ({ urls, allowDev }) => {
  const [displayUrls, setDisplayUrls] = useState<string[]>([]);
  const theme = useTheme();
  const primaryColor = theme.palette.colors.blue[900];
  const filterString = useAppSelector((state) => state.apiKey.filterString);

  useEffect(() => {
    setDisplayUrls(allowDev ? ["localhost and local network", ...urls] : urls);
  }, [urls, allowDev]);

  const isSingleUrl = displayUrls.length === 1;
  const noUrls = displayUrls.length === 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        flex: 1,
        backgroundColor: theme.palette.colors.grayScale[75],
        borderRadius: "4px",
        paddingTop: isSingleUrl ? "0" : "8px",
        paddingBottom: isSingleUrl ? "0" : "8px",
        width: "100%",
        minHeight: noUrls ? "28px" : "auto",
      }}
    >
      {noUrls ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant="body1"
            fontSize="0.8rem"
            color={theme.palette.colors.blue[900]}
            sx={{ opacity: 0.5, textAlign: "center" }}
          >
            No Approved URLs
          </Typography>
        </Box>
      ) : (
        displayUrls.map((url, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: "4px",
            }}
          >
            <Typography
              variant="body2"
              color={primaryColor}
              paddingLeft="12px"
              paddingRight="8px"
              paddingTop={isSingleUrl ? "8px" : "4px"}
              paddingBottom={isSingleUrl ? "8px" : "4px"}
              sx={{
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontSize: "0.8rem",
                fontWeight: allowDev && index === 0 ? "700" : "600",
                color:
                  allowDev && index === 0
                    ? theme.palette.colors.blue[500]
                    : primaryColor,
              }}
            >
              {KeyFilterService.highlightText(url, filterString, theme)}
            </Typography>
            {!isSingleUrl && index < displayUrls.length - 1 && (
              <Divider
                sx={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  marginLeft: "6px",
                  opacity: 0.5,
                }}
              />
            )}
          </Box>
        ))
      )}
    </Box>
  );
};

// create react component
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  alpha,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  signup,
  signupGoogleUser,
} from "../../../../features/users/userAuthSlice";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import AuthCheckboxBlock from "./blocks/AuthNewsletterBlock";
import {
  IAuthFormData,
  IGoogleResponse,
} from "../../../../types/user/userTypes";
import { CustomInput } from "../../../ui-elements/CustomInput";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { AuthFormWrapper } from "./blocks/AuthFormWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useNavigate } from "react-router-dom";

interface SignupViewProps {
  viewState: AuthViewState;
  setViewState: (state: AuthViewState) => void;
  setView: (view: AuthViews) => void;
}

const SignupView: React.FC<SignupViewProps> = ({
  viewState,
  setViewState,
  setView,
}) => {
  const dispatch = useAppDispatch();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const publicUiSlice = useAppSelector((state) => state.publicUiState);
  const activeEmail = publicUiSlice.activeEmail;
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const initialSignupFormData = {
    email: "",
    password: "",
    confirmPassword: "",
    subscribedToNewsletter: "false",
    acceptedTerms: "false",
    confirmEmail: "",
  } as IAuthFormData;

  const [formData, setFormData] = useState(initialSignupFormData);

  const [formLoadTime, setFormLoadTime] = useState(Date.now());

  useEffect(() => {
    setFormLoadTime(Date.now()); // Set the time when the component mounts
  }, []);

  useEffect(() => {
    const signupParams = new URLSearchParams(window.location.search);
    const signup = signupParams.get("signup");
    if (signup === "true") {
      if (activeEmail !== "") {
        setFormData({ ...formData, email: activeEmail });
      } else {
        navigate("/auth");
      }
    }
  }, [activeEmail]);

  useEffect(() => {
    // console.log("newViewState", viewState);
  }, [publicUiSlice]);

  useEffect(() => {
    // console.log("newViewState", viewState);
  }, [viewState]);

  useEffect(() => {
    // console.log("formData", formData);
  }, [formData]);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    const submitTime = Date.now();
    const timeElapsed = submitTime - formLoadTime;

    if (timeElapsed < 1100) {
      console.log(
        "SignupView.tsx - Submission too quick: Likely bot activity."
      );
      return;
    }

    if (formData.confirmEmail !== "") {
      console.log("SignupView.tsx - triggered");
      return;
    }

    if (formData.acceptedTerms !== "true") {
      callReportError("You must accept the terms and conditions to sign up.");
      return;
    }

    setViewState(AuthViewState.Loading);
    callReportError(null);
    const response = await dispatch(signup(formData));
    setViewState(AuthViewState.Default);
    if (response.type === "userAuth/signup/rejected") {
      callReportError(response.payload);
    } else {
      callReportError(null);
      setView(AuthViews.VerificationPending);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () =>
    setShowPassword((previousShowPassword) => !previousShowPassword);

  const googleSuccess = async (response: IGoogleResponse) => {
    try {
      if (formData.acceptedTerms !== "true") {
        callReportError("You must accept the terms and conditions to sign up.");
        return;
      }

      setViewState(AuthViewState.Loading);
      const googleAuthFormData = {
        token: response.credential,
        acceptedTerms: formData.acceptedTerms,
        subscribedToNewsletter: formData.subscribedToNewsletter,
      };
      const result = await dispatch(signupGoogleUser(googleAuthFormData));
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setViewState(AuthViewState.Default);

      if (result.payload !== null && result.payload !== undefined) {
        if (result.payload.user !== null && result.payload.user !== undefined) {
          setView(AuthViews.VerificationPending);
          return;
        }
      }

      if (result.payload !== "") {
        callReportError(result.payload);
      }
    } catch (error) {
      console.log(error);
      callReportError("Google sign in was unsuccessful");
    }
  };

  const googleError = () => {
    callReportError("Google sign in was unsuccessful");
  };

  const handleSubmitPressed = () => {
    if (formData.acceptedTerms === "true") {
      handleSubmit(null);
    } else {
      callReportError("You must accept the terms and conditions to sign up.");
    }
  };

  return (
    <AuthViewWrapper
      title={"Sign Up"}
      viewState={viewState}
      currentErrorMessage={currentErrorMessage}
      clearErrorMessage={clearErrorMessage}
    >
      <AuthFormWrapper handleSubmit={handleSubmit} viewState={viewState}>
        {userAuthSlice.activeReferralCode &&
        userAuthSlice.activeReferralCode !== "" ? (
          <Typography fontWeight={800} color={theme.palette.colors.purple[200]}>
            Referral Code: Active!
          </Typography>
        ) : null}
        <CustomInput
          name="email"
          label="Email Address"
          autoComplete={"email"}
          onChange={handleChange}
          value={formData.email}
          type="email"
          forceInvisible={viewState === AuthViewState.Loading}
          onSubmit={handleSubmitPressed}
          sx={{ marginBottom: "8px", marginTop: "16px" }}
        />
        <CustomInput
          name="password"
          label="Password"
          autoComplete={"new-password"}
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          handleShowPassword={handleShowPassword}
          forceInvisible={viewState === AuthViewState.Loading}
          onSubmit={handleSubmitPressed}
          sx={{ marginBottom: "8px" }}
        />

        <CustomInput
          name="confirmPassword"
          autoComplete="new-password"
          label="Repeat Password"
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          forceInvisible={viewState === AuthViewState.Loading}
          onSubmit={handleSubmitPressed}
          sx={{ marginBottom: "8px" }}
        />
        <input
          type="text"
          name="confirmEmail"
          style={{ position: "absolute", top: "-9999px", opacity: 0 }}
          value={formData.confirmEmail}
          onChange={handleChange}
          tabIndex={-1}
        />

        <Box
          display={"flex"}
          paddingTop={"16px"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
          width={"100%"}
          sx={{
            visibility:
              viewState === AuthViewState.Loading ? "hidden" : "visible",
          }}
        >
          <AuthCheckboxBlock
            title={"Newsletter"}
            subtitle={"Stay up to date on the latest news and updates."}
            setValue={(value) =>
              setFormData({ ...formData, subscribedToNewsletter: value })
            }
            value={formData.subscribedToNewsletter}
          />
          <AuthCheckboxBlock
            title={"Terms"}
            subtitle={`By signing up, you agree to our Terms & Conditions and Privacy Policy.`}
            setValue={(value) =>
              setFormData({ ...formData, acceptedTerms: value })
            }
            value={formData.acceptedTerms}
          />
          <CustomButton
            onClick={() => handleSubmit}
            component={"button"}
            type="submit"
            disabled={viewState === AuthViewState.Loading}
            sx={{
              marginTop: "16px",
              marginBottom: "8px",
              width: "194px",
            }}
          >
            {viewState === AuthViewState.Loading ? (
              <CircularProgress size={24} />
            ) : (
              <></>
            )}
            {viewState === AuthViewState.Loading ? "" : "Sign Up"}
          </CustomButton>
          <GoogleLogin
            width="194px"
            theme="outline"
            size="large"
            text="signup_with"
            onSuccess={
              googleSuccess as (credentialResponse: CredentialResponse) => void
            }
            onError={googleError}
          />

          <Button
            sx={{
              marginRight: "8px",
              marginLeft: "8px",
              fontWeight: "300",
              fontSize: { xs: "12px", md: "14px" },
              textTransform: "none",
              marginTop: "16px",
              color: alpha(theme.palette.colors.grayScale.white, 0.8),
              "&:hover": {
                color: theme.palette.colors.grayScale.white,
              },
            }}
            onClick={() => setView(AuthViews.Login)}
            disableRipple
          >
            Already have an account? &nbsp;
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: alpha(theme.palette.colors.grayScale.white, 0.7),
              }}
            >
              Sign In
            </Typography>
          </Button>
        </Box>
      </AuthFormWrapper>
    </AuthViewWrapper>
  );
};

export default SignupView;

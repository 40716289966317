// VerificationPendingView.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { resendVerificationEmail } from "../../../../features/users/userCredentialsSlice";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { setEmailValidatedFlow } from "../../../../features/users/userAuthSlice";
import { BackToLoginButton } from "./blocks/BackToLoginButton";

interface VerificationPendingViewProps {
  setView: (view: AuthViews) => void;
}

const VerificationPendingView: React.FC<VerificationPendingViewProps> = ({
  setView,
}) => {
  const theme = useTheme();
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);
  const userCredentials = userCredentialsSlice?.userCredentials;

  const userAuthSlice = useAppSelector((state) => state.userAuth);

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  useEffect(() => {
    if (userAuthSlice.emailValidatedFlow) {
      setView(AuthViews.UserDetails);
      dispatch(setEmailValidatedFlow(false));
    } else if (
      userCredentials?.verified &&
      userCredentials?.verified === true
    ) {
      setView(AuthViews.UserDetails);
    }
  }, []);

  useEffect(() => {
    // if (userCredentials?.verified && userCredentials?.verified === true) {
    //   setView(AuthViews.BetaPending);
    // }
  }, [userCredentials?.verified]);

  const handleResendEmail = async () => {
    setLoading(true);
    const response = await dispatch(
      resendVerificationEmail(userCredentials?.email)
    );

    // wait 3 seconds
    await new Promise((resolve) => setTimeout(resolve, 3000));

    callReportError(null);
    console.log(response.type);
    if (response.type !== "userCredentials/resendVerificationEmail/fulfilled") {
      callReportError(response.payload);
    } else {
      callReportError(response.payload.message);
    }

    setLoading(false);
  };

  const cleanErrorMessage = () => {
    if (currentErrorMessage) {
      try {
        if (currentErrorMessage.startsWith("It can take")) {
          return "";
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  return (
    <AuthViewWrapper
      title="Pending Email Verification"
      viewState={AuthViewState.Default}
      currentErrorMessage={cleanErrorMessage()}
      clearErrorMessage={clearErrorMessage}
    >
      <Typography
        variant="body2"
        color={theme.palette.colors.grayScale.white}
        textAlign={"center"}
        lineHeight={1.6}
        sx={{ mb: 2, mt: 2, opacity: 0.8 }}
      >
        {`We've sent you a verification email. If you don't see it in your inbox within the next few minutes, please check your spam folder. If no email has arrived in 5 minutes, please click the button below to resend the email.`}
      </Typography>
      {loading ? (
        <CircularProgress sx={{ marginTop: "26px" }} />
      ) : (
        <>
          {currentErrorMessage && (
            <Typography
              sx={{
                color: theme.palette.colors.grayScale.white,
                fontSize: "12px",
                textAlign: "center",
                cursor: "pointer",
                fontWeight: "600",
                padding: "8px",
              }}
              onClick={() => {
                clearErrorMessage();
              }}
            >
              {currentErrorMessage || "Something went wrong, please try again"}
            </Typography>
          )}
          <CustomButton
            sx={{
              marginTop: "26px",
            }}
            onClick={handleResendEmail}
            disableRipple
          >
            Resend Email
          </CustomButton>
          <BackToLoginButton setView={setView} />
        </>
      )}
    </AuthViewWrapper>
  );
};

export default VerificationPendingView;

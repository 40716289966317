import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/errorHandler";
import {
  LocalStorageService,
  StorageKeys,
} from "../../services/LocalStorageService";
import { IUserDetails } from "../../types/user";
import { loadUserEngagement } from "./userEngagement";

const initialState = {
  userDetails: LocalStorageService.get(
    StorageKeys.userDetails
  ) as IUserDetails | null,
};

export const loadUserDetails = createAsyncThunk(
  "userDetails/loadUserDetails",
  async (userDetailsId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.loadUserDetails(userDetailsId);
      dispatch(setUserDetails(data));
      LocalStorageService.set(StorageKeys.userDetails, data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "userDetails/updateUserDetails",
  async (userDetails: IUserDetails, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.updateUserDetails(userDetails);

      dispatch(setUserDetails(data));

      LocalStorageService.set(StorageKeys.userDetails, data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearUserDetails = createAsyncThunk(
  "userDetails/clearUserDetails",
  async (_, { dispatch }) => {
    LocalStorageService.remove(StorageKeys.userDetails);
    dispatch(setUserDetails(null));
  }
);

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const { setUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import ActionButton from "./ActionButton";
import { Close, Doc, Expand, Qr, SplitScreen } from "../../../../../icons";
import { useAppSelector } from "../../../../../app/store";
import { styled } from "@mui/material/styles";
import QRCode from "react-qr-code";

const HEIGHT = "32px";

const CustomStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.colors.blue[500],
  width: "160px",
  borderRadius: "8px",
  paddingLeft: "16px",
  paddingRight: "16px",
  height: "32px",
  boxShadow: "none",
  marginTop: "12px",
  "&:hover": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.colors.blue[500],
    color: theme.palette.common.white,
    opacity: 0.6,
    boxShadow: "none",
  },
}));

export const ExamplesActionBar = ({ setIsSplitScreen }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [qrLink, setQrLink] = useState("");

  const { selectedExample, sessionId } = useAppSelector(
    (state) => state.pageManager
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (sessionId) {
      const qRLink =
        selectedExample.exampleUrl +
        `?q=${sessionId}#pw=2xQ7J1trGlnQ-4PfFOI8DA`;
      setQrLink(qRLink);
    }
  }, [sessionId, selectedExample]);

  const handleSplitScreenPressed = () => {
    setIsSplitScreen((prev) => !prev);
  };

  const handleConnectPressed = () => {
    setOpen(true);
  };

  const handleDocsPressed = () => {
    const url = selectedExample?.docsLink;
    if (url !== undefined && url !== "") {
      window.open(url, "_blank");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop="12px"
        marginBottom="12px"
        height={HEIGHT}
        sx={{
          borderRadius: "8px",
          padding: "0 0",
        }}
      >
        <Box flexGrow={1} />
        <Box display="flex" gap="8px" alignItems="center">
          {selectedExample?.hasFullScreen && (
            <ActionButton
              icon={Expand}
              label="Fullscreen"
              onClick={() => {
                window.open(selectedExample?.exampleUrl, "_blank");
              }}
              bgColor={theme.palette.colors.royalBlue[500]}
            />
          )}
          {selectedExample?.docsLink !== "" && (
            <ActionButton
              icon={Doc}
              label="Source"
              onClick={handleDocsPressed}
              bgColor={theme.palette.colors.blue[500]}
            />
          )}
          {!isSmallScreen && !selectedExample?.noSplit && (
            <ActionButton
              icon={SplitScreen}
              label="2 Player"
              onClick={handleSplitScreenPressed}
              bgColor={theme.palette.colors.royalBlue[500]}
            />
          )}
          <ActionButton
            icon={Qr}
            label="Join"
            onClick={handleConnectPressed}
            bgColor={theme.palette.colors.royalBlue[500]}
          />
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px",
            margin: "8px",
            maxWidth: "400px",
          },
        }}
      >
        <DialogContent
          sx={{
            borderRadius: "20px",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={800}
            component="div"
            gutterBottom
          >
            Join Session
          </Typography>
          <Typography
            variant="body1"
            fontSize={"14px"}
            marginBottom={"16px"}
            sx={{
              opacity: 0.8,
            }}
          >
            Scan the QR code to join this session with your phone or copy the
            link and paste it in your browser.
          </Typography>
          <QRCode
            size={256}
            style={{ maxHeight: "200px", maxWidth: "200px", width: "100%" }}
            value={qrLink}
            viewBox={`0 0 256 256`}
          />
          <DialogActions>
            <CustomStyledButton
              onClick={copyToClipboard}
              variant="contained"
              color="primary"
              disabled={copied}
            >
              {copied ? "Copied!" : "Copy Link"}
            </CustomStyledButton>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: "12px",
                right: "8px",
                color: theme.palette.colors.grayScale[500],
                height: "32px",
                width: "32px",
              }}
            >
              <Close />
            </IconButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Box, Button, lighten, Typography, useTheme } from "@mui/material";
import { CustomIconButton } from "../../../../ui-elements/CustomIconButton";
import { Close } from "../../../../../icons";
import { AllowedUrlsBlock } from "./blocks/AllowedUrlsBlock";
import { IApiKey, IApiRegion } from "../../../../../types/api-key/apiKeyTypes";
import { StorageRegionBlock } from "./blocks/DataRegionBlock";
import { NameBlock } from "./blocks/NameBlock";
import EditKeyPopupLoadingOverlay from "./EditKeyPopupLoadingOverlay";

interface EditApiKeyPopupProps {
  open: boolean;
  onClose: () => void;
  apiKey?: IApiKey;
  onSave: (formData: IApiKey, isUpdate: boolean) => Promise<void>;
}

export const EditApiKeyPopup: React.FC<EditApiKeyPopupProps> = ({
  open,
  onClose,
  apiKey,
  onSave,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<IApiKey>({
    userId: "",
    apiKey: "",
    name: "",
    region: {
      location: "",
      label: "",
    },
    allowedUrls: [],
    allowDev: false,
    lastUsed: "",
    created: new Date().toISOString(),
  });

  useEffect(() => {
    if (apiKey !== null && apiKey !== undefined) {
      setFormData(apiKey);
    } else {
      setFormData({
        userId: "",
        apiKey: "",
        name: "",
        region: {
          location: "",
          label: "",
        },
        allowedUrls: [],
        allowDev: false,
        lastUsed: "",
        created: new Date().toISOString(),
      });
    }
  }, [apiKey]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.name.trim() === "") {
      setError("Please enter a name for the API key.");
      return;
    }
    if (formData.allowedUrls.length === 0 && !formData.allowDev) {
      setError(
        "Please add at least one URL or enable Allow local development."
      );
      return;
    }
    if (
      !formData.region.location ||
      formData.region.location === "" ||
      formData.region.location === undefined
    ) {
      setError("Please select a Storage Region for the API key.");
      return;
    }
    setError(null);
    setLoading(true);
    const isUpdate = !!apiKey?.apiKey;
    try {
      await onSave(formData, isUpdate);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.error("Error saving API key:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleClose = () => {
    if (!loading) {
      setError(null);
      onClose();
    }
  };

  if (!open) return null;

  const setNameString = (value: string) => {
    setFormData((prev) => ({ ...prev, name: value }));
  };

  const handleErrorClick = () => {
    setError(null);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          bgcolor: lighten(theme.palette.colors.grayScale[100], 0.3),
          borderRadius: 2,
          boxShadow: 24,
          width: "90%",
          maxWidth: 500,
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {loading && (
          <EditKeyPopupLoadingOverlay
            message={apiKey ? "Saving Key..." : "Creating Key..."}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            p: { xs: 1, sm: 2 },
          }}
        >
          <Box sx={{ width: 48 }} />
          <Typography
            variant="h3"
            fontSize={{ xs: "1rem", sm: "1.45rem" }}
            textAlign="center"
            sx={{ color: theme.palette.primary.main }}
          >
            {apiKey ? "Edit API Key" : "Create API Key"}
          </Typography>
          <CustomIconButton
            icon={<Close />}
            onClick={handleClose}
            sx={{
              alignSelf: "flex-end",
              borderRadius: "50%",
              height: "32px",
              width: "32px",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: theme.palette.colors.grayScale[100],
              },
              color: theme.palette.colors.grayScale[500],
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            bgcolor: "background.paper",
          }}
        >
          {apiKey && (
            <Box
              sx={{
                backgroundColor: theme.palette.colors.grayScale[75],
                borderRadius: "8px",
                padding: "16px",
                marginLeft: { xs: 2, sm: 4 },
                marginRight: { xs: 2, sm: 4 },
                marginTop: { xs: 2, sm: 4 },
                marginBottom: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 900,
                  wordBreak: "break-all",
                }}
              >
                Key
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  wordBreak: "break-all",
                }}
              >
                {apiKey.apiKey}
              </Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                paddingLeft: { xs: 2, sm: 4 },
                paddingRight: { xs: 2, sm: 4 },
                paddingTop: 2,
                paddingBottom: 1,
              }}
            >
              <NameBlock name={formData.name} setName={setNameString} />
              <Box sx={{ height: "16px" }} />
              <AllowedUrlsBlock
                urls={formData.allowedUrls}
                setUrls={(urls) =>
                  setFormData((prev) => ({ ...prev, allowedUrls: urls }))
                }
                setAllowDev={(allowDev) =>
                  setFormData((prev) => ({ ...prev, allowDev }))
                }
                allowDev={formData.allowDev}
              />

              <Box sx={{ height: "16px" }} />
              <StorageRegionBlock
                isEdit={apiKey !== undefined && apiKey !== null}
                region={formData.region}
                setRegion={(region: IApiRegion) =>
                  setFormData((prev) => ({ ...prev, region }))
                }
              />
            </Box>
          </form>
        </Box>
        {error && (
          <Typography
            color={theme.palette.colors.red[400]}
            sx={{
              fontWeight: 600,
              mt: 3,
              mb: 1.5,
              textAlign: "center",
              cursor: "pointer",
              maxWidth: "300px",
              alignSelf: "center",
            }}
            onClick={handleErrorClick}
          >
            {error}
          </Typography>
        )}
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            {apiKey ? "Save Changes" : "Create Key"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

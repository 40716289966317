import { Box, Grid, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  cancelUser,
  deleteUser,
  setManualLogout,
} from "../../../features/users/userAuthSlice";
import { useEffect } from "react";
import { processInternalLinkClicked } from "./footer-links/processInternalLinkClick";
import {
  FooterProfileSection,
  FooterConnectSection,
  FooterInfoSection,
  FooterLogoSection,
  FooterCopyrightSection,
} from "./footer-sections";

const SiteFooter = () => {
  const theme = useTheme();
  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userId = userAuthSlice.userAuth?.user?._id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoggedIn =
    userAuthSlice.userAuth !== null && userAuthSlice.userAuth !== undefined;
  const confirmationPopupSlice = useAppSelector(
    (state) => state.confirmationPopup
  );

  const scrollToTopOfWindow = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: this makes the scroll smooth
    });
  };

  useEffect(() => {
    if (!isLoggedIn) return;

    if (confirmationPopupSlice.onConfirm) {
      const onConfirm = confirmationPopupSlice.onConfirm;
      if (onConfirm.command === "cancelUser") {
        handleCancelConfirmed();
      } else if (onConfirm.command === "deleteUser") {
        handleDeleteUserConfirmed();
      }
    }
  }, [confirmationPopupSlice.onConfirm]);

  const handleClick = processInternalLinkClicked(dispatch, navigate);

  const handleCancelConfirmed = () => {
    dispatch(setManualLogout(true));
    dispatch(cancelUser(userId));
  };

  const handleDeleteUserConfirmed = () => {
    dispatch(setManualLogout(true));
    dispatch(deleteUser(userId));
  };

  return (
    <Box
      component="footer"
      position={"relative"}
      bottom={0}
      sx={{
        py: 2,
        px: 2,
        backgroundColor: theme.palette.primary.main,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        paddingTop="8px"
        paddingLeft={"8px"}
        paddingRight={"8px"}
      >
        <FooterLogoSection scrollToTopOfWindow={scrollToTopOfWindow} />
        <FooterInfoSection handleClick={handleClick} />
        <FooterConnectSection handleClick={handleClick} />
        <FooterProfileSection
          handleClick={handleClick}
          isLoggedIn={isLoggedIn}
        />
      </Grid>
      <Divider
        sx={{
          my: 2,
          borderColor: theme.palette.background.divider,
          opacity: 0.3,
        }}
      />
      <FooterCopyrightSection theme={theme} />
    </Box>
  );
};

export default SiteFooter;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import newWayMov from "../../../../../videos/newWay.mov";
import newWayWebm from "../../../../../videos/newWay.webm";
import newWayPortraitMov from "../../../../../videos/newWay-portrait.mov";
import newWayPortraitWebm from "../../../../../videos/newWay-portrait.webm";
import { getVideoSrc } from "../../../../helpers/VideoSource";

const NewWaySection: React.FC = () => {
  const theme = useTheme();
  const [animate, setAnimate] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoSource, setVideoSource] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const ref = useRef(null);
  const isPortrait = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentTime, setCurrentTime] = useState(0);

  const updateVideoSource = () => {
    const source = isPortrait
      ? getVideoSrc(newWayPortraitMov, newWayPortraitMov, window.navigator)
      : getVideoSrc(newWayMov, newWayWebm, window.navigator);
    setVideoSource(source);
  };

  useEffect(() => {
    updateVideoSource();
  }, [isPortrait]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadeddata", handleLoadedData);
      videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        videoRef.current?.removeEventListener("loadeddata", handleLoadedData);
        videoRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [videoSource]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!hasAnimated && entry.isIntersecting) {
          setAnimate(true);
          setHasAnimated(true);
        }
      },
      {
        threshold: 0.2,
        rootMargin: "0px",
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasAnimated]);

  const handleLoadedData = () => {
    setIsLoading(false);
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  theme.components = {
    MuiCssBaseline: {
      styleOverrides: `
        @keyframes slideFadeIn {
          from {
            transform: translateY(40px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .animate-on-scroll {
          opacity: 0;
          animation-fill-mode: forwards;
          animation-duration: 0.4s;
          animation-name: slideFadeIn;
        }
      `,
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection={"column"}
      alignItems="center"
      width="100%"
      paddingTop={"32px"}
      paddingBottom={"32px"}
      paddingLeft={{ xs: "32px", md: "32px" }}
      paddingRight={{ xs: "32px", md: "32px" }}
      sx={{
        position: "relative",
        backgroundColor: `rgba(0, 43, 216, 1.0)`,
        overflow: "hidden",
      }}
    >
      <Box
        ref={ref}
        display={"flex"}
        flexDirection={"column"}
        marginBottom={isPortrait ? "24px" : "0px"}
        paddingLeft={{ xs: "32px", md: "0px" }}
        paddingRight={{ xs: "32px", md: "0px" }}
        sx={{
          animation: animate ? "slideFadeIn 1.0s forwards" : "none",
          width: "100%",
          maxWidth: "500px",
          opacity: 0,
        }}
      >
        <Typography
          variant="h3"
          paddingTop={"32px"}
          textAlign={"center"}
          sx={{ color: theme.palette.colors.grayScale.white }}
          gutterBottom
        >
          {`A New Way To Multiplay`}
        </Typography>
        <Typography
          variant="h6"
          lineHeight={"1.4"}
          textAlign={"center"}
          sx={{ color: theme.palette.colors.grayScale.white, opacity: 0.8 }}
        >
          {`Multi-user computing moves from the monolithic server to a shared virtual machine.`}
        </Typography>
      </Box>
      {isLoading && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            alignItems="center"
            height={`600px`}
            width="100%"
            position="absolute"
            top="0"
            left="0"
            zIndex="1"
            bgcolor="rgba(0, 0, 0, 0.5)"
          >
            <CircularProgress
              thickness={6}
              sx={{
                opacity: 0.8,
                color: theme.palette.colors.grayScale.white,
                marginBottom: 4,
              }}
            />
            <Typography
              variant="h6"
              sx={{ ml: 2, color: "white", opacity: 0.8 }}
            >
              Loading...
            </Typography>
          </Box>
          <Box height={`300px`} width="100%" />
        </>
      )}
      <video
        ref={videoRef}
        src={videoSource}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",
          maxWidth: "800px",
          height: "auto",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};

export default NewWaySection;

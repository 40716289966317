import React, { useState, useEffect } from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { useTheme } from "@mui/material";
import { AuthViewState, AuthViews } from "./ViewContentHelper";
import { updateUserDetails } from "../../../../features/users/userDetailsSlice";
import { IUserDetails } from "../../../../types/user";
import { CustomInput } from "../../../ui-elements/CustomInput";
import { AuthViewWrapper } from "./blocks/AuthViewWrapper";
import { AuthFormWrapper } from "./blocks/AuthFormWrapper";
import AuthCheckboxBlock from "./blocks/AuthNewsletterBlock";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useNavigate } from "react-router-dom";
import { BackToLoginButton } from "./blocks/BackToLoginButton";

interface UserDetailsViewProps {
  viewState: AuthViewState;
  setViewState: (state: AuthViewState) => void;
  setView: (view: AuthViews) => void;
}

const UserDetailsView: React.FC<UserDetailsViewProps> = ({
  viewState,
  setViewState,
  setView,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const existingUserDetails = useAppSelector(
    (state) => state.userDetails.userDetails
  );

  const initialUserDetailsFormData = {
    firstName: existingUserDetails?.firstName || "",
    lastName: existingUserDetails?.lastName || "",
    isCoder: true,
    isNoder: true,
    age: "",
  } as IUserDetails;

  const [formData, setFormData] = useState(initialUserDetailsFormData);

  useEffect(() => {
    if (existingUserDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...existingUserDetails,
      }));
    }
  }, [existingUserDetails]);

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    console.log("UserDetailsView.tsx: ", formData);
    if (
      formData.age !== null &&
      formData.age !== "" &&
      formData.age !== undefined
    ) {
      console.log("UserDetailsView.tsx - triggered");
      return;
    }

    if (!formData.firstName || !formData.lastName) {
      setCurrentErrorMessage("Please fill out all fields");
      return;
    }

    setViewState(AuthViewState.Loading);
    try {
      const onboardedFormData = { ...formData, onboarded: true };
      const response = await dispatch(updateUserDetails(onboardedFormData));
      if (response.type === "userDetails/updateUserDetails/rejected") {
        setCurrentErrorMessage(response.payload);
        setViewState(AuthViewState.Default);
      } else {
        setCurrentErrorMessage(null);
        navigate("/account");
      }
    } catch (error) {
      setCurrentErrorMessage("An error occurred, please try again.");
      setViewState(AuthViewState.Default);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitPressed = () => {
    handleSubmit(null);
  };

  return (
    <AuthViewWrapper
      viewState={viewState}
      title={"More Info"}
      clearErrorMessage={clearErrorMessage}
      currentErrorMessage={currentErrorMessage}
    >
      <AuthFormWrapper handleSubmit={handleSubmit} viewState={viewState}>
        <Typography
          variant="body1"
          textAlign={"center"}
          color={theme.palette.colors.grayScale.white}
          fontWeight={600}
          sx={{ marginBottom: "24px" }}
        >
          We need a little more information to get you started.
        </Typography>

        {viewState !== AuthViewState.Loading && (
          <Grid container spacing={"8px"} paddingBottom={"24px"}>
            <Grid item xs={12}>
              <CustomInput
                name="firstName"
                value={formData.firstName}
                placeholder="First Name"
                onChange={handleChange}
                onSubmit={handleSubmitPressed}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                placeholder="Last Name"
                onChange={handleChange}
                onSubmit={handleSubmitPressed}
              />
              <input
                type="text"
                name="age"
                style={{ display: "none" }}
                value={formData.age ?? ""}
                onChange={handleChange}
                tabIndex={-1} // Avoid navigation to this input
              />
            </Grid>
          </Grid>
        )}

        <AuthCheckboxBlock
          title={"Synqers"}
          subtitle={"I wanna be a Synqer... Let's get paid!"}
          setValue={(value) =>
            setFormData({ ...formData, isNoder: value === "true" })
          }
          value={formData.isNoder.toString()}
        />
        <AuthCheckboxBlock
          title={"Coders"}
          subtitle={
            "I'm a developer and want to be a Coder. Let's get stuff made!"
          }
          setValue={(value) =>
            setFormData({ ...formData, isCoder: value === "true" })
          }
          value={formData.isCoder.toString()}
        />

        <CustomButton
          component={"button"}
          type="submit"
          onClick={() => handleSubmit}
          disabled={viewState === AuthViewState.Loading}
          sx={{
            marginTop: "32px",
            marginBottom: "16px",

            width: "194px",
          }}
        >
          {viewState === AuthViewState.Loading ? (
            <CircularProgress size={24} />
          ) : (
            <></>
          )}
          {viewState === AuthViewState.Loading ? "" : "Confirm"}
        </CustomButton>
        <BackToLoginButton setView={setView} />
      </AuthFormWrapper>
    </AuthViewWrapper>
  );
};

export default UserDetailsView;

import { useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Papa from "papaparse";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { TeamMenuHeader } from "./blocks/TeamMenuHeader";
import MenuGrid from "./blocks/MenuGrid";
import ProfileBlock from "./blocks/profile-header/ProfileBlock";

export enum TeamMenuTab {
  TEAM = "team",
  ADVISORS = "advisors",
}

function getHourTimestamp() {
  const now = new Date();
  // Create a new Date object with the current year, month, day, hour and zeroed out minutes, seconds, and milliseconds
  const hourTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 0, 0, 0);

  return hourTimestamp.getTime();
}

const TeamPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  const [team, setTeam] = useState([]);
  const [advisors, setAdvisors] = useState([]);

  const [activeTab, setActiveTab] = useState(TeamMenuTab.TEAM);
  const [activeTeamMember, setActiveTeamMember] = useState(0);
  const [activeAdvisor, setActiveAdvisor] = useState(0);

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, [dispatch, showPageWrapper]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      Papa.parse(await response.text(), {
        header: true,
        complete: (results) => {
          const transformedData = transformData(results.data);
          const teamData = transformedData.filter(
            (member) => member.type === "Team"
          );
          const advisorData = transformedData.filter(
            (member) => member.type === "Advisor"
          );
          setTeam(teamData);
          setAdvisors(advisorData);
        },
      });
    } catch (error) {
      console.error("Error fetching or parsing CSV file:", error);
    }
  };

  useEffect(() => {
    fetchData(`/teamData.csv?t=${getHourTimestamp()}`);
  }, []);

  const transformData = (data) => {
    return data.map((item) => ({
      firstName: item["firstName"],
      middleInitial: item["middleInitial"],
      lastName: item["lastName"],
      title: item["title"],
      fullBio: item["fullBio"],
      shortBio: item["shortBio"],
      image: item["image"],
      type: item["type"],
      linkedInUrl: item["linkedInUrl"] ?? null,
      xUrl: item["xUrl"] ?? null,
      sortOrder: item["sortOrder"] ?? -1,
    }));
  };

  const baseSize = 150;
  const providedSize = 150;
  const scale = providedSize / baseSize;

  const activeMember =
    activeTab === TeamMenuTab.TEAM
      ? team[activeTeamMember]
      : advisors[activeAdvisor];
  const bioHeight = isXs ? "120px" : isSm ? "100px" : "80px";

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      right={0}
      left={0}
      top={0}
      bottom={0}
      marginTop={`-${70 * scale}px`}
      paddingTop={`${80 * scale}px`}
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <TeamMenuHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        <ProfileBlock member={activeMember} bioHeight={bioHeight} />
        <MenuGrid
          activeTab={activeTab}
          team={team.sort((a, b) => a.sortOrder - b.sortOrder)}
          advisors={advisors}
          setActiveTeamMember={setActiveTeamMember}
          setActiveAdvisor={setActiveAdvisor}
          activeTeamMember={activeTeamMember}
          activeAdvisor={activeAdvisor}
        />
      </Box>
    </Box>
  );
};

export default TeamPage;

import { Box, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { ExamplesThumbnails } from "./blocks/ExamplesThumbnails";
import { ExampleDetails } from "./blocks/ExampleDetails";
import { ExampleExperience } from "./blocks/ExampleExperience";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  fetchExamples,
  setSelectedExample,
  setSessionId,
  setTotalPages,
} from "../../../features/ui/pageManagerStateSlice";
import generateRandomString from "../../../utility/sessionGenerator";
import { useLocation } from "react-router-dom";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import UtilityService from "../../../utility/UtilityService";

// PageWrapper component for layout
const PageWrapper = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      marginTop="-70px"
      paddingTop="80px"
      sx={{ background: theme.palette.background.headerBackgroundGradient }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
};

// Header component for title and description
const Header = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ md: "center", lg: "flex-start" }}
      justifyContent="center"
      width={{ xs: "100%", md: "350px", lg: "400px" }}
      padding="40px"
    >
      <Typography
        variant="h4"
        textAlign="center"
        color={theme.palette.colors.blue[200]}
      >
        Examples
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        fontSize="16px"
        color={alpha(theme.palette.colors.grayScale.white, 0.7)}
        paddingBottom="20px"
      >
        Experience the magic! Explore some of the amazing demos that have been
        built using the Multisynq Network.
      </Typography>
    </Box>
  );
};

// ExamplesCardWrapper with forwarded ref for better reusability
export const ExamplesCardWrapper = forwardRef(
  ({ children }: { children: React.ReactNode }, ref) => {
    const theme = useTheme();
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="20px"
        bgcolor={theme.palette.background.paper}
      >
        <Box
          ref={ref}
          maxWidth="900px"
          width="100%"
          display="flex"
          flexDirection="column"
          padding="20px"
          bgcolor={alpha(theme.palette.colors.grayScale.black, 0.05)}
          borderRadius="16px"
        >
          {children}
        </Box>
      </Box>
    );
  }
);

const ExamplesPage = ({ showPageWrapper }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [isSplitScreen, setIsSplitScreen] = useState(false);
  const [originalHeight, setOriginalHeight] = useState<number | null>(null);

  const { filteredExamples, totalPages, selectedExample } = useAppSelector(
    (state) => state.pageManager
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const exampleCardWrapperRef = useRef<HTMLDivElement>(null);

  const updateDefaultSplitScreenState = (
    selectedExample,
    isSmallScreen,
    setIsSplitScreen
  ) => {
    const isScrollSync = selectedExample?.title === "ScrollSync";
    const isPloma = selectedExample?.title === "Ploma";
    const isPlomaOrScrollSync = isScrollSync || isPloma;
    const shouldShowSplitScreen = isPlomaOrScrollSync && !isSmallScreen;
    setIsSplitScreen(shouldShowSplitScreen);
  };

  useEffect(() => {
    updateDefaultSplitScreenState(
      selectedExample,
      isSmallScreen,
      setIsSplitScreen
    );
  }, [selectedExample, isSmallScreen]);

  // Handle URL hash and selected example
  useEffect(() => {
    const currentHash = UtilityService.getStringFromLocation(location.hash);

    if (currentHash) {
      const example = filteredExamples.find((example) =>
        example.title.includes(currentHash)
      );

      if (example) {
        dispatch(setSelectedExample(example));
      }
    }
  }, [location.hash, filteredExamples, dispatch]);

  // Set session ID and fetch examples on component mount
  useLayoutEffect(() => {
    dispatch(setSessionId(generateRandomString(9)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
    dispatch(fetchExamples());
  }, [showPageWrapper, dispatch]);

  // Calculate total pages and handle resize events
  useEffect(() => {
    calculateTotalPages();
    const handleResize = () => {
      calculateTotalPages();
      if (isSmallScreen) setIsSplitScreen(false);
      updateDefaultSplitScreenState(
        selectedExample,
        isSmallScreen,
        setIsSplitScreen
      );
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredExamples, isSmallScreen]);

  // Function to calculate total pages based on available width and number of examples
  const calculateTotalPages = () => {
    if (exampleCardWrapperRef.current) {
      const offsetWidth = Math.min(
        Math.max(exampleCardWrapperRef.current.offsetWidth, 300),
        900
      );
      const totalExamples = filteredExamples.length;
      const exampleThumbnailWidth = 200;
      const currentPages = Math.ceil(
        totalExamples / Math.floor(offsetWidth / exampleThumbnailWidth)
      );
      const pages = Math.max(
        Math.min(currentPages, filteredExamples.length),
        1
      );

      if (pages !== totalPages) {
        dispatch(setTotalPages(pages));
      }
    }
  };

  const handleThumbnailClick = (event, example) => {
    event.preventDefault();
    const randomString = generateRandomString(9);
    dispatch(setSessionId(randomString));
    dispatch(setSelectedExample(example));
    if (example.externalURL) {
      window.open(
        `${example.externalURL}?q=${randomString}#pw=2xQ7J1trGlnQ-4PfFOI8DA`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <PageWrapper>
      <ExamplesCardWrapper ref={exampleCardWrapperRef}>
        <ExamplesThumbnails handleThumbnailClick={handleThumbnailClick} />
        <Box display="flex" flexDirection="row" width="100%">
          <ExampleExperience
            isSplitScreen={isSplitScreen}
            originalHeight={originalHeight}
            setOriginalHeight={setOriginalHeight}
          />
          {isSplitScreen && !isSmallScreen && (
            <>
              <Box width="16px" />
              <ExampleExperience
                isSplitScreen={isSplitScreen}
                originalHeight={originalHeight}
                setOriginalHeight={setOriginalHeight}
              />
            </>
          )}
        </Box>
        <ExampleDetails setIsSplitScreen={setIsSplitScreen} />
      </ExamplesCardWrapper>
    </PageWrapper>
  );
};

export default ExamplesPage;

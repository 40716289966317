import { Box, useTheme } from "@mui/material";
import DOMPurify from "dompurify";

const HtmlScrubberService = ({ html }: { html: string }) => {
  const theme = useTheme();
  const safeHtml = DOMPurify.sanitize(html);

  return (
    <Box
      sx={{
        color: theme.palette.common.white,
        backgroundColor: "transparent",
        "& p": {
          marginBottom: theme.spacing(1),
          padding: "0 0 8px",
          fontSize: "1rem",
          opacity: 0.8,
        },
        "& h1, & h2": {
          marginBottom: theme.spacing(0.5),
          marginTop: theme.spacing(2),
        },
      }}
      dangerouslySetInnerHTML={{ __html: safeHtml }}
    />
  );
};

export default HtmlScrubberService;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  DialogContentText,
  DialogActions,
  Button,
  useTheme,
} from "@mui/material";
import { IApiKey } from "../../../../../../../types/api-key/apiKeyTypes";

interface IConfirmDeletePopupProps {
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: (open: boolean) => void;
  deleting: boolean;
  apiKey: IApiKey;
  confirmDelete: () => void;
}

export const ConfirmDeletePopup: React.FC<IConfirmDeletePopupProps> = ({
  deleteDialogOpen,
  setDeleteDialogOpen,
  deleting,
  apiKey,
  confirmDelete,
}: IConfirmDeletePopupProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
          padding: "12px",
          alignItems: "center",
          maxWidth: "400px",
        },
        "& .MuiDialogTitle-root": {
          color: theme.palette.colors.blue[900],
        },
        "& .MuiDialogContent-root": {
          color: theme.palette.colors.blue[900],
        },
        "& .MuiDialogActions-root": {
          color: theme.palette.colors.blue[900],
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "1.2rem",
          fontWeight: "700",
          color: deleting
            ? theme.palette.colors.red[500]
            : theme.palette.colors.blue[900],
        }}
      >
        {deleting ? "Deleting Key..." : "Delete Key?"}
      </DialogTitle>
      <DialogContent>
        {deleting ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress
              sx={{
                color: theme.palette.colors.red[500],
              }}
              thickness={4}
            />
          </Box>
        ) : (
          <DialogContentText
            sx={{
              fontSize: "0.9rem",
              fontWeight: "400",
              color: theme.palette.colors.blue[900],
              textAlign: "center",
            }}
          >
            {` Are you sure you want to delete your `}
            <strong style={{ color: theme.palette.colors.red[500] }}>
              {apiKey?.name}
            </strong>
            {` API key?`}
          </DialogContentText>
        )}
      </DialogContent>
      {!deleting && (
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            sx={{
              color: theme.palette.colors.blue[900],
              fontSize: "0.9rem",
              borderColor: theme.palette.colors.blue[900],
              backgroundColor: theme.palette.colors.grayScale[100],
              "&:hover": {
                backgroundColor: theme.palette.colors.grayScale[200],
                boxShadow: "none",
              },
              boxShadow: "none",
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            sx={{
              backgroundColor: theme.palette.colors.red[500],
              fontSize: "0.9rem",
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.colors.red[600],
                boxShadow: "none",
              },
              boxShadow: "none",
            }}
            variant="contained"
            autoFocus
            disableFocusRipple
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDeletePopup;

import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { logout, setManualLogout } from "../../../features/users/userAuthSlice";
import { ProfileButton } from "../nav-menu/ProfileButton";
import { useNavigate } from "react-router-dom";

export const AuthSection = ({ handleMenuClick, theme }) => {
  const userAuth = useAppSelector((state) => state.userAuth);

  const isLoggedIn =
    userAuth.userAuth?.token !== null &&
    userAuth.userAuth?.token !== undefined &&
    userAuth.userAuth?.token !== "";
  return isLoggedIn ? (
    <AccountSection handleMenuClick={handleMenuClick} theme={theme} />
  ) : (
    <LoginSection handleMenuClick={handleMenuClick} theme={theme} />
  );
};

const LoginSection = ({ handleMenuClick, theme }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography
        fontSize={"16px"}
        fontWeight={700}
        textAlign={"center"}
        sx={{
          color: theme.palette.colors.royalBlue[900],
          marginTop: "10px",
        }}
      >
        Announcement Phase is here 🚀
      </Typography>
      <Typography
        fontSize={"14px"}
        fontWeight={500}
        textAlign={"center"}
        sx={{
          color: theme.palette.colors.royalBlue[900],
          marginBottom: "16px",
          opacity: 0.7,
        }}
      >
        Sign up today!
      </Typography>

      <Button
        variant="contained"
        sx={{
          fontSize: "14px",
          width: "200px",
          height: "30px",
          backgroundColor: theme.palette.colors.royalBlue[500],
          boxShadow: "none",
          borderRadius: "4px",
          "&:hover": {
            boxShadow: "none",
          },
        }}
        onClick={() => handleMenuClick("signup")}
      >
        Let's gooo!
      </Button>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"4px"}
        marginBottom={"20px"}
      >
        <Typography
          fontSize={"12px"}
          fontWeight={400}
          textAlign={"center"}
          sx={{
            color: theme.palette.colors.royalBlue[900],
          }}
        >
          Already have an account?
        </Typography>
        <Button
          variant="text"
          sx={{
            fontSize: "12px",
            color: theme.palette.colors.royalBlue[500],
            paddingLeft: "0px",
            marginLeft: "-4px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "transparent",
              color: theme.palette.colors.royalBlue[900],
            },
          }}
          onClick={() => handleMenuClick("login")}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export const AccountSection = ({ handleMenuClick, theme }) => {
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firstName = userDetailsSlice.userDetails?.firstName ?? "";
  const firstNameString = firstName
    ? `Welcome back, ${firstName}! 🚀`
    : `Welcome back! 🚀`;

  const processLogout = () => {
    // close drawer
    handleMenuClick(null);
    dispatch(setManualLogout(true));
    dispatch(logout());
    navigate("/");
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography
        fontSize={"16px"}
        fontWeight={700}
        textAlign={"center"}
        sx={{
          color: theme.palette.colors.royalBlue[900],
          marginTop: "10px",
          marginBottom: "16px",
        }}
      >
        {firstNameString}
      </Typography>
      <ProfileButton handleMenuClickOnMobile={handleMenuClick} />
      <Button
        variant="text"
        sx={{
          fontSize: "12px",
          color: theme.palette.text.primary,
          boxShadow: "none",
          marginTop: "2px",
          marginBottom: "20px",
          opacity: 0.5,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "transparent",
            color: theme.palette.colors.royalBlue[900],
            opacity: 0.6,
          },
        }}
        onClick={() => processLogout()}
      >
        Logout
      </Button>
    </Box>
  );
};

import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import referral_arrow from "../../../../../images/referral-arrow.svg";

interface DataBlockProps {
  title: string;
  value: string;
}

export const DataBlock: React.FC<DataBlockProps> = ({ title, value }) => {
  const theme = useTheme();
  const isReferrals = title === "Referrals";
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const frontTextStyle = {
    fontWeight: 900,
    fontSize: { xs: "40px", sm: "24px" },
    color: theme.palette.colors.grayScale.white,
    letterSpacing: "0.1em",
    WebkitTextStroke: "8px rgba(255, 255, 255, 0.2)",
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        margin: "28px",
        paddingLeft: "8px",
        paddingRight: "8px",
      }}
    >
      {value.split("").map((char, index) => (
        <Typography key={index} sx={frontTextStyle} component="span">
          {char}
        </Typography>
      ))}
      <Typography
        sx={{
          fontSize: { xs: 16, sm: 14 },
          fontFamily: "Poppins",
          fontWeight: 800,
          opacity: 0.8,
        }}
        gutterBottom
      >
        {title.toUpperCase()}
      </Typography>
      {/* {isReferrals && !isXs && (
        <Box
          sx={{
            position: "relative",
            top: 52,
            left: 10,
            width: "50px",
            opacity: 1,
            zIndex: 1,
          }}
        >
          <img
            src={referral_arrow}
            alt={"referral_arrow"}
            style={{
              width: "100%",
              height: "auto",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-10%, -60%)",
              fill: theme.palette.colors.blue[200],
            }}
          />
        </Box>
      )} */}
    </Box>
  );
};

export default DataBlock;

import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AuthViewState,
  AuthViews,
  componentForViewAndState,
} from "./auth-views/ViewContentHelper";
import logo_white_long from "../../../images/logo-white.png";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import {
  logout,
  setActiveReferalCode,
  setEmailValidatedFlow,
  setManualLogout,
  setReactivationToken,
} from "../../../features/users/userAuthSlice";
import { PageProps } from "../../page-manager/PageManager";
import { LocalStorageService } from "../../../services/LocalStorageService";

const Auth = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const theme = useTheme();
  const userAuth = useAppSelector((state) => state.userAuth);
  const [currentView, setCurrentView] = useState(AuthViews.Login);
  const [prevView, setPrevView] = useState(AuthViews.Login);
  const [currentViewState, setCurrentViewState] = useState(
    AuthViewState.Default
  );
  const [token, setToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(location.search);
      const referralCode = urlParams.get("referral");
      if (referralCode) {
        const userLoggedIn =
          userAuth.userAuth?.token !== null &&
          userAuth.userAuth?.token !== undefined;
        if (userLoggedIn) {
          console.log("User is logged in and has referral code");
          setView(AuthViews.ReferralPreview);
          return;
        }
      }

      const codedEmail = urlParams.get("emailVerified");
      if (codedEmail) {
        const email = decodeURIComponent(codedEmail);
        if (email !== null && email !== undefined && email !== "") {
          const creds = LocalStorageService.get("userCredentials");
          const currentEmail = creds?.email;
          if (
            currentEmail !== null &&
            currentEmail !== undefined &&
            currentEmail !== "" &&
            currentEmail !== email
          ) {
            dispatch(setManualLogout(true));
            dispatch(logout());
            setView(AuthViews.Login);
            dispatch(setEmailValidatedFlow(true));
            return;
          } else {
            dispatch(setEmailValidatedFlow(true));
          }
        }
      } else {
        dispatch(setEmailValidatedFlow(false));
      }

      const token = urlParams.get("token");
      if (token) {
        setToken(token);
        setView(AuthViews.ResetPassword);
      }

      const signup = urlParams.get("signup");
      if (signup) {
        setView(AuthViews.Signup);
      }

      // get params signup and properties email and messaage
      const login = urlParams.get("login");
      if (login) {
        setView(AuthViews.Login);
      }

      if (referralCode) {
        dispatch(setActiveReferalCode(referralCode));
        setView(AuthViews.Signup);
      }

      const reactivationToken = urlParams.get("reactivate-token");
      dispatch(setReactivationToken(reactivationToken));
    } catch (error) {
      console.error("Failed to parse token from URL", error);
    }
  }, [location]);

  const setView = (newView) => {
    setPrevView(currentView);
    setCurrentView(newView);
  };

  const setViewState = (newState) => {
    setCurrentViewState(newState);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: theme.palette.background.headerBackgroundGradient,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          alignItems: "center",
          maxWidth: "400px",
          width: "100%",
          padding: { xs: "0px", sm: "24px" },
          top: 0,
          bottom: 0,
        }}
      >
        <Box
          display={"flex"}
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          margin={"24px"}
        >
          <img
            src={logo_white_long}
            alt="Logo"
            style={{
              height: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/coder");
            }}
          />
        </Box>
        {componentForViewAndState({
          currentView,
          viewState: currentViewState,
          setViewState,
          setView,
          prevView,
          token,
          setToken,
        })}
        <Box height={"100%"} />
      </Box>
    </Box>
  );
};

export default Auth;

import * as React from "react";
import TextField from "@mui/material/TextField";
import { alpha, useTheme } from "@mui/material";

export interface CustomInputProps {
  name: string;
  value?: string;
  setValue?: (value: string) => void;
  placeholder?: string;
  onSubmit?: () => void;
  autoComplete?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  forceInvisible?: boolean;
  handleShowPassword?: () => void;
  colorOverride?: string;
  sx?: React.CSSProperties;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  name,
  value = "",
  setValue = null,
  placeholder = "Enter text...",
  onSubmit,
  autoComplete,
  label,
  onChange,
  type = "text",
  forceInvisible = false,
  handleShowPassword = null,
  colorOverride = null,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const borderRadius = "6px";
  const borderThickness = "1.5px";
  const primaryColor = colorOverride
    ? colorOverride
    : theme.palette.colors.grayScale.white;
  const height = "48px";

  const [inputValue, setInputValue] = React.useState(value);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  React.useEffect(() => {
    if (name === "email") {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (setValue) {
      setValue(event.target.value);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
      if (inputRef.current) {
        inputRef.current.blur();
      }
      event.preventDefault(); // Prevent the default action of Enter key
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      placeholder={placeholder}
      value={inputValue}
      onKeyDown={handleKeyPress}
      onChange={handleInputChange}
      variant="outlined"
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      autoComplete={autoComplete}
      label={label}
      type={type}
      inputProps={{
        type,
      }}
      sx={{
        visibility: forceInvisible ? "hidden" : "visible",
        ":hover": {
          backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.05),
        },
        "& .MuiOutlinedInput-root": {
          height: height,
          borderRadius: borderRadius,
          "& fieldset": {
            borderWidth: borderThickness,
            borderRadius: borderRadius,
            borderColor: primaryColor,
          },
          "&:hover fieldset": {
            borderColor: primaryColor,
          },
          "&.Mui-focused fieldset": {
            borderColor: primaryColor,
            borderWidth: borderThickness,
          },
          "&.Mui-focused": {
            backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.1),
            borderWidth: borderThickness,
          },
          backgroundColor: alpha(theme.palette.colors.grayScale.white, 0.05),
        },
        "& .MuiOutlinedInput-input": {
          height: "calc(100% - 32px)",
          boxSizing: "border-box",
        },
        "& .MuiOutlinedInput-input:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 100px ${theme.palette.colors.blue[300]} inset`,
          WebkitTextFillColor: theme.palette.colors.blue[100],

          fontWeight: 500,
          fontSize: "1.0rem",
          height: "calc(100%)",
        },
        "& .MuiInputLabel-root": {
          color: primaryColor,
        },
        "& .MuiInputLabel-shrink": {
          color: theme.palette.text.primary,
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
        "& .MuiInputBase-input::placeholder": {
          color: primaryColor,
          opacity: isFocused ? 0.5 : 1,
          transition: "none",
        },
        // change label color to white
        "& .MuiFormLabel-root": {
          color: primaryColor,
          fontWeight: 600,
        },
        // set text color to white
        "& .MuiInputBase-input": {
          color: theme.palette.colors.blue[200],
          fontWeight: 500,
          fontSize: "1.0rem",
        },
        ...sx, // Merge the provided sx prop
      }}
      {...props}
    />
  );
};

import {
  Box,
  Typography,
  useTheme,
  Popover,
  Button,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout, setManualLogout } from "../../../features/users/userAuthSlice";

export const ProfileButton = ({ handleMenuClickOnMobile = null }) => {
  const userDetails = useAppSelector((state) => state.userDetails);
  const firstName = userDetails.userDetails?.firstName;
  const firstLetter = firstName ? firstName[0]?.toUpperCase() : "A";
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const handleClick = (event) => {
    if (isMediumScreen) {
      navigate("/account");
      if (handleMenuClickOnMobile) handleMenuClickOnMobile("account");
      return;
    }

    if (location.pathname !== "/account") {
      navigate("/account");
      return;
    }

    if (anchorEl) {
      setAnchorEl(null);
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleLogout = () => {
    dispatch(setManualLogout(true));
    dispatch(logout());
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: { xs: "0px", md: "10px" },
        backgroundColor: {
          xs: theme.palette.colors.purple[500],
          md: theme.palette.colors.grayScale.white,
        },
        height: { xs: "60px", md: "40px" },
        width: { xs: "60px", md: "40px" },
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: {
            xs: alpha(theme.palette.colors.purple[500], 0.9),
            md: alpha(theme.palette.colors.grayScale.white, 0.9),
          },
        },
      }}
      onClick={handleClick}
    >
      <Typography
        variant="body1"
        fontSize={"16px"}
        fontWeight={800}
        sx={{
          color: {
            xs: theme.palette.colors.grayScale.white,
            md: theme.palette.colors.purple[500],
          },
        }}
      >
        {firstLetter}
      </Typography>

      {location.pathname === "/account" && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          // set props borderRaidus to 40px
          PaperProps={{
            sx: { borderRadius: "16px", marginTop: "8px", marginRight: "40px" },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Button
              variant="text"
              sx={{
                fontSize: "12px",
                color: theme.palette.text.primary,
                boxShadow: "none",

                opacity: 0.5,
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  color: theme.palette.colors.royalBlue[900],
                  opacity: 0.6,
                },
              }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Popover>
      )}
    </Box>
  );
};

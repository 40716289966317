import React from "react";
import { Theme } from "@mui/material";
export class KeyFilterService {
  static highlightText(
    text: string,
    filterString: string,
    theme: Theme
  ): React.ReactNode {
    if (!filterString.trim()) {
      return text;
    }

    const parts = text.split(new RegExp(`(${filterString})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === filterString.toLowerCase() ? (
        <span
          key={index}
          style={{
            background: `linear-gradient(to right, ${theme.palette.colors.purple[100]}, ${theme.palette.colors.royalBlue[100]})`,
            borderRadius: "2px",
            color: theme.palette.colors.royalBlue[700],
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  }
}

import { Button, IconButton, alpha } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const HEIGHT = "32px";

const ActionButton = ({ icon: Icon, label, onClick, bgColor }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return isSmallScreen || label === "Fullscreen" ? (
    <IconButton
      sx={{
        height: HEIGHT,
        backgroundColor: bgColor,
        color: "white",
        borderRadius: "8px",
        paddingLeft: "4px",
        paddingRight: "4px",
        "&:hover": {
          backgroundColor: alpha(bgColor, 0.8),
          boxShadow: "none",
        },
      }}
      onClick={onClick}
    >
      <Icon sx={{ fill: "white", height: "24px", width: "24px" }} />
    </IconButton>
  ) : (
    <Button
      variant="contained"
      startIcon={<Icon sx={{ fill: "white", height: "16px", width: "16px" }} />}
      sx={{
        height: HEIGHT,
        backgroundColor: bgColor,
        color: "white",
        borderRadius: "8px",
        textTransform: "none",
        fontSize: "14px",
        boxShadow: "none",
        width: label === "2 Player" ? "110px" : "auto",
        "&:hover": {
          backgroundColor: alpha(bgColor, 0.8),
          boxShadow: "none",
        },
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default ActionButton;

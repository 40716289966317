import { showConfirmPopup } from "../../../../features/ui/confirmationPopupSlice";
import {
  logout,
  setManualLogout,
} from "../../../../features/users/userAuthSlice";
import EmailService from "../../../../services/EmailService";

export function processInternalLinkClicked(dispatch, navigate) {
  return (path: string) => {
    switch (path) {
      case "/logout":
        navigate("/");
        dispatch(setManualLogout(true));
        dispatch(logout());
        window.scrollTo(0, 0);
        break;
      case "/delete":
        const confirmDeletePopup = {
          title: "Delete Account",
          message: "Are you sure you want to delete your account?",
          confirmationText: "Delete",
          isDestructive: true,
          confirmationHandler: "deleteUser",
          command: "deleteUser",
        };
        dispatch(showConfirmPopup(confirmDeletePopup));
        break;
      case "/deactivate":
        console.log("deactivate");
        const confirmCancelPopup = {
          title: "Deactivate",
          message: "Are you sure you want to deactivate your profile?",
          confirmationText: "Confirm",
          isDestructive: true,
          confirmationHandler: "cancelUser",
          command: "cancelUser",
        };
        dispatch(showConfirmPopup(confirmCancelPopup));
        break;
      case "/questions":
        EmailService.askQuestion();
        break;
      case "/bug-report":
        EmailService.reportBug();
        break;
      case "/feedback":
        EmailService.sendFeedback();
        break;
      case "/auth":
        navigate("/auth");
        break;
      case "/auth?signup=true":
        navigate("/auth?signup=true");
        break;
      default:
        navigate(path);
        break;
    }
  };
}

import { Box, Grid, Typography, alpha, useTheme } from "@mui/material";

const DrawerTermsFooter = () => {
  const theme = useTheme();
  const color = theme.palette.text.primary;
  const termsLink = `${process.env.REACT_APP_FRONTEND_URL}/terms`;
  const privacyLink = `${process.env.REACT_APP_FRONTEND_URL}/privacy`;
  const termsText = "Terms & Conditions";
  const privacyText = "Privacy Policy";
  const spacer = " / ";

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-end"}
      width={"100%"}
      height={"100%"}
      padding={"16px"}
      alignItems={"center"}
    >
      <Typography
        fontWeight={300}
        fontSize={"12px"}
        color={color}
        sx={{
          a: {
            color: color,
            fontWeight: 400,
            textDecoration: "none",
            padding: "6px",
            "&:hover": {
              color: alpha(color, 0.8),
              cursor: "pointer",
              // background highlight
              backgroundColor: alpha(color, 0.05),
              borderRadius: "4px",
            },
          },
        }}
      >
        <a href={termsLink} target="_blank" rel="noreferrer">
          {termsText}
        </a>
        {spacer}
        <a href={privacyLink} target="_blank" rel="noreferrer">
          {privacyText}
        </a>
      </Typography>
    </Box>
  );
};

export default DrawerTermsFooter;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiKey } from "../../types/api-key/apiKeyTypes";

interface ApiKeyPopupState {
  isOpen: boolean;
  selectedApiKey: IApiKey | null;
}

const initialState: ApiKeyPopupState = {
  isOpen: false,
  selectedApiKey: null,
};

const apiKeyPopupSlice = createSlice({
  name: "apiKeyPopup",
  initialState,
  reducers: {
    openApiKeyPopup: (state, action: PayloadAction<IApiKey | null>) => {
      state.isOpen = true;
      state.selectedApiKey = action.payload;
    },
    closeApiKeyPopup: (state) => {
      state.isOpen = false;
      state.selectedApiKey = null;
    },
  },
});

export const { openApiKeyPopup, closeApiKeyPopup } = apiKeyPopupSlice.actions;
export default apiKeyPopupSlice.reducer;

export default function generateRandomString(length) {
    // Define all possible characters that can be in the random string
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    let result = '';
    const charactersLength = characters.length;
    
    // Loop to generate random characters
    for (let i = 0; i < length; i++) {
      // Generate a random index to pick a character from the characters string
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  }
  
import { Box, alpha, useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { useEffect, useRef } from "react";
import FootholdExample from "../foothold/FootholdExample";
import { useLocation } from "react-router-dom";
import { setSelectedExample } from "../../../../features/ui/pageManagerStateSlice";
import UtilityService from "../../../../utility/UtilityService";

export const ExampleExperience = ({
  isSplitScreen,
  originalHeight,
  setOriginalHeight,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const exampleCardWrapperRef = useRef<HTMLDivElement>(null);

  const filteredExamples = useAppSelector(
    (state) => state.pageManager.filteredExamples
  );
  const { selectedExample, sessionId } = useAppSelector(
    (state) => state.pageManager
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Handle hash changes and set the selected example
  useEffect(() => {
    if (location.hash) {
      const exampleName = UtilityService.getStringFromLocation(location.hash); // Use UtilityService
      const example = filteredExamples.find((ex) => ex.title === exampleName);

      if (example) {
        dispatch(setSelectedExample(example));
      }
    }
  }, [location.hash, filteredExamples, dispatch]);

  // Set the original height when the component mounts or updates
  useEffect(() => {
    if (exampleCardWrapperRef.current && !originalHeight && !isSplitScreen) {
      setOriginalHeight(exampleCardWrapperRef.current.offsetHeight);
    }
  }, [originalHeight, setOriginalHeight, isSplitScreen]);

  // Determine if the example is "Foothold"
  const isFoothold = selectedExample?.title === "Foothold";

  return (
    <Box
      ref={exampleCardWrapperRef}
      display="flex"
      flexDirection={isSplitScreen ? "column" : "row"}
      alignItems="flex-start"
      justifyContent="center"
      marginTop="20px"
      width={isSplitScreen ? `calc(50% - 8px)` : "100%"}
      height={isSplitScreen && originalHeight ? `${originalHeight}px` : "auto"}
      maxWidth="100%"
      bgcolor={alpha(theme.palette.colors.grayScale.black, 0.05)}
      borderRadius="16px"
      overflow="hidden"
      sx={{
        border:
          selectedExample?.title === "ScrollSync" && isSmallScreen
            ? `1px solid ${theme.palette.colors.grayScale[100]}`
            : "none",
      }}
    >
      {isFoothold ? (
        <FootholdExample example={selectedExample} />
      ) : (
        <iframe
          width="100%"
          height="500px"
          src={`${selectedExample?.exampleUrl}?q=${sessionId}#pw=2xQ7J1trGlnQ-4PfFOI8DA`}
          title={selectedExample?.title}
          style={{
            borderRadius: "16px",
            border: "none",
          }}
          allow="fullscreen; camera; microphone; encrypted-media"
          sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts"
        />
      )}
    </Box>
  );
};

import { Box, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import ImageLoader from "../../synchronizer/sections/header-section/ImageLoader";
import coder_img from "../../../../images/coder-page/coder-img.svg";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowRight } from "../../../../icons";

export const CodeHeader = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isExtraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [movedArrow, setMovedArrow] = useState(false);

  useEffect(() => {
    //console.log("isExtraSmall: ", isExtraSmall);
  }, [isExtraSmall]);
  useEffect(() => {
    //console.log("movedArrow: ", movedArrow);
  }, [movedArrow]);

  const LeftSection = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        position={"relative"}
        marginRight={{ xs: "0px", md: "20px", lg: "60px" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          position={"relative"}
          top={-10}
          sx={{
            pointerEvents: "none",
          }}
        >
          <ImageLoader
            heightOverride={isExtraSmall ? 250 : 350}
            widthOverride={isExtraSmall ? 250 : 350}
            src={coder_img}
            placeholder={coder_img}
            alt="Animated Node"
          />
        </Box>
      </Box>
    );
  };

  const RightSection = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        position={"relative"}
        marginLeft={{ xs: "0px", md: "20px", lg: "60px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ md: "center", lg: "flex-start" }}
          justifyContent={"center"}
          width={{ xs: "100%", md: "350px", lg: "400px" }}
          paddingLeft={{ xs: "40px", md: "20px" }}
          paddingRight={{ xs: "40px", md: "20px" }}
          paddingTop={"16px"}
          paddingBottom={"16px"}
        >
          <Typography
            variant="h4"
            textAlign={{ xs: "center", md: "left" }}
            color={theme.palette.colors.blue[200]}
            paddingBottom={"8px"}
          >
            Tools For Multiplayer Synchronization
          </Typography>
          <Typography
            variant="body1"
            textAlign={{ xs: "center", md: "left" }}
            fontSize={"16px"}
            width={"100%"}
            paddingRight={{ xs: "0px", sm: "60px", md: "0px" }}
            paddingLeft={{ xs: "0px", sm: "60px", md: "0px" }}
            color={alpha(theme.palette.colors.grayScale.white, 0.7)}
            paddingBottom={"20px"}
          >
            Multisynq is a decentralized multiplayer synchronization system for
            the Internet that benefits developers with no netcode,  much lower
            costs, much higher performance and faster time to market. 
          </Typography>
          <Box
            display="flex"
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems={{ xs: "center", md: "flex-start" }}
            width={"100%"}
            paddingTop={{ xs: "16px", md: "0px" }}
          >
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/docs")}
              onMouseEnter={() => setMovedArrow(true)}
              onMouseLeave={() => setMovedArrow(false)}
              sx={{
                width: "160px",
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                Explore Docs
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                height={"16px"}
                width={"16px"}
                marginLeft={"8px"}
                sx={{
                  transition: "transform 0.3s",
                  transform: `translateX(${movedArrow ? "6px" : "0px"})`,
                }}
              >
                <ArrowRight />
              </Box>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="center"
      alignItems="center"
      marginBottom={"40px"}
      maxWidth={"900px"}
    >
      <LeftSection />
      <RightSection />
    </Box>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IExample,
  examples,
} from "../../components/pages/examples/ExampleData";

interface PageManagerState {
  allExamples: IExample[];
  filters: string;
  filteredExamples: IExample[];
  selectedExample: IExample | null;
  currentPage: number;
  totalPages: number;
  scrollPosition: number;
  isAnimatingScroll: boolean;
  sessionId: string;
}

const initialState: PageManagerState = {
  allExamples: examples,
  filters: "Show All",
  filteredExamples: examples,
  selectedExample: examples[0],
  currentPage: 1,
  totalPages: 1,
  scrollPosition: 0,
  isAnimatingScroll: false,
  sessionId: ''
};

export const fetchExamples = createAsyncThunk(
  "pageManager/fetchExamples",
  async () => {
    // Simulating an API call
    return examples;
  }
);

const pageManagerSlice = createSlice({
  name: "pageManager",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.filteredExamples =
        state.filters === "Show All"
          ? state.allExamples
          : state.allExamples.filter(
              (example) => example.framework === state.filters
            );
    },
    setFilteredExamples: (state, action) => {
      state.filteredExamples = action.payload;
    },
    setSelectedExample: (state, action) => {
      state.selectedExample = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setisAnimatingScroll: (state, action) => {
      state.isAnimatingScroll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExamples.fulfilled, (state, action) => {
      state.allExamples = action.payload;
      state.filteredExamples = action.payload;
    });
  },
});

export const {
  setFilters,
  setFilteredExamples,
  setSelectedExample,
  setSessionId,
  setCurrentPage,
  setScrollPosition,
  setTotalPages,
  setisAnimatingScroll,
} = pageManagerSlice.actions;

export default pageManagerSlice.reducer;

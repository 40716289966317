import { useRef } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import world_map from "../../../../../images/world-map.png";
import { LaunchPhase } from "./LaunchPhase";

export const Section2 = () => {
  const theme = useTheme();
  const title = "Start Earning";
  const subtitle = "The earlier you join, the more points you earn!";
  const contentRef = useRef(null);

  const randomColor = theme.palette.colors.grayScale.white;

  // TODO - Pull phase info from the backend as the source of truth

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      paddingBottom="48px"
      sx={{
        backgroundColor: randomColor,
        position: "relative",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "24px",
          left: "50%",
          width: "100%",
          maxWidth: "900px",
          transform: "translateX(-50%)",
          opacity: 0.7,
          zIndex: 2,
        }}
        ref={contentRef}
      >
        <img
          src={world_map}
          alt="world map"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        sx={{
          zIndex: 3,
          padding: "24px",
          marginTop: "24px",
          color: theme.palette.common.white,
        }}
      >
        <Typography
          variant="h3"
          color={theme.palette.colors.royalBlue[500]}
          sx={{ paddingBottom: "8px", zIndex: 2 }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.colors.royalBlue[500]}
          sx={{
            maxWidth: "500px",
            textAlign: "center",
            paddingBottom: "32px",
            fontWeight: 500,
            zIndex: 2,
          }}
        >
          {subtitle}
        </Typography>
        <LaunchPhase />
      </Box>
    </Box>
  );
};

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Checkbox, alpha, useTheme } from "@mui/material";
import { useEffect } from "react";

const CustomCheckbox = ({ isChecked, setIsChecked, size = "20px" }) => {
  const theme = useTheme();

  useEffect(() => {
    //console.log("isChecked changed in useEffect", isChecked);
  }, [isChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleChange}
      icon={<CheckBoxOutlineBlank />}
      checkedIcon={<CheckBox />}
      sx={{
        padding: 0.5,
        backgroundColor: isChecked
          ? theme.palette.colors.grayScale.white
          : undefined,
        height: size,
        width: size,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "2px",
        color: theme.palette.primary.main,
        "&:selected": {
          color: theme.palette.primary.main,
        },

        // on hover, set backgroundCOlor
        "&:hover": {
          backgroundColor: isChecked
            ? alpha(theme.palette.colors.grayScale.white, 0.7)
            : undefined,
        },
        // set inner border radius to 4 also
        "&:checked": {
          color: theme.palette.primary.main,
        },
      }}
    />
  );
};

export default CustomCheckbox;

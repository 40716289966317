export class URLValidator {
  static validate(url: string, urls: string[]): string | null {
    if (urls.includes(url)) {
      return "This URL is already in the list.";
    }

    if (!URLValidator.isAscii(url)) {
      return "The URL contains non-ASCII characters and must be Punycode-encoded.";
    }

    if (!URLValidator.couldBeURL(url)) {
      return "Invalid URL format.";
    }

    return null;
  }

  private static isAscii(pattern: string): boolean {
    return /^[\u0000-\u007F]*$/.test(pattern);
  }

  private static couldBeURL(pattern: string): boolean {
    if (!pattern.includes("://")) {
      pattern = "x://" + pattern;
    }
    try {
      new URL(pattern);
      return true;
    } catch (_) {
      return false;
    }
  }
}

import React, { useState, useEffect } from "react";
import {
  Button,
  Popover,
  Box,
  ListItem,
  ListItemText,
  useTheme,
  alpha,
  lighten,
  darken,
} from "@mui/material";
import {
  Code,
  ExpandMore,
  PlayArrow,
  PlayArrowRounded,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { MenuItem } from "../mobile-menu/MenuItem";
import { Dev, Doc, Logo } from "../../../icons";
import { MenuDivider } from "../mobile-menu/MenuDivider";
import { CoderPopoverContent } from "./CoderPopoverContent";

interface CoderButtonProps {
  viewName: string;
  onClick?: (view: string, forceNavigate: boolean) => void;
  closeDrawer?: () => void | null;
  sx?: any;
}

const CoderButton: React.FC<CoderButtonProps> = ({
  viewName,
  onClick,
  closeDrawer = null,
  sx = null,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isActiveView, setIsActiveView] = useState(determineActiveView());
  const [activeColor, setActiveColor] = useState(
    theme.palette.colors.blue[300]
  );
  // const [isMouseOverButton, setIsMouseOverButton] = useState(false);
  // const [isMouseOverPopover, setIsMouseOverPopover] = useState(false);

  useEffect(() => {
    setIsActiveView(determineActiveView());

    setActiveColor(
      isActiveView
        ? theme.palette.colors.blue[300]
        : theme.palette.colors.grayScale.white
    );
  }, [location, viewName, dispatch, isActiveView]);

  useEffect(() => {
    // color changed
  }, [activeColor]);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (anchorEl && !anchorEl.contains(event.target as Node)) {
  //       handlePopoverClose();
  //     }
  //   };

  //   if (anchorEl) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [anchorEl]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (isMouseOverButton || isMouseOverPopover) {
  //       if (!open) {
  //         handlePopoverOpen();
  //       }
  //     } else {
  //       if (open) {
  //         handlePopoverClose();
  //       }
  //     }
  //   }, 1);

  //   return () => clearInterval(interval);
  // }, [isMouseOverButton, isMouseOverPopover]);

  function determineActiveView() {
    const view = viewName.toLowerCase();
    const viewPattern = new RegExp(`^/${view}(/|$)`);
    const result = viewPattern.test(location.pathname);
    return result;
  }

  // const handlePopoverOpen = () => {
  //   setAnchorEl(document.getElementById("coder-button") as HTMLElement);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  const handleClick = (path: string, forceNavigate: boolean) => {
    if (onClick) onClick(path, forceNavigate);
  };

  // const handleMouseEnterButton = () => {
  //   setIsMouseOverButton(true);
  // };

  // const handleMouseLeaveButton = () => {
  //   setIsMouseOverButton(false);
  // };

  // const handleMouseEnterPopover = () => {
  //   setIsMouseOverPopover(true);
  // };

  // const handleMouseLeavePopover = () => {
  //   setIsMouseOverPopover(false);
  // };

  return (
    <Box
      display={"flex"}
      //onMouseEnter={handlePopoverOpen}
    >
      <Button
        id="coder-button"
        onClick={() => handleClick(viewName, false)}
        // onMouseEnter={handleMouseEnterButton}
        // onMouseLeave={handleMouseLeaveButton}
        disableRipple
        sx={{
          marginRight: "6px",
          color: isActiveView
            ? activeColor
            : theme.palette.colors.grayScale.white,
          boxShadow: "none",
          transition: "color 0.0s",
          "&:hover": {
            backgroundColor: activeColor,
            color: isActiveView
              ? lighten(activeColor, 0.2)
              : darken(theme.palette.colors.grayScale.white, 0.2),
          },
          "&.MuiButtonBase-root": {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          ...sx,
        }}
      >
        {viewName}
        {/* <ExpandMore sx={{ ml: "4px", height: "16px", width: "16px" }} /> */}
      </Button>
      {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginTop: theme.spacing(1),
            backgroundColor: "white",
            boxShadow: "none",
            backdropFilter: "blur(15px)",
            borderRadius: "12px",
            zIndex: 10000,
          },
        }}
      >
        <Box
          display={"flex"}
          position={"relative"}
          flexDirection={"column"}
          onMouseEnter={handleMouseEnterPopover}
          onMouseLeave={handleMouseLeavePopover}
          sx={{
            width: "280px",
            backgroundColor: alpha(
              theme.palette.background.paper,
              0.2
            ) as string,
            borderRadius: "16px",
            padding: theme.spacing(1),

            boxShadow: "none",
          }}
        >
          <CoderPopoverContent handleMenuClick={handleClick} />
        </Box>
      </Popover> */}
    </Box>
  );
};

export default CoderButton;
